import * as React from "react";
import { FormattedMessage } from "react-intl";

export const required = {
  required: true,
  message: <FormattedMessage id="validation.required" defaultMessage="Field is required" />,
};

export const password = {
  pattern: new RegExp(`[a-zA-Z0-9!@#$%^&*\\(\\)=_+\\|\\/.,<>;:'"\\[{\\]}-]{3,}`),
  message: (
    <FormattedMessage
      id="validation.password"
      defaultMessage="Min. 3 characters from range (A-Z, 0-9, special characters or space)."
    />
  ),
};

export const az09 = {
  pattern: new RegExp(`^[a-zA-Z0-9]$|^[a-zA-Z0-9]+[a-zA-Z0-9-]*[a-zA-Z0-9]+$`),
  message: <FormattedMessage id="validation.az09" defaultMessage="Please use only (a-z, -, 0-9,) characters" />,
};

export const trim = {
  pattern: new RegExp(`^\\S+(?: \\S+)*$`),
  message: <FormattedMessage id="validation.trim" defaultMessage="Can not start or end with whitespace" />,
};

export const az09space = {
  pattern: new RegExp(`^[a-zA-Z0-9]$|^[a-zA-Z0-9]+[a-zA-Z0-9- ]*[a-zA-Z0-9]+$`),
  message: (
    <FormattedMessage id="validation.az09space" defaultMessage="Please use only (a-z, -, space, 0-9) characters" />
  ),
};

export const az09DotSpace = {
  pattern: new RegExp(`^[a-zA-Z0-9]$|^[a-zA-Z0-9]+[a-zA-Z0-9-. ]*[a-zA-Z0-9]+$`),
  message: (
    <FormattedMessage
      id="validation.az09DotSpace"
      defaultMessage="Please use only (a-z, 0-9, -, space, .) characters"
    />
  ),
};

export const az09Extra = {
  pattern: new RegExp(`^[a-zA-Z0-9]$|^[a-zA-Z0-9]+[a-zA-Z0-9- !@#$%^&*()-_=+]*[a-zA-Z0-9-!@#$%^&*()-_=+]+$`),
  message: (
    <FormattedMessage
      id="validation.az09Extra"
      defaultMessage="Please use only (a-z,space,0-9,!,@,#,$,%,^,&,*,(,),-,_,=,+) characters"
    />
  ),
};

export const min10characters = {
  min: 10,
  message: <FormattedMessage id="validation.min10characters" defaultMessage="Min. 10 characters" />,
};

export const min2characters = {
  min: 2,
  message: <FormattedMessage id="validation.min2characters" defaultMessage="Min. 2 characters" />,
};

export const min3characters = {
  min: 3,
  message: <FormattedMessage id="validation.min3character" defaultMessage="Min. 3 characters" />,
};

export const max79characters = {
  max: 79,
  message: <FormattedMessage id="validation.max79characters" defaultMessage="Max. 79 characters" />,
};

export const max130characters = {
  max: 130,
  message: <FormattedMessage id="validation.max130characters" defaultMessage="Max. 130 characters" />,
};

export const max32characters = {
  max: 32,
  message: <FormattedMessage id="validation.max32characters" defaultMessage="Max. 32 characters" />,
};

export const max220characters = {
  max: 220,
  message: <FormattedMessage id="validation.max220characters" defaultMessage="Max. 220 characters" />,
};

export const max128characters = {
  max: 128,
  message: <FormattedMessage id="validation.max128characters" defaultMessage="Max. 128 characters" />,
};

export const max1024characters = {
  max: 1024,
  message: <FormattedMessage id="validation.max1024characters" defaultMessage="Max. 1024 characters" />,
};

export const urlValidation = {
  pattern:
    /^(https?|wss?):\/\/((([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})|((\d{1,3}\.){3}\d{1,3})|(\[[a-fA-F0-9:]+\]))(:\d+)?(\/.*)?$/,
  message: <FormattedMessage id="validation.urlValidation" defaultMessage="Please enter a valid URL" />,
};

export const ASCIICharacters = {
  pattern: new RegExp(`^[\x00-\x7F]*$`),
  message: <FormattedMessage id="validation.ASCII" defaultMessage="Only ASCII characters" />,
};

export const pidValidation = {
  required: true,
  pattern: new RegExp(`3[2-9]|[4-9][0-9]|[1-9][0-9]{2,}|12[5-9]|1[3-9][0-9]|[2-9][0-9][0-9]|^0$`),
  message: <FormattedMessage id="validation.pidValidation" defaultMessage="Wrong range (min: 32, max: 8186 or 0)" />,
};

export const AACAudioChannels = {
  pattern: new RegExp(`^[1|2|4|5|6|8]$`),
  message: (
    <FormattedMessage id="validation.AACAudioChannels" defaultMessage="AAC codec available channels: 1,2,4,5,6,8" />
  ),
};

export const MPEGTSAudioChannels = {
  pattern: new RegExp(`^[1-2]$`),
  message: (
    <FormattedMessage id="validation.AACAudioChannels" defaultMessage="AAC codec available channels: 1,2,4,5,6,8" />
  ),
};
