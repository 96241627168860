import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Card, Col, Form, Switch, Row, Segmented, DatePicker, Button, Collapse, Space, message } from "antd";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import styled from "styled-components";

import MQTTService from "../../services/mqtt";

import { selectors as NODE_ENGINE_LOGGING_SELECTORS } from "../../ducks/nodeEngineLogging";

import useNodeVersion from "../../hooks/useNodeVersion";
import useNodeChannelConfig from "../../hooks/useNodeChannelConfig";

import { required } from "../../lib/utils/formValidation";
import { MESSAGE_TYPE, LOG_LEVEL_SELECTOR } from "../../lib/utils/constants";

import NodeEngineLoggingLogLevelLabel from "./NodeEngineLoggingLogLevelLabel";
import NodeEngineLoggingFFmpegLogLevelLabel from "./NodeEngineLoggingFFmpegLogLevelLabel";
import NodeEngineLoggingBeCerfuleLabel from "./NodeEngineLoggingBeCerfuleLabel";

const { Item } = Form;

const translations = defineMessages({
  critical: {
    id: "NodeEngineLogging.critical",
    defaultMessage: "Critical",
  },
  error: {
    id: "NodeEngineLogging.error",
    defaultMessage: "Error",
  },
  warning: {
    id: "NodeEngineLogging.warning",
    defaultMessage: "Warning",
  },
  info: {
    id: "NodeEngineLogging.info",
    defaultMessage: "Info",
  },
  debug: {
    id: "NodeEngineLogging.debug",
    defaultMessage: "Debug",
  },
  verbose: {
    id: "NodeEngineLogging.verbose",
    defaultMessage: "Verbose",
  },
});

const NodeEngineLogging = () => {
  const { isMinNodeV530 } = useNodeVersion();
  const { isEnginePlayout } = useNodeChannelConfig();
  const { cwid, id } = useParams();
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const [messageApi, contextHolder] = message.useMessage();
  const [isOpen, setIsOpen] = useState(false);

  const engineLoggingSetting = useSelector((state) => NODE_ENGINE_LOGGING_SELECTORS.getEngineLogging(state, cwid, id));

  useEffect(() => {
    if (isMinNodeV530) {
      MQTTService.sendMessageV5({
        topic: `node/${cwid}/in`,
        message: { msgType: MESSAGE_TYPE.GET_CHANNEL_LOGGING_OPTIONS, channelId: id },
      });
    }
  }, [isMinNodeV530, cwid, id]);

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i += 1) {
      result.push(i);
    }

    return result;
  };

  const rangePresets = useMemo(
    () => [
      {
        label: <FormattedMessage id="NodeEngineLogging.5minutes" defaultMessage="5 Minutes" />,
        value: dayjs().add(5, "m"),
      },
      {
        label: <FormattedMessage id="NodeEngineLogging.15minutes" defaultMessage="15 Minutes" />,
        value: dayjs().add(15, "m"),
      },
      {
        label: <FormattedMessage id="NodeEngineLogging.30minutes" defaultMessage="30 Minutes" />,
        value: dayjs().add(30, "m"),
      },
      {
        label: <FormattedMessage id="NodeEngineLogging.1hour" defaultMessage="1 Hour" />,
        value: dayjs().add(1, "h"),
      },
      {
        label: <FormattedMessage id="NodeEngineLogging.2hours" defaultMessage="2 Hours" />,
        value: dayjs().add(2, "h"),
      },
      {
        label: <FormattedMessage id="NodeEngineLogging.5hours" defaultMessage="5 Hours" />,
        value: dayjs().add(5, "h"),
      },
      {
        label: <FormattedMessage id="NodeEngineLogging.1day" defaultMessage="1 Day" />,
        value: dayjs().add(1, "d"),
      },
      {
        label: <FormattedMessage id="NodeEngineLogging.2days" defaultMessage="2 Days" />,
        value: dayjs().add(2, "d"),
      },
      {
        label: <FormattedMessage id="NodeEngineLogging.7days" defaultMessage="7 Days" />,
        value: dayjs().add(7, "d"),
      },
    ],
    [isOpen]
  );

  const disabledDateTime = (date) => {
    return {
      disabledHours: () => {
        const currentHour = dayjs().hour();
        const sameAsCurrentDay = date && date.date() === dayjs().date();

        return sameAsCurrentDay ? range(0, currentHour) : [];
      },
      disabledMinutes: () => {
        const currentMinute = dayjs().minute();
        const sameAsCurrentDay = date && date.date() === dayjs().date();
        const sameAsCurrentHour = date && date.hour() === dayjs().hour();

        return sameAsCurrentDay && sameAsCurrentHour ? range(0, currentMinute) : [];
      },
      disabledSeconds: () => {
        const currentSecond = dayjs().second();
        const sameAsCurrentDay = date && date.date() === dayjs().date();
        const sameAsCurrentHour = date && date.hour() === dayjs().hour();
        const sameAsCurrentMinute = date && date.minute() === dayjs().minute();

        return sameAsCurrentDay && sameAsCurrentHour && sameAsCurrentMinute ? range(0, currentSecond) : [];
      },
    };
  };

  const onFinish = (values) => {
    messageApi.open({
      type: "success",
      content: <FormattedMessage id="NodeEngineLogging.loggingDataUpdated" defaultMessage="Logging data updated" />,
    });

    MQTTService.sendMessageV5({
      topic: `node/${cwid}/in`,
      message: { msgType: MESSAGE_TYPE.SET_CHANNEL_LOGGING_OPTIONS, channelId: id, ...values },
    });
  };

  const isLoggingEnabled = Form.useWatch(["extendedLoggingEnabled"], form);
  const logLevel = Form.useWatch(["logLevel"], form);
  const isLogLevelDebugOrVerbose = logLevel >= 4;

  const handleOpenChange = (open) => {
    setIsOpen(open);
  };

  return (
    <StyledCollapse
      items={[
        {
          key: "engineLoggin",
          label: <FormattedMessage id="NodeEngineLogging.logging" defaultMessage="Logging" />,
          children: (
            <Col span={24}>
              <Form form={form} initialValues={engineLoggingSetting} onFinish={onFinish}>
                <StyledSpace direction="vertical" size="middle">
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                      <Item name="logLevel" label={<NodeEngineLoggingLogLevelLabel />}>
                        <Segmented
                          options={LOG_LEVEL_SELECTOR.map((logLevelSelector) => ({
                            label: formatMessage(translations[logLevelSelector.label]),
                            value: logLevelSelector.value,
                          }))}
                          onChange={(selectedLogLevelValue) => {
                            const ffmpegLogLevel = form.getFieldValue("ffmpegLogLevel");

                            if (selectedLogLevelValue < ffmpegLogLevel) {
                              form.setFieldValue("ffmpegLogLevel", selectedLogLevelValue);
                            }
                          }}
                        />
                      </Item>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                      <Item name="ffmpegLogLevel" label={<NodeEngineLoggingFFmpegLogLevelLabel />}>
                        <Segmented
                          options={LOG_LEVEL_SELECTOR.map((logLevelSelector) => {
                            return {
                              label: formatMessage(translations[logLevelSelector.label]),
                              value: logLevelSelector.value,
                              disabled: logLevelSelector.value > logLevel,
                            };
                          })}
                        />
                      </Item>
                    </Col>

                    {isLogLevelDebugOrVerbose && (
                      <>
                        <Col span={24}>
                          <Item
                            name="extendedLoggingEnabled"
                            label={
                              <FormattedMessage
                                id="NodeEngineLogging.extendedLogging"
                                defaultMessage="Extended logging"
                              />
                            }
                          >
                            <Switch
                              checkedChildren={<FormattedMessage id="global.enabled" defaultMessage="Enabled" />}
                              unCheckedChildren={<FormattedMessage id="global.disabled" defaultMessage="Disabled" />}
                            />
                          </Item>
                        </Col>

                        {isLoggingEnabled && (
                          <Col span={24}>
                            <Card
                              title={
                                <FormattedMessage
                                  id="NodeEngineLogging.loggingOptions"
                                  defaultMessage="Logging Options"
                                />
                              }
                            >
                              <Row gutter={24}>
                                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                  <Item
                                    name="timeoutTimestamp"
                                    label={
                                      <FormattedMessage id="NodeEngineLogging.endTime" defaultMessage="End Time" />
                                    }
                                    getValueProps={(value) => ({
                                      value: value ? dayjs.unix(value) : null,
                                    })}
                                    normalize={(value) => value && dayjs(value).unix()}
                                    rules={[required]}
                                  >
                                    <StyledDatePicker
                                      showTime={{ hideDisabledOptions: true }}
                                      maxDate={dayjs().add(7, "days")}
                                      minDate={dayjs()}
                                      disabledTime={disabledDateTime}
                                      presets={rangePresets}
                                      onOpenChange={handleOpenChange}
                                    />
                                  </Item>
                                </Col>

                                <>
                                  <Col xs={24} sm={24} md={12} lg={6}>
                                    <Item
                                      name="logTimestamps"
                                      label={
                                        <NodeEngineLoggingBeCerfuleLabel
                                          label={
                                            <FormattedMessage
                                              id="NodeEngineLogging.logLevel"
                                              defaultMessage="Log Timestamps"
                                            />
                                          }
                                        />
                                      }
                                    >
                                      <Switch
                                        checkedChildren={
                                          <FormattedMessage id="global.enabled" defaultMessage="Enabled" />
                                        }
                                        unCheckedChildren={
                                          <FormattedMessage id="global.disabled" defaultMessage="Disabled" />
                                        }
                                      />
                                    </Item>
                                  </Col>
                                  <Col xs={24} sm={24} md={12} lg={6}>
                                    <Item
                                      name="logBitrateReports"
                                      label={
                                        <FormattedMessage
                                          id="NodeEngineLogging.logBitrateReports"
                                          defaultMessage="Log Bitrate Reports"
                                        />
                                      }
                                    >
                                      <Switch
                                        checkedChildren={
                                          <FormattedMessage id="global.enabled" defaultMessage="Enabled" />
                                        }
                                        unCheckedChildren={
                                          <FormattedMessage id="global.disabled" defaultMessage="Disabled" />
                                        }
                                      />
                                    </Item>
                                  </Col>
                                  <Col xs={24} sm={24} md={12} lg={6}>
                                    <Item
                                      name="logBitrateAll"
                                      label={
                                        <NodeEngineLoggingBeCerfuleLabel
                                          label={
                                            <FormattedMessage
                                              id="NodeEngineLogging.logBitrateAll"
                                              defaultMessage="Log Bitrate All"
                                            />
                                          }
                                        />
                                      }
                                    >
                                      <Switch
                                        checkedChildren={
                                          <FormattedMessage id="global.enabled" defaultMessage="Enabled" />
                                        }
                                        unCheckedChildren={
                                          <FormattedMessage id="global.disabled" defaultMessage="Disabled" />
                                        }
                                      />
                                    </Item>
                                  </Col>
                                  {isEnginePlayout && (
                                    <>
                                      <Col xs={24} sm={24} md={12} lg={6}>
                                        <Item
                                          name="logPlaylistEvents"
                                          label={
                                            <FormattedMessage
                                              id="NodeEngineLogging.logPlaylistEvents"
                                              defaultMessage="Log Playlist Events"
                                            />
                                          }
                                        >
                                          <Switch
                                            checkedChildren={
                                              <FormattedMessage id="global.enabled" defaultMessage="Enabled" />
                                            }
                                            unCheckedChildren={
                                              <FormattedMessage id="global.disabled" defaultMessage="Disabled" />
                                            }
                                          />
                                        </Item>
                                      </Col>
                                      <Col xs={24} sm={24} md={12} lg={6}>
                                        <Item
                                          name="logTcInOut"
                                          label={
                                            <FormattedMessage
                                              id="NodeEngineLogging.logTcInOut"
                                              defaultMessage="Log TC In Out"
                                            />
                                          }
                                        >
                                          <Switch
                                            checkedChildren={
                                              <FormattedMessage id="global.enabled" defaultMessage="Enabled" />
                                            }
                                            unCheckedChildren={
                                              <FormattedMessage id="global.disabled" defaultMessage="Disabled" />
                                            }
                                          />
                                        </Item>
                                      </Col>
                                      <Col xs={24} sm={24} md={12} lg={6}>
                                        <Item
                                          name="logCGEvents"
                                          label={
                                            <FormattedMessage
                                              id="NodeEngineLogging.logCGEvents"
                                              defaultMessage="Log CG Events"
                                            />
                                          }
                                        >
                                          <Switch
                                            checkedChildren={
                                              <FormattedMessage id="global.enabled" defaultMessage="Enabled" />
                                            }
                                            unCheckedChildren={
                                              <FormattedMessage id="global.disabled" defaultMessage="Disabled" />
                                            }
                                          />
                                        </Item>
                                      </Col>
                                      <Col xs={24} sm={24} md={12} lg={6}>
                                        <Item
                                          name="logSCTEEvents"
                                          label={
                                            <FormattedMessage
                                              id="NodeEngineLogging.logSCTEEvents"
                                              defaultMessage="Log SCTE Events"
                                            />
                                          }
                                        >
                                          <Switch
                                            checkedChildren={
                                              <FormattedMessage id="global.enabled" defaultMessage="Enabled" />
                                            }
                                            unCheckedChildren={
                                              <FormattedMessage id="global.disabled" defaultMessage="Disabled" />
                                            }
                                          />
                                        </Item>
                                      </Col>
                                      <Col xs={24} sm={24} md={12} lg={6}>
                                        <Item
                                          name="logSCTENullEvents"
                                          label={
                                            <FormattedMessage
                                              id="NodeEngineLogging.logSCTENullEvents"
                                              defaultMessage="Log SCTE Null Events"
                                            />
                                          }
                                        >
                                          <Switch
                                            checkedChildren={
                                              <FormattedMessage id="global.enabled" defaultMessage="Enabled" />
                                            }
                                            unCheckedChildren={
                                              <FormattedMessage id="global.disabled" defaultMessage="Disabled" />
                                            }
                                          />
                                        </Item>
                                      </Col>
                                      <Col xs={24} sm={24} md={12} lg={6}>
                                        <Item
                                          name="logSubtitles"
                                          label={
                                            <FormattedMessage
                                              id="NodeEngineLogging.logSubtitles"
                                              defaultMessage="Log Subtitles"
                                            />
                                          }
                                        >
                                          <Switch
                                            checkedChildren={
                                              <FormattedMessage id="global.enabled" defaultMessage="Enabled" />
                                            }
                                            unCheckedChildren={
                                              <FormattedMessage id="global.disabled" defaultMessage="Disabled" />
                                            }
                                          />
                                        </Item>
                                      </Col>
                                      <Col xs={24} sm={24} md={12} lg={6}>
                                        <Item
                                          name="logWholeSubtitles"
                                          label={
                                            <NodeEngineLoggingBeCerfuleLabel
                                              label={
                                                <FormattedMessage
                                                  id="NodeEngineLogging.logWholeSubtitles"
                                                  defaultMessage="Log Whole Subtitles"
                                                />
                                              }
                                            />
                                          }
                                        >
                                          <Switch
                                            checkedChildren={
                                              <FormattedMessage id="global.enabled" defaultMessage="Enabled" />
                                            }
                                            unCheckedChildren={
                                              <FormattedMessage id="global.disabled" defaultMessage="Disabled" />
                                            }
                                          />
                                        </Item>
                                      </Col>
                                    </>
                                  )}
                                </>
                              </Row>
                            </Card>
                          </Col>
                        )}
                      </>
                    )}
                  </Row>
                  <Row gutter={24}>
                    <Col span={24} style={{ textAlign: "right" }}>
                      <Button type="primary" htmlType="submit">
                        <FormattedMessage id="global.save" defaultMessage="Save" />
                      </Button>
                    </Col>
                  </Row>
                </StyledSpace>
              </Form>
              {contextHolder}
            </Col>
          ),
        },
      ]}
    />
  );
};

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`;

const StyledCollapse = styled(Collapse)`
  margin-bottom: 10px;
`;

const StyledSpace = styled(Space)`
  display: flex;
`;

export default NodeEngineLogging;
