export default {
  cloudBlue: "#0958d9",
  hoverCloudBlue: "#1677ff",
  lightRed: "#E33D29",
  red: "#C81600",
  darkRed: "#B81400",
  defaultBlueColor: "#286FE0",
  defaultDarkBGColor: "#141414",
  defaultLightBGColor: "#FFFFFF",
  lightGray: "#ebecf0",
  grayBackground: "#f0f6f6",
  grayBorder: "#616161",
  green: "#00a971",
  greenHover: "#00c383",
  greyTips: "#cbd6d6",
  greyTitle: "#afc2c2",
  logCritical: "#ff5636",
  logDebug: "#99f0c3",
  logError: "#ffe8a9",
  logInfo: "#d5e5ff",
  logWarning: "#ffe8a9",
  orange: "#FF6621",
  orangeBackground: "#ffe8a9",
  orangeHover: "#FF8B1F",
  yellowLink: "#FF6621",
  yellowLinkHover: "#FF8B1F",
  maritime: "#4bc0c0",
  defaultBackground: "#232230",
  paddingInvitation: "#434343",
  mainBackground: "#090712",
  lightMainBackground: "#f0f2f5",
  cardBackground: "#1f1f1f",
  lightCardBackground: "#fafafa",
  missingStatus: "#13c2c2",
  unknownStatus: "#434343",
  warningStatus: "#FF6621",
  adBreakStatus: "#13c2c2",
};

export const GRAPH_OPTIONS = {
  plugins: {
    zoom: {
      pan: {
        enabled: true,
        mode: "xy",
        modifierKey: "ctrl",
      },
      zoom: {
        mode: "xy",
        drag: {
          enabled: true,
          borderColor: "#FF8B1F",
          borderWidth: 1,
          backgroundColor: "rgba(255, 232, 169, 0.1)",
        },
      },
    },
  },
  scales: {
    x: {
      stacked: true,
      type: "timeseries",
      ticks: {
        color: "#4bc0c0",
        autoSkip: true,
        autoSkipPadding: 20,
        maxRotation: 0,
        major: {
          enabled: true,
        },
      },
      time: {
        displayFormats: {
          hour: "MMM DD, HH:mm",
          minute: "MMM DD, HH:mm",
          second: "MMM DD, HH:mm:ss",
        },
      },
    },
    y: {
      beginAtZero: true,
      ticks: {
        color: "#4bc0c0",
      },
    },
  },
};

export const GRAPH_DATASET_OPTIONS = {
  fill: false,
  spanGaps: false,
  lineTension: 0.1,
  pointBackgroundColor: "rgba(220,220,220,0.7)",
  borderCapStyle: "butt",
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: "miter",
  pointBorderWidth: 1,
  pointHoverRadius: 5,
  pointRadius: 1.5,
  pointHoverBorderWidth: 1,
  pointHoverBorderColor: "rgba(220,220,220,1)",
};
