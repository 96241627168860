import React, { useCallback, useState } from "react";
import PropTypes, { number, string } from "prop-types";
import styled from "styled-components";
import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Modal, Table, Popconfirm, Tag, Row } from "antd";
import { useSelector } from "react-redux";

import { selectors as ORGANIZATION_SELECTOR } from "../../ducks/organizations";
import OrganizationsServices from "../../services/organizations";

import globalTranslation from "../../lib/translations";
import { errorNotification } from "../../lib/utils/notification";
import OrganizationColors from "../../lib/utils/organizationColors";

import OrganizationStep1 from "../OrganizationStep1";
import OrganizationValidation from "../OrganizationValidation";

function OrganizationListRoles({ organization, disabled }) {
  const [isVisibleEditRoles, setIsVisibleRoles] = useState(false);
  const [isVisibleEditRolesDetail, setIsVisibleRolesDetail] = useState(false);
  const [initialRoleData, setInitialRoleData] = useState(null);

  const { formatMessage } = useIntl();
  const organizationRoles = useSelector((state) => ORGANIZATION_SELECTOR.getOrganizationRoles(state, organization.id));

  const handleDeleteRole = useCallback((id) => {
    OrganizationsServices.removeOrganizationRole({ errorNotification: errorNotification(formatMessage), id });
  }, []);

  const handleEditRole = (role) => {
    setIsVisibleRolesDetail(true);
    setInitialRoleData(role);
  };

  const columns = [
    {
      title: <FormattedMessage id="OrganizationListRoles.username" defaultMessage="User name" />,
      dataIndex: "username",
      key: "username",
    },
    {
      title: <FormattedMessage id="OrganizationListRoles.businessRole" defaultMessage="Business role" />,
      dataIndex: "businessRole",
      key: "businessRole",
    },
    {
      title: <FormattedMessage id="OrganizationListRoles.name" defaultMessage="Name" />,
      dataIndex: "businessName",
      key: "businessName",
    },
    {
      title: <FormattedMessage id="OrganizationListRoles.businessPhoneNumber" defaultMessage="Business phone" />,
      dataIndex: "businessPhoneNumber",
      key: "businessPhoneNumber",
    },
    {
      title: <FormattedMessage id="OrganizationListRoles.businessEmail" defaultMessage="Business email" />,
      dataIndex: "businessEmail",
      key: "businessEmail",
    },
    {
      title: <FormattedMessage id="OrganizationListRoles.technicalRole" defaultMessage="Technical role" />,
      dataIndex: "technicalRole",
      key: "technicalRole",
    },
    {
      title: <FormattedMessage id="OrganizationListRoles.name" defaultMessage="Name" />,
      dataIndex: "technicalName",
      key: "technicalName",
    },
    {
      title: <FormattedMessage id="OrganizationListRoles.technicalPhoneNumber" defaultMessage="Technical phone" />,
      dataIndex: "technicalPhoneNumber",
      key: "technicalPhoneNumber",
    },
    {
      title: <FormattedMessage id="OrganizationListRoles.technicalEmail" defaultMessage="Technical email" />,
      dataIndex: "technicalEmail",
      key: "technicalEmail",
    },
    {
      title: <FormattedMessage id="OrganizationListRoles.status" defaultMessage="Status" />,
      dataIndex: "status",
      key: "status",
      render: (status, organizationData) => {
        return (
          <Tag
            color={OrganizationColors({ isOrganizationPublic: organizationData?.public, organizationStatus: status })}
          >
            <OrganizationValidation organizationData={organizationData} organizationId={organizationData?.id} />
          </Tag>
        );
      },
    },
    {
      title: <FormattedMessage id="OrganizationListRoles.actions" defaultMessage="Actions" />,
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => (
        <>
          <StyledButton
            icon={<EditOutlined />}
            onClick={() => handleEditRole(record)}
            disabled={disabled}
            loading={disabled}
          >
            {formatMessage(globalTranslation.edit)}
          </StyledButton>
          <Popconfirm
            onConfirm={() => handleDeleteRole(record.id)}
            title={
              <FormattedMessage
                id="OrganizationListRoles.confirmRemove"
                defaultMessage="Are you sure, you want to remove this role?"
              />
            }
          >
            <StyledButton icon={<DeleteOutlined />} type="primary" danger disabled={disabled} loading={disabled}>
              {formatMessage(globalTranslation.delete)}
            </StyledButton>
          </Popconfirm>
        </>
      ),
    },
  ];

  // const handleEditRoles = useCallback(() => {
  //   setIsVisibleRoles(true);
  // }, [organization]);

  const handleAddNewRole = () => {
    setInitialRoleData({ organizationId: organization.id, organizationName: organization.name });
    setIsVisibleRolesDetail(true);
  };

  return (
    <>
      {/* <StyledButton
        type="dashed"
        icon={<FormOutlined />}
        onClick={() => handleEditRoles()}
        disabled={disabled}
        loading={disabled}
      >
        <span>
          <FormattedMessage id="OrganizationListRoles.editRoles" defaultMessage="Edit roles" />
        </span>
      </StyledButton> */}
      <Modal
        title={
          <>
            <FormattedMessage id="OrganizationListRoles.organizationRoles" defaultMessage="Organization roles" /> (
            {organization.name})
          </>
        }
        open={isVisibleEditRoles}
        onOk={() => setIsVisibleRoles(true)}
        onCancel={() => setIsVisibleRoles(false)}
        footer={null}
        width={1400}
      >
        <Row justify="end">
          <Button type="primary" icon={<PlusCircleOutlined />} onClick={handleAddNewRole} style={{ margin: "15px" }}>
            <span>
              <FormattedMessage id="OrganizationListRoles.addNewRole" defaultMessage="Add new role" />
            </span>
          </Button>
        </Row>
        <Table columns={columns} dataSource={organizationRoles} rowKey={(record) => record.id} scroll={{ x: 1300 }} />
      </Modal>
      <Modal
        title={<FormattedMessage id="OrganizationListRoles.editRole" defaultMessage="Edit role" />}
        open={isVisibleEditRolesDetail}
        onOk={() => setIsVisibleRolesDetail(true)}
        onCancel={() => setIsVisibleRolesDetail(false)}
        footer={null}
        width={1000}
        destroyOnClose
      >
        <OrganizationStep1
          setCurrentStep={() => null}
          initialData={initialRoleData}
          setIsVisibleRolesDetail={setIsVisibleRolesDetail}
        />
      </Modal>
    </>
  );
}

const StyledButton = styled(Button)`
  margin: 3px 5px;
`;

OrganizationListRoles.propTypes = {
  disabled: PropTypes.bool.isRequired,
  organization: PropTypes.shape({
    name: string,
    id: number,
  }).isRequired,
};

export default OrganizationListRoles;
