import React, { useContext, useCallback } from "react";
import { Col, Form, Select, Card, Row, Badge } from "antd";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  OUTPUTS_TYPES,
  SELECT_STREAM_CODEC,
  OUTPUT_RTMP_PIX_FORMAT,
  TRANSCODING_OPEN_H264_PIX_FORMAT,
  TRANSCODING_MPEG2VIDEO_PIX_FORMAT,
  TRANSCODING_X264_PIX_FORMAT,
  SELECT_TRANSCODING_VIDEO_CODEC_VALUES,
} from "../../../../../../lib/utils/constants";
import { required } from "../../../../../../lib/utils/formValidation";
import themeColor from "../../../../../../lib/style/theme";
import changeTranscodingCodecType from "../../../../../../lib/utils/changeTranscodingCodecType";
import globalTranslation from "../../../../../../lib/translations";

import X264VideoCodecAdvanced from "./X264VideoCodecAdvanced";
import X264VideoCodec from "./X264VideoCodec";
import Mpeg2VideoCodec from "./Mpeg2VideoCodec";

import { FormV5Context } from "../../../../NodeChannelFormV5";
import IncompleteConfig from "../../../../../IncompleteConfig";
import GeneralVideoCodec from "./GeneralVideoCodec";
import NvidiaVideoCodecs from "../../../../../NvidiaVideoCodecs";
import { getOutputVideoIncomplete } from "../../../../../../ducks/nodeChannels";

import { selectors as NODE_SELECTORS, getNodeRTMPCodecsSelector } from "../../../../../../ducks/node";
import { getEnvidiaHWEncodersSelector } from "../../../../../../ducks/nodeDevices";

const { Item } = Form;
const { Option } = Select;
const { Ribbon } = Badge;

export default function VideoStream({ disabled, prefix }) {
  const form = useContext(FormV5Context);
  const { formatMessage } = useIntl();
  const { setFieldValue } = form;

  const { id: channelId } = useParams();
  const envidiaHWEncoders = useSelector(getEnvidiaHWEncodersSelector);
  const isDefaultHwEncoder = envidiaHWEncoders.length === 1 && envidiaHWEncoders[0].value === -1;
  const disableNvidiaCodecs = envidiaHWEncoders.length === 0 || isDefaultHwEncoder;

  const nodeCodecs = useSelector(NODE_SELECTORS.getNodeCodecs);
  const nodeRTMPCodecs = useSelector(getNodeRTMPCodecsSelector);

  const NODE_CODECS_LIST = nodeCodecs.map((codec) => {
    if (codec === "Nv264" || codec === "Nv265") {
      return {
        value: codec,
        label: formatMessage(globalTranslation[codec]) || codec,
        disabled: disableNvidiaCodecs,
      };
    }

    return {
      value: codec,
      label: formatMessage(globalTranslation[codec]) || codec,
    };
  });

  const RTMP_NODE_CODECS_LIST = nodeRTMPCodecs.map((codec) => ({
    value: codec,
    label: formatMessage(globalTranslation[codec]) || codec,
  }));

  const incomplete = useSelector((state) =>
    getOutputVideoIncomplete(state, {
      channelId,
      outputIndex: prefix[1],
    })
  );

  const isIncomplete = incomplete && incomplete.length > 0;

  const outputType = Form.useWatch([...prefix, "type"], form);
  const videoStreamType = Form.useWatch([...prefix, "videoStream", "videoCodec", "type"], form);
  const isNvidiaType = videoStreamType === "Nv264" || videoStreamType === "Nv265";
  const isGeneralVideoType = SELECT_TRANSCODING_VIDEO_CODEC_VALUES.includes(videoStreamType);

  const isOutputRtmp = outputType === OUTPUTS_TYPES.outputRtmp.value;
  const streamCodecType = Form.useWatch([...prefix, "videoStream", "videoCodec", "type"], form);
  const isOpenH264Type = streamCodecType === SELECT_STREAM_CODEC[2].value;

  const setAdditionalAdvancedFields = useCallback(() => {
    switch (streamCodecType) {
      case SELECT_STREAM_CODEC[3].value:
        return <X264VideoCodecAdvanced fieldPrefix={[...prefix, "videoStream", "videoCodec"]} disabled={disabled} />;
      default:
        return null;
    }
  }, [streamCodecType, disabled, prefix]);

  const setPixelFormat = () => {
    if (isOutputRtmp) {
      return OUTPUT_RTMP_PIX_FORMAT;
    }

    switch (streamCodecType) {
      case SELECT_STREAM_CODEC[1].value:
        return TRANSCODING_MPEG2VIDEO_PIX_FORMAT;
      case SELECT_STREAM_CODEC[2].value:
        return TRANSCODING_OPEN_H264_PIX_FORMAT;
      case SELECT_STREAM_CODEC[3].value:
        return TRANSCODING_X264_PIX_FORMAT;
      default:
        return [];
    }
  };

  const setAdditionalBasicFields = useCallback(() => {
    switch (streamCodecType) {
      case SELECT_STREAM_CODEC[1].value:
        return <Mpeg2VideoCodec fieldPrefix={[...prefix, "videoStream", "videoCodec"]} disabled={disabled} />;
      case SELECT_STREAM_CODEC[3].value:
        return (
          <X264VideoCodec
            prefix={[...prefix, "videoStream", "videoCodec"]}
            fieldPrefix={[...prefix, "videoStream", "videoCodec"]}
            disabled={disabled}
            form={form}
            isOutputRtmp={isOutputRtmp}
          />
        );
      default:
        return null;
    }
  }, [streamCodecType, prefix, disabled, form, isOutputRtmp]);

  const handleChangeCodecType = (codecType) => {
    changeTranscodingCodecType({
      codecType,
      prefix: [...prefix, "videoStream"],
      setFieldValue,
      isOutputRtmp,
    });
  };

  return (
    <Col span={24}>
      <Ribbon
        text={<IncompleteConfig incomplete={incomplete} color="#FFFFFF" />}
        color={isIncomplete ? themeColor.orange : themeColor.green}
      >
        <Card title={<FormattedMessage id="GeneralVideoCodec.videoStream" defaultMessage="Video stream" />}>
          <Row gutter={12}>
            <Col span={12}>
              <Item
                name={[...prefix, "videoStream", "videoCodec", "type"]}
                label={<FormattedMessage id="StreamCodec.codec" defaultMessage="Codec" />}
                rules={[required]}
              >
                <Select onChange={handleChangeCodecType} disabled={disabled}>
                  {isOutputRtmp &&
                    RTMP_NODE_CODECS_LIST.map((option) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))}

                  {!isOutputRtmp &&
                    NODE_CODECS_LIST.map((option) => (
                      <Option key={option.value} value={option.value} disabled={option?.disabled}>
                        {option.label}
                      </Option>
                    ))}
                </Select>
              </Item>
            </Col>
            {isNvidiaType && (
              <NvidiaVideoCodecs
                prefix={[...prefix, "videoStream", "videoCodec"]}
                disabled={disabled}
                disableInterlaced={isOutputRtmp}
              />
            )}
            {isGeneralVideoType && (
              <GeneralVideoCodec
                disabled={disabled}
                prefix={[...prefix, "videoStream"]}
                disableInterlaced={isOpenH264Type || isOutputRtmp}
                additionalBasicFields={setAdditionalBasicFields()}
                additionalAdvancedFields={setAdditionalAdvancedFields()}
                isOutputRtmp={isOutputRtmp}
                pixelFormat={setPixelFormat()}
              />
            )}
          </Row>
        </Card>
      </Ribbon>
    </Col>
  );
}

VideoStream.propTypes = {
  disabled: PropTypes.bool.isRequired,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
};
