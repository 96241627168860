import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { List, Card, Divider, Tooltip, Empty, Button, Spin, Space } from "antd";
import VirtualList from "rc-virtual-list";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import PropTypes from "prop-types";

import store from "../../store";

import MQTTService from "../../services/mqtt";
import { DELETE_LOGS_DATA, SET_LOADING_LOGS, DELETE_LOGS_PARAMS, selectors as LOGS_SELECTORS } from "../../ducks/logs";
import { getChannelsDataSelector } from "../../ducks/nodeChannels";

import { NODE, MESSAGE_TYPE } from "../../lib/utils/constants";
import useNodeVersion from "../../hooks/useNodeVersion";

import LogsV5Filters from "./LogsV5Filters";
import LogsV5Label from "./LogsV5Label";
import NodeEngineLogging from "../NodeEngineLogging";

const ContainerHeight = 600;

export default function LogsV5({ channelDetailView }) {
  const { cwid, id } = useParams();
  const logs = useSelector(LOGS_SELECTORS.getLogs);
  const logsLoading = useSelector(LOGS_SELECTORS.getLogsLoading);
  const channelsData = useSelector(getChannelsDataSelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const { isMinNodeV530 } = useNodeVersion();

  const source = searchParams.get("source");
  const jumpTo = searchParams.get("jumpTo");
  const channelId = searchParams.get("c") || id;

  const logsExist = logs && channelId in logs;
  const logsData = logsExist && logs[channelId];
  const lastLogsId = (logsData && logsData[logsData.length - 1] && logsData[logsData.length - 1].id) || 0;
  const [lastChannelLog, setLastChannelLog] = useState(lastLogsId);

  const sourceParams = source && JSON.parse(source);
  const timestamp = jumpTo || null;

  const { p1, p2, p3, s } = sourceParams || {};

  useEffect(() => {
    // End of logs
    if (+lastChannelLog === -1) {
      return;
    }

    const getLogsParams = {
      msgType: MESSAGE_TYPE.GET_LOGS,
      channelId: `${channelId}`,
      first: +lastChannelLog - 1 < 0 ? 0 : +lastChannelLog - 1,
      count: 15,
      timestamp: lastChannelLog <= 0 ? timestamp || 0 : 0,
      p1,
      p2,
      p3,
      s,
    };

    if (channelId) {
      MQTTService.sendMessage({
        topic: `node/${cwid}/in`,
        message: getLogsParams,
      });

      store.dispatch(SET_LOADING_LOGS({ loading: true }));
    }
  }, [channelId, cwid, lastChannelLog, timestamp, p1, p2, p3, s]);

  const loadMoreLogs = useCallback(() => {
    setLastChannelLog(+lastLogsId);
  }, [lastLogsId]);

  const onScroll = (e) => {
    // Refer to: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#problems_and_solutions
    if (Math.abs(e.currentTarget.scrollHeight - e.currentTarget.scrollTop - ContainerHeight) <= 1) {
      loadMoreLogs();
    }
  };

  const handleSelectChangeChannel = useCallback((selectedChannelId) => {
    setLastChannelLog(0);
    searchParams.delete("source");
    searchParams.set("c", selectedChannelId);
    setSearchParams(searchParams);
    store.dispatch(DELETE_LOGS_PARAMS());
  }, []);

  const getChannelName = (selectedId) => {
    switch (selectedId) {
      case "0":
        return NODE;
      case -1:
        return "";
      default:
        return `${channelsData[selectedId]?.config?.name} - `;
    }
  };

  const handleFilterBy = (filterData) => {
    const filterSourceParams = {
      c: filterData?.c,
      e: filterData?.e,
      p1: filterData?.p1,
      p2: filterData?.p2,
      p3: filterData?.p3,
      s: filterData?.s,
    };

    const stringFilteredSourceParams = JSON.stringify(filterSourceParams);
    const stringSourceParams = JSON.stringify(sourceParams);

    if (stringFilteredSourceParams === stringSourceParams && filterData?.time === timestamp) {
      return;
    }

    store.dispatch(DELETE_LOGS_DATA());
    setLastChannelLog(0);
    searchParams.set("source", JSON.stringify(filterSourceParams));
    searchParams.set("jumpTo", filterData?.time);
    setSearchParams(searchParams);
  };

  return (
    <Space
      direction="vertical"
      size="middle"
      style={{
        display: "flex",
      }}
    >
      {isMinNodeV530 && channelDetailView && <NodeEngineLogging />}
      <Card
        title={
          <LogsV5Filters
            channelDetailView={channelDetailView}
            channelId={channelId}
            handleSelectChangeChannel={handleSelectChangeChannel}
            sourceParams={sourceParams}
            timestamp={timestamp}
            setLastChannelLog={setLastChannelLog}
          />
        }
      >
        {!channelId && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<FormattedMessage id="LogsV5.pleaseSelectChannel" defaultMessage="Please select channel" />}
          />
        )}
        {channelId && (
          <Spin spinning={logsLoading} tip={<FormattedMessage id="LogsV5.loadingLogs" defaultMessage="Loading logs" />}>
            <List>
              <VirtualList data={logsData} height={ContainerHeight} itemHeight={61} itemKey="id" onScroll={onScroll}>
                {(item) => (
                  <List.Item
                    key={item.id}
                    actions={
                      // End of logs
                      +item.id === -1
                        ? []
                        : [
                            <Tooltip
                              title={
                                <FormattedMessage
                                  id="LogsV5.setAsFilter"
                                  defaultMessage="Set this log params as filters"
                                />
                              }
                            >
                              <Button type="link" onClick={() => handleFilterBy(item)}>
                                <FormattedMessage id="LogsV5.filterBy" defaultMessage="Filter by" />
                              </Button>
                            </Tooltip>,
                          ]
                    }
                  >
                    <StyledListItemMeta
                      avatar={
                        <StyledAvatar>
                          <Tooltip placement="top" title={item?.time}>
                            {item?.parsedTime}
                          </Tooltip>
                        </StyledAvatar>
                      }
                      title={
                        <span>
                          {getChannelName(item?.c)}
                          {item?.e}
                        </span>
                      }
                      description={
                        +item.id === -1 ? (
                          <FormattedMessage id="LogsV5.endOfLogs" defaultMessage="End of logs" />
                        ) : (
                          item?.description
                        )
                      }
                    />
                    <div>
                      {item.errorCode && (
                        <span>
                          <FormattedMessage id="LogsV5.errorCode" defaultMessage="Error code" />
                          {": "}
                          {item.errorCode}
                          <Divider type="vertical" />
                        </span>
                      )}
                      {!Number.isNaN(item?.level) && (
                        <span>
                          <FormattedMessage id="LogsV5.logLevel" defaultMessage="Level" />
                          {": "}
                          <LogsV5Label logValue={item?.level} />
                        </span>
                      )}
                    </div>
                  </List.Item>
                )}
              </VirtualList>
            </List>
          </Spin>
        )}
      </Card>
    </Space>
  );
}

const StyledListItemMeta = styled(List.Item.Meta)`
  align-items: center !important;
`;

const StyledAvatar = styled.div`
  min-width: 130px;
`;

LogsV5.propTypes = {
  channelDetailView: PropTypes.bool,
};

LogsV5.defaultProps = {
  channelDetailView: null,
};
