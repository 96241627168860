import React from "react";
import { Descriptions } from "antd";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import styled from "styled-components";

import { selectors as NODE_PLAYOUT_PLAYLIST_SELECTORS } from "../../ducks/nodePlayoutPlaylist";

import themeColors from "../../lib/style/theme";
import PreviewImage from "../PreviewImage";
import NodePlayoutPlaylistsStatus from "./NodePlayoutPlaylistsStatus";

export default function NodePlayoutPlaylistsPreview() {
  const { id } = useParams();

  const apiChannel = useSelector((state) => NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistApiChannel(state, +id));
  const apiConnected = useSelector((state) =>
    NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistApiConnected(state, +id)
  );
  const cgMode = useSelector((state) => NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistCgMode(state, +id));
  const cgControl = useSelector((state) => NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistCgControl(state, +id));
  const cgSource = useSelector((state) => NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistCgSource(state, +id));
  const cgControlStatus = useSelector((state) =>
    NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistCgControlStatus(state, +id)
  );
  const cgSourceStatus = useSelector((state) =>
    NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistCgSourceStatus(state, +id)
  );
  const elemPath0 = useSelector((state) => NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistElemPath0(state, +id));
  const elemStatus0 = useSelector((state) => NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistElemStatus0(state, +id));
  const elemPath20 = useSelector((state) => NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistElemPath20(state, +id));
  const elemStatus20 = useSelector((state) =>
    NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistElemStatus20(state, +id)
  );
  const elemPath21 = useSelector((state) => NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistElemPath21(state, +id));
  const elemStatus21 = useSelector((state) =>
    NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistElemStatus21(state, +id)
  );
  const elemPath22 = useSelector((state) => NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistElemPath22(state, +id));
  const elemStatus22 = useSelector((state) =>
    NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistElemStatus22(state, +id)
  );
  const elemPreview20 = useSelector((state) => NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPreviewElem20(state, +id));
  const elemPreview21 = useSelector((state) => NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPreviewElem21(state, +id));
  const elemPreview22 = useSelector((state) => NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPreviewElem22(state, +id));
  const elemPreview23 = useSelector((state) => NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPreviewElem23(state, +id));

  const items = [
    {
      key: "live",
      label: (
        <>
          <span>
            <FormattedMessage id="NodePlayoutPlaylists.live" defaultMessage="Live" />:
          </span>{" "}
          <StyledTitle>{elemPath22}</StyledTitle>
        </>
      ),
      children: (
        <>
          <PreviewImage src={elemPreview22} alt="Live" />
          <div>
            <FormattedMessage id="NodePlayoutPlaylists.status" defaultMessage="Status" />:{" "}
            <NodePlayoutPlaylistsStatus status={elemStatus22} />
          </div>
        </>
      ),
    },
    {
      key: "elemPath20",
      label: (
        <>
          <span>
            <FormattedMessage id="NodePlayoutPlaylists.head1" defaultMessage="Head 1" />:
          </span>{" "}
          <StyledTitle>{elemPath20}</StyledTitle>
        </>
      ),
      children: (
        <>
          <PreviewImage src={elemPreview20} alt="Head 1" />
          <div>
            <FormattedMessage id="NodePlayoutPlaylists.status" defaultMessage="Status" />:{" "}
            <NodePlayoutPlaylistsStatus status={elemStatus20} />
          </div>
        </>
      ),
    },
    {
      key: "elemPath21",
      label: (
        <>
          <span>
            <FormattedMessage id="NodePlayoutPlaylists.head2" defaultMessage="Head 2" />:
          </span>{" "}
          <StyledTitle>{elemPath21}</StyledTitle>
        </>
      ),
      children: (
        <>
          <PreviewImage src={elemPreview21} alt="Head 2" />
          <div>
            <FormattedMessage id="NodePlayoutPlaylists.status" defaultMessage="Status" />:{" "}
            <NodePlayoutPlaylistsStatus status={elemStatus21} />
          </div>
        </>
      ),
    },
    {
      key: "elemPath0",
      label: (
        <>
          <span>
            <FormattedMessage id="NodePlayoutPlaylists.switcher" defaultMessage="Switcher" />:
          </span>{" "}
          <StyledTitle>{elemPath0}</StyledTitle>
        </>
      ),
      children: (
        <>
          <PreviewImage src={elemPreview23} alt="Switcher" />
          <div>
            <FormattedMessage id="NodePlayoutPlaylists.status" defaultMessage="Status" />:{" "}
            <NodePlayoutPlaylistsStatus status={elemStatus0} />
          </div>
        </>
      ),
    },
    {
      key: "apiChannel",
      span: { xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 2 },
      label: <FormattedMessage id="NodePlayoutPlaylists.apiChannel" defaultMessage="Api channel" />,
      children: (
        <>
          <div>{apiChannel}</div>
          <div>
            <FormattedMessage id="NodePlayoutPlaylists.status" defaultMessage="Status" />:{" "}
            <NodePlayoutPlaylistsStatus status={apiConnected} />
            <div>
              <FormattedMessage id="NodePlayoutPlaylists.utcTime" defaultMessage="UTC Time" />:{" "}
              {moment.utc().format("HH:mm:ss")}
            </div>
          </div>
        </>
      ),
    },
    {
      key: "cgMode",
      label: <FormattedMessage id="NodePlayoutPlaylists.cg" defaultMessage="CG" />,
      span: { xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 2 },
      children: (
        <>
          <div>{cgMode}</div>
          <div>
            <FormattedMessage id="NodePlayoutPlaylists.control" defaultMessage="Control" />: {cgControl}
            {", "}
            <FormattedMessage id="NodePlayoutPlaylists.status" defaultMessage="Status" />:{" "}
            <NodePlayoutPlaylistsStatus status={cgControlStatus} />
          </div>
          <div>
            <FormattedMessage id="NodePlayoutPlaylists.source" defaultMessage="Source" />: {cgSource}
            {", "}
            <FormattedMessage id="NodePlayoutPlaylists.status" defaultMessage="Status" />:{" "}
            <NodePlayoutPlaylistsStatus status={cgSourceStatus} />
          </div>
        </>
      ),
    },
  ];

  return (
    <Descriptions
      items={items}
      labelStyle={{ color: "#FFF" }}
      column={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 4 }}
      bordered
      layout="vertical"
    />
  );
}

const StyledTitle = styled.span`
  color: ${themeColors.maritime};
`;
