import { Button, Form, Input, InputNumber } from "antd";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState, useContext } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { actions as NODE_ACTIONS, selectors as NODE_SELECTORS } from "../../ducks/node";
import themeColor from "../../lib/style/theme";
import { CONNECTION_METHOD_INPUT } from "../../lib/utils/constants";
import store from "../../store";

import { FormV5Context } from "../NodeChannelFormV5";

const { Item } = Form;

const messages = defineMessages({
  ip: {
    id: "channels.field.ip.validation",
    defaultMessage: "IPv4 address has an invalid format!",
  },
  notNumber: {
    id: "channels.field.validation.notNumber",
    defaultMessage: "Please provide a number!",
  },
  latencyTooBig: {
    id: "channels.field.latency.validation.tooBig",
    defaultMessage: "Latency must be less than {LATENCY_MAX}!",
  },
  latencyTooSmall: {
    id: "channels.field.latency.validation.tooSmall",
    defaultMessage: "Latency must be greater than {LATENCY_MIN}!",
  },
  overheadTooBig: {
    id: "channels.field.overhead.validation.tooBig",
    defaultMessage: "Latency must be less than {OVERHEAD_MIN}!",
  },
  overheadTooSmall: {
    id: "channels.field.overhead.validation.tooSmall",
    defaultMessage: "Latency must be greater than {OVERHEAD_MIN}!",
  },
  ipRequired: {
    id: "IPField.ipRequired",
    defaultMessage: "Please input IP address",
  },
  portRequired: {
    id: "PortField.portRequired",
    defaultMessage: "Please input port number",
  },
  latencyRequired: {
    id: "LatencyField.latencyRequired",
    defaultMessage: "Please specify latency parameter",
  },
  latency: {
    id: "LatencyField.latency",
    defaultMessage: "Latency",
  },
  overhead: {
    id: "OverheadField.overhead",
    defaultMessage: "Overhead",
  },
  overheadRequired: {
    id: "OverheadField.overheadRequired",
    defaultMessage: "Please input overhead number",
  },
  ttl: {
    id: "TTLField.ttl",
    defaultMessage: "TTL",
  },
  ttlRequired: {
    id: "TTLField.ttlRequired",
    defaultMessage: "Please input ttl number",
  },
  ipMulticast: {
    id: "IPFieldV5.ipMulticast",
    defaultMessage: "IPv4 multicast address has invalid range (224~239.x.x.x)",
  },
});

export function IPFieldV5({ disabled, name, label, isInputUdp }) {
  const { formatMessage } = useIntl();
  const form = useContext(FormV5Context);

  const inputUrlType = Form.useWatch([name[0], name[1], "type"], form);
  const inputPlayoutUrlType = Form.useWatch([name[0], name[1], name[2], "type"], form);
  const isInputUrlMulticastSelected = isInputUdp && inputUrlType === CONNECTION_METHOD_INPUT.inUdpMulticast.value;
  const isInputPlayoutUrlMulticastSelected =
    isInputUdp && inputPlayoutUrlType === CONNECTION_METHOD_INPUT.inUdpMulticast.value;

  return (
    <Item
      name={name}
      label={label || "IP"}
      rules={[
        { required: true, message: formatMessage(messages.ipRequired) },
        ...(isInputUrlMulticastSelected || isInputPlayoutUrlMulticastSelected
          ? [
              {
                pattern: new RegExp(
                  // eslint-disable-next-line max-len
                  "^(2[2-3][4-9]|23[0-9])\\.([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\\.([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\\.([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])$"
                ),
                message: formatMessage(messages.ipMulticast),
              },
            ]
          : [
              {
                pattern: new RegExp(
                  // eslint-disable-next-line max-len
                  "^([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\\.([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\\.([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\\.([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])$"
                ),
                message: formatMessage(messages.ip),
              },
            ]),
      ]}
    >
      <Input type="text" disabled={disabled} />
    </Item>
  );
}

IPFieldV5.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  label: PropTypes.string,
  isInputUdp: PropTypes.bool,
};

IPFieldV5.defaultProps = {
  disabled: false,
  label: "IP",
  isInputUdp: false,
};

export const IPField = ({ disabled, name, label }) => {
  const { formatMessage } = useIntl();

  return (
    <Item
      name={name}
      label={label}
      rules={[
        { required: true, message: formatMessage(messages.ipRequired) },
        {
          pattern: new RegExp(
            // eslint-disable-next-line max-len
            "^([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\\.([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\\.([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\\.([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])$"
          ),
          message: formatMessage(messages.ip),
        },
      ]}
    >
      <Input type="text" disabled={disabled} />
    </Item>
  );
};

IPField.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  label: PropTypes.string,
};

IPField.defaultProps = {
  disabled: false,
  label: "IP",
};

export const TTLField = ({ disabled, name, label }) => {
  const { formatMessage } = useIntl();

  return (
    <Item
      name={name}
      label={label || formatMessage(messages.ttl)}
      rules={[{ required: true, message: formatMessage(messages.ttlRequired) }]}
    >
      <InputNumber type="number" min={0} max={255} disabled={disabled} />
    </Item>
  );
};

TTLField.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  ]).isRequired,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

TTLField.defaultProps = {
  disabled: null,
  label: null,
};

export const NameField = ({ name, label, validationMessage, fieldValidation, type }) => {
  return (
    <Item
      name={name}
      label={label}
      rules={
        fieldValidation
          ? [{ ...fieldValidation }, { required: true, message: validationMessage }]
          : [{ required: true, message: validationMessage }]
      }
    >
      <Input type={type} />
    </Item>
  );
};

NameField.propTypes = {
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  ]).isRequired,
  fieldValidation: PropTypes.object,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  validationMessage: PropTypes.string.isRequired,
};

NameField.defaultProps = {
  fieldValidation: null,
  type: "text",
};

export const PortField = ({ disabled, name, label, min, notRequired, uniqValidation }) => {
  const { formatMessage } = useIntl();
  const inputEl = useRef(null);
  const [uniqPort, setUniqPort] = useState(true);

  const usedPorts = useSelector(NODE_SELECTORS.getNodeUsedPorts);
  const isLocalPort = name.includes("localPort") || label;

  useLayoutEffect(() => {
    function handleMouseScroll() {
      inputEl.current.blur();
    }
    window.addEventListener("mousewheel", handleMouseScroll);

    return () => window.removeEventListener("mousewheel", handleMouseScroll);
  }, []);

  const validateIfPortIsUsed = (port) => {
    const parsedPort = Number(port);
    if (usedPorts.includes(parsedPort)) {
      setUniqPort(false);
    } else {
      setUniqPort(true);
    }
  };

  useEffect(() => {
    const value = inputEl?.current.value;
    if (!value) return;

    const index = usedPorts.indexOf(Number(value));
    if (index > -1) {
      usedPorts.splice(index, 1);
      validateIfPortIsUsed(Number(value));
    }
  }, [inputEl, usedPorts]);

  const handleChangePort = useCallback(
    (value) => {
      if (!value) return;
      validateIfPortIsUsed(value);
    },
    [usedPorts]
  );

  const handleClickPortList = useCallback(() => {
    store.dispatch(NODE_ACTIONS.SET_NODE_DRAWER(true));
  }, []);

  const validationRules = [
    ...(notRequired ? [] : [{ required: true, message: formatMessage(messages.portRequired) }]),
    ...(uniqValidation ? [{ validator: uniqValidation }] : []),
  ];

  return (
    <Item
      name={name}
      label={label || "Port"}
      rules={validationRules}
      extra={
        <>
          {isLocalPort && (
            <StyledButton type="link" onClick={handleClickPortList}>
              <FormattedMessage id="PortField.usedPorts" defaultMessage="(used ports list)" />
            </StyledButton>
          )}

          {uniqPort ? null : (
            <span>
              <br />
              {isLocalPort && (
                <FormattedMessage
                  id="PortField.portFieldAlreadyUsed"
                  defaultMessage="Port already in use by other configuration"
                />
              )}
            </span>
          )}
        </>
      }
    >
      <InputNumber
        ref={inputEl}
        type="number"
        min={min}
        max={49151}
        disabled={disabled}
        onChange={isLocalPort ? handleChangePort : null}
      />
    </Item>
  );
};

const StyledButton = styled(Button)`
  color: ${themeColor.orange};
  padding: 3px;

  &:hover {
    color: ${themeColor.orangeHover};
    cursor: pointer;
    transition: color 0.4s ease;
  }
`;

PortField.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  ]).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  min: PropTypes.number,
  notRequired: PropTypes.bool,
};

PortField.defaultProps = {
  disabled: null,
  label: null,
  min: 1,
  notRequired: false,
};
