import React from "react";
import { Col, Form, Select, Tooltip } from "antd";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { QuestionCircleFilled } from "@ant-design/icons";
import styled from "styled-components";

import { required, AACAudioChannels, MPEGTSAudioChannels } from "../../../../../lib/utils/formValidation";
import {
  GLOBAL_CODEC_AUDIO_SAMPLE_FORMATS,
  GLOBAL_CODEC_AUDIO_SAMPLE_RATE,
  SELECT_STREAM_CODEC_AUDIO,
  ACC_AUDIO_SAMPLE_RATE_FORMATS,
} from "../../../../../lib/utils/constants";
import languageCodes from "../../../../../lib/utils/languageCodes";
import themeColors from "../../../../../lib/style/theme";

import InputNumberField from "../../../../Fields/InputNumberField";

const { Item } = Form;
const { Option } = Select;

const getMaxAudioChannels = (audioCodedType) => {
  switch (audioCodedType) {
    case SELECT_STREAM_CODEC_AUDIO[2].value:
      return 8;
    case SELECT_STREAM_CODEC_AUDIO[1].value:
      return 2;
    default:
      return 16;
  }
};

function GlobalAudioCodecFormItem({
  disabled,
  prefix,
  isAudioFormat,
  audioCodecType,
  isEnginePlayout,
  maxAudioChannels,
}) {
  const isAACCodeType = audioCodecType === SELECT_STREAM_CODEC_AUDIO[2].value;
  const isMpeg2audioType = audioCodecType === SELECT_STREAM_CODEC_AUDIO[1].value;

  const PARSED_CODEC_AUDIO_SAMPLE_FORMATS = isAACCodeType
    ? ACC_AUDIO_SAMPLE_RATE_FORMATS
    : GLOBAL_CODEC_AUDIO_SAMPLE_FORMATS;

  const isMaxChannelsWarning = isEnginePlayout && maxAudioChannels < 0;

  return (
    <>
      {isEnginePlayout && (
        <Col span={6}>
          <Item
            name={[...prefix, "langCode"]}
            label={<FormattedMessage id="GlobalAudioCodecFormItem.language" defaultMessage="Language" />}
            rules={[required]}
          >
            <Select disabled={disabled} options={languageCodes} showSearch />
          </Item>
        </Col>
      )}
      <Item
        name={[...prefix, "sampleFormat"]}
        label={<FormattedMessage id="GlobalAudioCodecFormItem.sampleFormat" defaultMessage="Sample format" />}
        rules={[required]}
        hidden
      >
        <Select disabled={disabled}>
          {PARSED_CODEC_AUDIO_SAMPLE_FORMATS.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </Item>
      {!isAudioFormat && (
        <Col span={8}>
          <Item
            name={[...prefix, "sampleRate"]}
            label={<FormattedMessage id="GlobalAudioCodecFormItem.sampleRate" defaultMessage="Sample rate" />}
            rules={[required]}
          >
            <Select disabled={disabled}>
              {GLOBAL_CODEC_AUDIO_SAMPLE_RATE.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
      )}
      <Col span={8}>
        <InputNumberField
          min={1}
          max={getMaxAudioChannels(audioCodecType)}
          name={[...prefix, "channels"]}
          label={
            <>
              <FormattedMessage id="GlobalAudioCodecFormItem.channels" defaultMessage="Channels" />
              {isMaxChannelsWarning && (
                <Tooltip
                  placement="top"
                  title={
                    <FormattedMessage
                      id="GlobalAudioCodecFormItem.maxChannelsWarning"
                      defaultMessage="The number of channels is greater than the maximum number
                      of channels allowed for the global processing audio formats."
                    />
                  }
                >
                  <StyledIcon>
                    <QuestionCircleFilled />
                  </StyledIcon>
                </Tooltip>
              )}
            </>
          }
          rules={[
            required,
            ...(isAACCodeType ? [AACAudioChannels] : []),
            ...(isMpeg2audioType ? [MPEGTSAudioChannels] : []),
          ]}
          disabled={disabled}
          status={isMaxChannelsWarning ? "warning" : undefined}
        />
      </Col>
    </>
  );
}

const StyledIcon = styled.span`
  color: ${themeColors.orange};
  margin-left: 5px;
`;

GlobalAudioCodecFormItem.propTypes = {
  isAudioFormat: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  audioCodecType: PropTypes.string,
  isEnginePlayout: PropTypes.bool,
  maxAudioChannels: PropTypes.number,
};

GlobalAudioCodecFormItem.defaultProps = {
  isAudioFormat: false,
  audioCodecType: null,
  isEnginePlayout: null,
  maxAudioChannels: null,
};

export default GlobalAudioCodecFormItem;
