import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Table, Badge, Tag } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { ExclamationCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import moment from "moment";

import { selectors as NODE_PLAYOUT_PLAYLIST_SELECTORS } from "../../ducks/nodePlayoutPlaylist";

import themeColor from "../../lib/style/theme";
import translatedNodeCode from "../../lib/utils/translatedNodeCode";

export default function NodePlayoutPlaylistsAlertsAlerts() {
  const { id } = useParams();
  const { formatMessage } = useIntl();

  const alerts = useSelector((state) => NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistAlerts(state, +id));

  const columns = [
    {
      title: <FormattedMessage id="NodePlayoutPlaylistsAlerts.type" defaultMessage="TYPE" />,
      dataIndex: "c",
      key: "type",
      render: (alertCode) => {
        const alertKey = `nodeAlert-${alertCode}`;

        return translatedNodeCode({ formatMessage, code: alertKey });
      },
    },
    {
      title: <FormattedMessage id="NodePlayoutPlaylistsAlerts.counter" defaultMessage="COUNTER" />,
      dataIndex: "n",
      key: "counter",
      align: "center",
      render: (counter) => (
        <Badge count={counter} showZero color={themeColor.orange} overflowCount={100000} style={{ color: "#FFF" }} />
      ),
    },
    {
      title: <FormattedMessage id="NodePlayoutPlaylistsAlerts.countUnique" defaultMessage="COUNT UNIQUE" />,
      dataIndex: "u",
      key: "countUnique",
      align: "center",
      render: (countUnique) => (
        <Badge
          count={countUnique}
          showZero
          color={themeColor.orange}
          overflowCount={100000}
          style={{ color: "#FFF" }}
        />
      ),
    },
    {
      title: <FormattedMessage id="NodePlayoutPlaylistsAlerts.status" defaultMessage="STATUS" />,
      dataIndex: "s",
      key: "status",
      align: "center",
      render: (status) => {
        return (
          <Tag color={status ? themeColor.lightRed : themeColor.green}>
            {status ? (
              <>
                <ExclamationCircleOutlined style={{ fontSize: "12px" }} />
                <span>
                  <FormattedMessage id="NodePlayoutPlaylistsAlerts.active" defaultMessage="ACTIVE" />
                </span>
              </>
            ) : (
              <>
                <CheckCircleOutlined style={{ fontSize: "12px" }} />
                <span>
                  <FormattedMessage id="NodePlayoutPlaylistsAlerts.ok" defaultMessage="OK" />
                </span>
              </>
            )}
          </Tag>
        );
      },
    },
    {
      title: <FormattedMessage id="NodePlayoutPlaylistsAlerts.lastSeen" defaultMessage="LAST SEEN" />,
      dataIndex: "t",
      key: "time",
      align: "center",
      render: (time) => (
        <>
          <span style={{ color: themeColor.maritime, marginRight: "5px" }}>{moment.unix(time).format("HH:mm:ss")}</span>
          <span>{moment.unix(time).format("DD-MM-YYYY")}</span>
        </>
      ),
    },
  ];

  return <Table dataSource={alerts} columns={columns} pagination={false} rowKey={(record) => record.c} />;
}
