import React from "react";
import { Descriptions, Empty, Card, Badge } from "antd";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import styled from "styled-components";

import badgeStatus from "../../../lib/utils/badgeStatus";

import { selectors as CHANNEL_SELECTORS } from "../../../ducks/nodeChannels";

import TranslatedStatus from "../../TranslatedStatus";
import ClientsConnected from "./ClientsConnected";
import DetailSectionPermissionLabel from "./DetailSectionPermissionLabel";
import WrongVersionConfig from "../../WrongVersionConfig";
import DetailSectionInputStatus from "./DetailSectionInputStatus";

const { Item } = Descriptions;

function DetailSection({ channels, channelId }) {
  if (!channels || channels.size === 0) {
    return (
      <Empty
        description={
          <FormattedMessage
            id="NodeChannelDetailView.missingChannelData"
            defaultMessage="Missing channel data - make sure that the channel still exists (it may have been deleted)"
          />
        }
      />
    );
  }

  const {
    status: { channelStatusText, connected, outputs, configWrongVersionRunning, inputs },
    outputs: { outputsConfigs },
  } = channels.find((channel) => channel.channelId === channelId);

  return (
    <Card
      title={
        <>
          <FormattedMessage id="DetailSection.channelStatus" defaultMessage="Channel status" />
          {configWrongVersionRunning && <WrongVersionConfig />}
        </>
      }
    >
      <Descriptions bordered column={2} size="small">
        <Item label={<FormattedMessage id="DetailSection.channelStatus" defaultMessage="Channel status" />}>
          <Badge
            status={badgeStatus(channelStatusText)}
            text={
              <StyledTranslatedStatus>
                <TranslatedStatus channelStatus={channelStatusText} />
              </StyledTranslatedStatus>
            }
          />
        </Item>
        <Item label={<FormattedMessage id="DetailSection.clientsConnected" defaultMessage="Clients connected" />}>
          <ClientsConnected connected={connected} outputs={outputs} />
        </Item>
      </Descriptions>
      {inputs && inputs.length > 0 && (
        <StyledCard title={<FormattedMessage id="NodeChannelDetailView.inputsStatus" defaultMessage="Inputs status" />}>
          <DetailSectionInputStatus inputs={inputs} channelId={channelId} />
        </StyledCard>
      )}
      {outputs && outputs.length > 0 && (
        <StyledCard
          title={<FormattedMessage id="NodeChannelDetailView.outputsStatus" defaultMessage="Outputs status" />}
        >
          <Descriptions bordered column={2} size="small">
            {outputs.map((output) => (
              <Item
                key={`${output?.p1}-${output?.p2}-${output?.p3}-${output?.e}`}
                label={
                  <DetailSectionPermissionLabel
                    permissionId={output?.p3}
                    channelId={channelId}
                    outputId={output?.p2 || output?.outputId || output?.id}
                    outputsConfigs={outputsConfigs}
                    outputName={output?.e}
                    info={output?.connectionInfo}
                    sourceId={{
                      c: +channelId,
                      e: output?.e,
                      p1: output?.p1,
                      p2: output?.p2,
                      p3: output?.p3,
                      s: output?.s,
                    }}
                  />
                }
              >
                <Badge
                  status={badgeStatus(output.threadStatusText)}
                  text={<TranslatedStatus channelStatus={output.threadStatusText} />}
                />
              </Item>
            ))}
          </Descriptions>
        </StyledCard>
      )}
    </Card>
  );
}

const StyledTranslatedStatus = styled.span`
  font-weight: bold !important;
`;

const StyledCard = styled(Card)`
  margin: 15px 0;
`;

DetailSection.propTypes = {
  channels: ImmutablePropTypes.map.isRequired,
  channelId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  channels: CHANNEL_SELECTORS.getChannels(state),
});

export default connect(mapStateToProps)(DetailSection);
