import { createAction, handleActions } from "redux-actions";
import { Map } from "immutable";
import moment from "moment";
import { createSelector } from "reselect";
import semver from "semver";

import { MQTT_STATUS, NODE_TYPES } from "../lib/utils/constants";
import splitArrayToHalf from "../lib/utils/splitArrayToHalf";

export const actions = {
  CLOUD_NODE_LICENSES: createAction("MQTT_PLATFORM/CLOUD_NODE_LICENSES"),
  CLEAR_CONNECTION: createAction("MQTT_PLATFORM/CLEAR_CONNECTION"),
  DISCONNECT: createAction("MQTT_PLATFORM/DISCONNECT"),
  OFFLINE: createAction("MQTT_PLATFORM/OFFLINE"),
  SET_NODES: createAction("MQTT_PLATFORM/SET_NODES"),
  SET_CONNECTION_AUTH: createAction("MQTT_PLATFORM/SET_CONNECTION_AUTH"),
  NODE_UPDATE_STATUS: createAction("MQTT_PLATFORM/NODE_UPDATE_STATUS"),
  CLOUD_NODE_UPDATE_STATUS: createAction("MQTT_PLATFORM/CLOUD_NODE_UPDATE_STATUS"),
  NODE_UPDATE_CNN: createAction("MQTT_PLATFORM/UPDATE_NODE_CNN"),
  NODE_UPDATE_TOTAL_NETWORK_OUTPUT: createAction("MQTT_PLATFORM/NODE_UPDATE_TOTAL_NETWORK_OUTPUT"),
  NODE_UPDATE_NETWORK_OUTPUT: createAction("MQTT_PLATFORM/NODE_UPDATE_NETWORK_OUTPUT"),
  NODE_SET_NETWORK_OUTPUT_DATE: createAction("MQTT_PLATFORM/NODE_SET_NETWORK_OUTPUT_DATE"),
  NODE_CLEAR_NETWORK_OUTPUT_DATA: createAction("MQTT_PLATFORM/NODE_CLEAR_NETWORK_OUTPUT_DATA"),
};

const defaultMountRangeDate = [moment().startOf("month"), moment().endOf("day")];

const defaultState = new Map({
  mqttConnection: null,
  cwid: null,
  nodes: [],
  cloudNodeLicenses: null,
  status: null,
  nodesTotalNetworkOutput: null,
  nodesNetworkOutputTraffic: [],
  nodesNetworkTrafficDate: defaultMountRangeDate,
});

const reducer = handleActions(
  {
    [actions.SET_CONNECTION_AUTH]: (state, { payload }) =>
      state.merge({ cwid: payload, status: MQTT_STATUS.CONNECTED, mqttConnection: true }),
    [actions.NODE_CLEAR_NETWORK_OUTPUT_DATA]: (state) => {
      return state.merge({
        nodesNetworkOutputTraffic: [],
        nodesTotalNetworkOutput: null,
      });
    },
    [actions.SET_NODES]: (state, { payload }) => {
      return state.merge({ nodes: payload || [] });
    },
    [actions.NODE_UPDATE_TOTAL_NETWORK_OUTPUT]: (state, { payload }) => {
      return state.merge({ nodesTotalNetworkOutput: payload });
    },
    [actions.CLOUD_NODE_LICENSES]: (state, { payload }) => {
      return state.merge({ cloudNodeLicenses: payload || [] });
    },
    [actions.NODE_UPDATE_NETWORK_OUTPUT]: (state, { payload }) => {
      return state.merge({ nodesNetworkOutputTraffic: payload });
    },
    [actions.NODE_SET_NETWORK_OUTPUT_DATE]: (state, { payload }) => {
      return state.merge({ nodesNetworkTrafficDate: payload });
    },
    [actions.NODE_UPDATE_STATUS]: (state, { payload: { cwid, online, nodeVersion, nodeOS } }) => {
      return state.updateIn(["nodes"], (nodes) =>
        nodes.map((node) => (node.cwid === cwid ? { ...node, online, nodeVersion, nodeOS } : node))
      );
    },
    [actions.CLOUD_NODE_UPDATE_STATUS]: (state, { payload: { cwid, cloudStatus } }) => {
      return state.updateIn(["nodes"], (nodes) =>
        nodes.map((node) => (node.cwid === cwid ? { ...node, cloudStatus } : node))
      );
    },
    [actions.NODE_UPDATE_CNN]: (state, { payload: { hostname, from } }) => {
      const parsedCWID = from.split("/")[1];

      const oldState = state.get("nodes");
      const updatedNodes = oldState.map((node) => {
        if (node.cwid === parsedCWID) {
          return { ...node, cnn: hostname };
        }

        return node;
      });

      return state.merge({ nodes: updatedNodes });
    },
    [actions.DISCONNECT]: (state) => state.merge({ mqttConnection: false, cwid: null }),
    [actions.OFFLINE]: (state) => state.merge({ mqttConnection: false, cwid: null }),
    [actions.CLEAR_CONNECTION]: () => defaultState,
  },
  defaultState
);

export const selectors = {
  getCloudNodeNetworkTraffic: (state, instanceId) => {
    const nodesNetworkOutputTraffic = state.getIn(["mqtt", "nodesNetworkOutputTraffic"]);
    const nodeNetworkTraffic = nodesNetworkOutputTraffic.find((nodeTraffic) => nodeTraffic?.instanceId === instanceId);

    return nodeNetworkTraffic;
  },
  getNodeType: (state, cwid) => {
    const nodes = state.getIn(["mqtt", "nodes"]);
    const nodeData = nodes.find((node) => node.cwid === cwid);

    return nodeData?.type;
  },
  getCloudNodeInstanceType: (state, cwid) => {
    const nodes = state.getIn(["mqtt", "nodes"]);
    const nodeData = nodes.find((node) => node.cwid === cwid);

    return nodeData?.instanceType;
  },
  getCloudNodeStatus: (state, cwid) => {
    const nodes = state.getIn(["mqtt", "nodes"]);
    const nodeData = nodes.find((node) => node.cwid === cwid);

    return nodeData?.cloudStatus;
  },
  getNodeOnline: (state, cwid) => {
    const nodes = state.getIn(["mqtt", "nodes"]);
    const nodeData = nodes.find((node) => node.cwid === cwid);

    return nodeData?.online;
  },
  getNodes: (state) => state.getIn(["mqtt", "nodes"]),
  getCloudNodeLicenses: (state) => state.getIn(["mqtt", "cloudNodeLicenses"]),
  getActiveNode: (state) => {
    const nodes = state.getIn(["mqtt", "nodes"]);
    const activeNodeCwid = state.getIn(["node", "cwid"]);

    return nodes.find((node) => node.cwid === activeNodeCwid);
  },
  getUserNodes: (state) => {
    const nodes = state.getIn(["mqtt", "nodes"]);

    return nodes.filter((node) => node.type === NODE_TYPES.USER);
  },
  getCloudNodes: (state) => {
    const nodes = state.getIn(["mqtt", "nodes"]);

    return nodes.filter((node) => node.type === NODE_TYPES.CLOUD);
  },
  getOnlineNodesSelector: (state) => {
    const nodes = state.getIn(["mqtt", "nodes"]);
    const onlineNodes = nodes.filter((node) => node.online === MQTT_STATUS.CONNECTED);
    const parsedNodesOptions = onlineNodes.map((node) => ({
      value: node.cwid,
      label: node?.cnn ? node?.cnn : node?.cwid,
    }));

    return parsedNodesOptions;
  },
  getMqttConnection: (state) => state.getIn(["mqtt", "mqttConnection"]),
  getStatus: (state) => state.getIn(["mqtt", "status"]),
  getTotalNetworkOutput: (state) => state.getIn(["mqtt", "nodesTotalNetworkOutput"]),
  getNetworkOutputTrafficDate: (state) => state.getIn(["mqtt", "nodesNetworkTrafficDate"]),
  nodeAlertsData: (state) => state.getIn(["nodeAlerts", "data"]),
};

export const getConnectedNodeV5Selector = createSelector(
  [selectors.getNodes, selectors.nodeAlertsData],
  (nodesList, nodeAlerts) => {
    const connectedNodesList = nodesList.filter(
      (node) => semver.satisfies(node?.nodeVersion, "5.x.x") && node.online === MQTT_STATUS.CONNECTED
    );

    const nodeWithAlerts = connectedNodesList.filter(
      (node) => nodeAlerts[node.cwid] && nodeAlerts[node.cwid].length > 0
    );
    const nodesNoAlerts = connectedNodesList.filter(
      (node) => !nodeAlerts[node.cwid] || (nodeAlerts[node.cwid] && nodeAlerts[node.cwid].length === 0)
    );

    const [arrayWithAlerts1, arrayWithAlerts2] = splitArrayToHalf(nodeWithAlerts);
    const [arrayNoAlerts1, arrayNoAlerts2] = splitArrayToHalf(nodesNoAlerts);

    return [
      [...arrayWithAlerts1, ...arrayNoAlerts1],
      [...arrayWithAlerts2, ...arrayNoAlerts2],
    ];
  }
);

export default reducer;
