/* eslint-disable no-unused-expressions */
import { createAction, handleActions } from "redux-actions";
import { Map } from "immutable";
import { createSelector } from "reselect";

import { OUTPUTS_TYPE, NODE_CHANNEL_STATE, OUTPUTS_TYPES } from "../lib/utils/constants";

export const actions = {
  SET_NODES_CHANNELS: createAction("CHANNELS/SET"),
  SET_LOADING_NODE_CHANNELS: createAction("CHANNELS/SET_LOADING_NODE_CHANNELS"),
  SET_NODES_SORTED_CHANNELS: createAction("CHANNELS/SET_NODES_SORTED_CHANNELS"),
  CHANGE_NODES_CHANNELS_STATUS: createAction("CHANNELS/CHANGE/STATUS"),
  CLEAR_NODES_CHANNELS: createAction("CHANNELS/CLEAR"),
};

const defaultState = new Map({ list: new Map({}), sortedList: [], loading: false });

const getOutputs = (channelConfig) => {
  const outputsConfigArray = [];
  const outputsNamesArray = [];
  const sdiOutputs = [];

  const hasNonmuxedOutputs = channelConfig?.nonMuxedOutputs && channelConfig?.nonMuxedOutputs.length > 0;
  const hasMuxedOutputs = channelConfig?.muxedOutputs && channelConfig?.muxedOutputs.length > 0;
  const hasDirectOutputs =
    channelConfig.type === OUTPUTS_TYPE.DIRECT && channelConfig?.outputs && channelConfig?.outputs.length > 0;

  const hasTranscodedOutputs =
    channelConfig.type === OUTPUTS_TYPE.TRANSCODING && channelConfig?.outputs && channelConfig?.outputs.length > 0;

  if (hasDirectOutputs) {
    channelConfig?.outputs.forEach((directOutput) => {
      outputsConfigArray.push(directOutput);
      outputsNamesArray.push(directOutput.outputName);
    });
  }

  if (hasTranscodedOutputs) {
    channelConfig?.outputs.forEach((transcodedOutput) => {
      outputsConfigArray.push(transcodedOutput);
      outputsNamesArray.push(transcodedOutput?.name || transcodedOutput?.outputName);

      if (transcodedOutput?.type === OUTPUTS_TYPES.outputDecklink.value) {
        sdiOutputs.push({
          source: transcodedOutput?.outputId,
          device: transcodedOutput?.device,
        });
      }

      if (transcodedOutput?.urlDestinations && transcodedOutput?.urlDestinations.length > 0) {
        transcodedOutput?.urlDestinations.forEach((urlDestination) => {
          outputsConfigArray.push(urlDestination);
          outputsNamesArray.push(urlDestination?.outputName);
        });
      }
    });
  }

  if (hasNonmuxedOutputs) {
    channelConfig?.nonMuxedOutputs.forEach((nonMuxedOutput) => {
      outputsConfigArray.push(nonMuxedOutput);
      outputsNamesArray.push(nonMuxedOutput.outputName);
    });
  }

  if (hasMuxedOutputs) {
    channelConfig?.muxedOutputs.forEach((muxedOutput) => {
      outputsConfigArray.push(muxedOutput);
      outputsNamesArray.push(muxedOutput?.name || muxedOutput?.outputName);

      if (muxedOutput?.outputs && muxedOutput?.outputs.length > 0) {
        muxedOutput?.outputs.forEach((muxedOutputOutput) => {
          outputsConfigArray.push(muxedOutputOutput);
          outputsNamesArray.push(muxedOutputOutput?.outputName);
        });
      }
    });
  }

  return { outputsConfigs: outputsConfigArray, outputsNames: outputsNamesArray, sdiOutputs };
};

const getCodecs = (channelConfig) => {
  const hasMuxedOutputs = channelConfig?.muxedOutputs && channelConfig?.muxedOutputs.length > 0;
  const codecsConfig = {};

  if (hasMuxedOutputs) {
    channelConfig?.muxedOutputs.forEach((muxedOutput) => {
      if (muxedOutput.muxer) {
        codecsConfig[muxedOutput.outputId] = {
          ...codecsConfig[muxedOutput.outputId],
          name: muxedOutput?.name,
          programs: muxedOutput?.muxer?.programs,
        };
      }
    });
  }

  const hasTranscodedOutputs =
    channelConfig.type === OUTPUTS_TYPE.TRANSCODING && channelConfig?.outputs && channelConfig?.outputs.length > 0;

  if (hasTranscodedOutputs) {
    channelConfig?.outputs?.forEach((transcodedOutput) => {
      codecsConfig[transcodedOutput.outputId] = {
        ...codecsConfig[transcodedOutput.outputId],
        ...transcodedOutput,
      };
    });
  }

  return codecsConfig;
};

const reducer = handleActions(
  {
    [actions.SET_NODES_SORTED_CHANNELS]: (state, { payload }) => {
      return state.merge({
        sortedList: payload,
      });
    },
    [actions.SET_NODES_CHANNELS]: (state, { payload }) => {
      const { list, cwid } = payload || {};

      const parsedPayload = list
        .map((channel) => ({
          [channel.channelId]: { ...channel, outputs: getOutputs(channel.config), codecs: getCodecs(channel.config) },
        }))
        .reduce((prev, next) => ({ ...prev, ...next }), {});

      const nodeSortOrder = window.localStorage.getItem(`nodeSortOrder-${cwid}`);

      const parsedNodeSortOrder = JSON.parse(nodeSortOrder) || [];

      const nodeChannelsIds = Object.keys(parsedPayload);

      const newChannelsIdToAddSortOrder =
        (parsedNodeSortOrder && nodeChannelsIds.filter((channelId) => !parsedNodeSortOrder.includes(channelId))) || [];

      // check if needed to add new channel ids to nodeSortOrder
      if (newChannelsIdToAddSortOrder.length > 0) {
        window.localStorage.setItem(
          `nodeSortOrder-${cwid}`,
          JSON.stringify([...parsedNodeSortOrder, ...newChannelsIdToAddSortOrder])
        );
      }

      const channelsIdsToRemoveSortOrder =
        (parsedNodeSortOrder && parsedNodeSortOrder.filter((channelId) => !nodeChannelsIds.includes(channelId))) || [];

      // check if needed to remove channel ids from nodeSortOrder
      if (channelsIdsToRemoveSortOrder.length > 0) {
        const newNodeSortOrder =
          parsedNodeSortOrder.filter((channelId) => !channelsIdsToRemoveSortOrder.includes(channelId)) || [];

        window.localStorage.setItem(`nodeSortOrder-${cwid}`, JSON.stringify(newNodeSortOrder));
      }

      const updatedNodeSortOrder = JSON.parse(window.localStorage.getItem(`nodeSortOrder-${cwid}`));

      const sortedList =
        (updatedNodeSortOrder && updatedNodeSortOrder.map((channelId) => parsedPayload[channelId])) || [];

      return state.merge({ list: new Map({ ...parsedPayload }), sortedList });
    },
    [actions.CHANGE_NODES_CHANNELS_STATUS]: (
      state,
      {
        payload: {
          status,
          status: { channelId },
          cwid,
        },
      }
    ) => {
      const channelExist = state.getIn(["list", `${channelId}`]);
      if (!channelExist) {
        return state;
      }

      const parsedState = state.updateIn(["list", `${channelId}`], (value) => {
        return {
          ...value,
          status,
        };
      });

      const updatedChannelList = parsedState.get("list").toJS();

      const updatedNodeSortOrder = JSON.parse(window.localStorage.getItem(`nodeSortOrder-${cwid}`));

      const sortedList = updatedNodeSortOrder.map((cid) => updatedChannelList[cid]);

      const updatedState = parsedState.merge({
        sortedList,
      });

      return updatedState;
    },
    [actions.SET_LOADING_NODE_CHANNELS]: (state, { payload }) => state.merge({ loading: payload }),
    [actions.CLEAR_NODES_CHANNELS]: () => defaultState,
  },
  defaultState
);

export const selectors = {
  getNodeChannelConfig: (state, channelId) => {
    const nodeChannelConfig = state.getIn(["nodeChannels", "list", channelId, "config"]);

    return nodeChannelConfig;
  },
  getChannels: (state) => state.getIn(["nodeChannels", "list"]),
  getLoadingChannels: (state) => state.getIn(["nodeChannels", "loading"]),
  getIsChannelStarted: ({ state, channelId }) => {
    return (
      state.getIn(["nodeChannels", "list", `${channelId}`, "status", "channelStatusText"]) ===
      NODE_CHANNEL_STATE.STARTED
    );
  },
  getChannelName: (state, channelId) => {
    const nodeChannelName = state.getIn(["nodeChannels", "list", channelId, "config", "name"]);

    return nodeChannelName;
  },
  getChannelSDIOutputs: (state, channelId) => {
    const sdiOutputs = state.getIn(["nodeChannels", "list", channelId, "outputs", "sdiOutputs"]);

    return sdiOutputs;
  },
  getSortedChannels: (state) => state.getIn(["nodeChannels", "sortedList"]),
  getChannelCodecs: ({ state, channelId }) => {
    const filteredChannel = state.getIn(["nodeChannels", "list"]).find((channel) => +channel.channelId === +channelId);

    return filteredChannel && filteredChannel?.codecs;
  },
  getParams: (state, params) => params,
  numberOfChannels: (state) => state.getIn(["nodeChannels", "list"]).size,
};

export const getSortedChannelsIdsSelector = createSelector([selectors.getSortedChannels], (sortedChannels) => {
  const sortedChannelsIds = sortedChannels.map((channel) => channel.channelId);

  return sortedChannelsIds;
});

export const getChannelsDataSelector = createSelector([selectors.getChannels], (channelsList) => {
  return channelsList.toJS();
});

export const getOutputVideoIncomplete = createSelector(
  [selectors.getChannels, selectors.getParams],
  (channelsList, params) => {
    const { channelId, outputIndex } = params;

    const filteredChannel = channelsList.find((channel) => +channel.channelId === +channelId);

    return (
      filteredChannel?.outputs &&
      filteredChannel?.outputs?.outputsConfigs[outputIndex] &&
      filteredChannel?.outputs?.outputsConfigs[outputIndex]?.videoStream?.videoCodec?.incomplete
    );
  }
);

export const getOutputAudioIncomplete = createSelector(
  [selectors.getChannels, selectors.getParams],
  (channelsList, params) => {
    const { channelId, outputIndex, audioStreamIndex } = params;

    const filteredChannel = channelsList.find((channel) => +channel.channelId === +channelId);

    return (
      filteredChannel?.config?.outputs &&
      filteredChannel?.config?.outputs[outputIndex]?.audioStreams &&
      filteredChannel?.config?.outputs[outputIndex]?.audioStreams[audioStreamIndex]?.audioCodec?.incomplete
    );
  }
);

export const getPlayoutOutputAudioIncomplete = createSelector(
  [selectors.getChannels, selectors.getParams],
  (channelsList, params) => {
    const { channelId, outputIndex, audioStreamIndex } = params;

    const filteredChannel = channelsList.find((channel) => +channel.channelId === +channelId);

    return (
      filteredChannel?.config?.outputs &&
      filteredChannel?.config?.outputs[outputIndex]?.audioStreams &&
      filteredChannel?.config?.outputs[outputIndex]?.audioStreams[audioStreamIndex]?.incomplete
    );
  }
);

export default reducer;
