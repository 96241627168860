import React from "react";
import styled from "styled-components";
import { Empty } from "antd";

const StyledDiv = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledNoImage = styled.div`
  width: 288px;
  height: 162px;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PreviewImage = ({ src, alt }) => {
  if (!src || src === "N/A") {
    return (
      <StyledDiv>
        <StyledNoImage>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </StyledNoImage>
      </StyledDiv>
    );
  }

  return (
    <StyledDiv>
      <img style={{ width: "288px", height: "162px" }} src={`data:image/png;base64, ${src}`} alt={alt} />
    </StyledDiv>
  );
};

export default PreviewImage;
