import React from "react";
import { Divider, Row, Col, Button } from "antd";
import { FormattedMessage } from "react-intl";
import { DeliveredProcedureOutlined } from "@ant-design/icons";
import styled from "styled-components";

const VirtualChannelNavButtons = ({ currentStep, handleChangeStep, noChannelName, loading }) => (
  <>
    <Divider />
    <FullRow justify={currentStep > 0 ? "space-between" : "end"}>
      {currentStep >= 1 && (
        <Col>
          <Button type="primary" disabled={loading} onClick={() => handleChangeStep(currentStep - 1)}>
            <FormattedMessage id="VirtualChannelNavButtons.previousStep" defaultMessage="Previous step" />
          </Button>
        </Col>
      )}
      {currentStep < 1 && (
        <Col>
          <Button
            type="primary"
            htmlType="submit"
            disabled={loading || (currentStep === 0 && noChannelName)}
            loading={loading}
            icon={<DeliveredProcedureOutlined />}
          >
            <span>
              <FormattedMessage id="VirtualChannelNavButtons.nextStep" defaultMessage="Next step" />
            </span>
          </Button>
        </Col>
      )}
      {currentStep === 1 && (
        <Button type="primary" htmlType="submit" icon={<DeliveredProcedureOutlined />}>
          <span>
            <FormattedMessage id="VirtualChannelNavButtons.finish" defaultMessage="Finish" />
          </span>
        </Button>
      )}
    </FullRow>
  </>
);

const FullRow = styled(Row)`
  width: 100%;
`;

export default VirtualChannelNavButtons;
