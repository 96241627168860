import { createAction, handleActions } from "redux-actions";
import { Map } from "immutable";

export const actions = {
  SAVE_LICENSE: createAction("LICENSE/SAVE_LICENSE"),
  SAVE_PLAYOUT_LICENSE: createAction("LICENSE/SAVE_PLAYOUT_LICENSE"),
  SAVE_CLOUD_LICENSES: createAction("LICENSE/SAVE_CLOUD_LICENSES"),
  SAVE_CLOUD_USER_LICENSES: createAction("LICENSE/SAVE_CLOUD_USER_LICENSES"),
  SET_LOADING_LICENSES: createAction("LICENSE/SET_LOADING_LICENSES"),
  CLEAR_CLOUD_USER_LICENSES: createAction("LICENSE/CLEAR_CLOUD_USER_LICENSES"),
  CLEAR_LICENSE: createAction("LICENSE/CLEAR_LICENSE"),
};

const initialState = new Map({
  licenseList: [],
  cloudLicensesList: null,
  playoutLicensesList: [],
  cloudUserLicensesList: {
    usage: null,
    limits: null,
  },
  loadingLicenses: false,
});

const reducer = handleActions(
  {
    [actions.SAVE_LICENSE]: (state, { payload }) => state.merge({ licenseList: payload }),
    [actions.SET_LOADING_LICENSES]: (state, { payload }) => state.merge({ loadingLicenses: payload }),
    [actions.SAVE_PLAYOUT_LICENSE]: (state, { payload }) =>
      state.merge({ playoutLicensesList: payload, loadingLicenses: false }),
    [actions.SAVE_CLOUD_LICENSES]: (state, { payload }) => state.merge({ cloudLicensesList: payload }),
    [actions.SAVE_CLOUD_USER_LICENSES]: (state, { payload }) => state.merge({ cloudUserLicensesList: payload }),
    [actions.CLEAR_CLOUD_USER_LICENSES]: (state) => state.merge({ cloudUserLicensesList: null }),
    [actions.CLEAR_LICENSE]: () => initialState,
  },
  initialState
);

export const selectors = {
  getLicense: (state) => state.getIn(["license", "licenseList"]),
  getLoadingLicense: (state) => state.getIn(["license", "loadingLicenses"]),
  getPlayoutLicense: (state) => state.getIn(["license", "playoutLicensesList"]),
  getCloudLicensesList: (state) => state.getIn(["license", "cloudLicensesList"]),
  getCloudUserLicensesList: (state) => state.getIn(["license", "cloudUserLicensesList", "usage"]),
  getCloudUserLicensesLimits: (state) => state.getIn(["license", "cloudUserLicensesList", "limits"]),
};

export default reducer;
