import React, { useEffect, useCallback } from "react";
import { Card, Form, Row, Col, Button, Input, Spin, Skeleton } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { selectors as NODE_SELECTORS } from "../../../../ducks/node";
import { actions as LOADING_ACTIONS } from "../../../../ducks/loadingData";
import { selectors as MQTT_SELECTORS } from "../../../../ducks/mqtt";

import MQTTService from "../../../../services/mqtt";
import CloudService from "../../../../services/cloud";

import useNodeFingerprint from "../../../../hooks/useNodeFingerprint";

import { required, az09DotSpace, max32characters } from "../../../../lib/utils/formValidation";
import { successNotification, errorNotification } from "../../../../lib/utils/notification";
import { LOADING_DATA_TYPE, MQTT_STATUS, MESSAGE_TYPE } from "../../../../lib/utils/constants";

import store from "../../../../store";

const { Item } = Form;

const EditNodeName = ({ cnn, cwid, connectionStatus, MQTTConnection }) => {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const { setFieldsValue } = form;
  const fingerprint = useNodeFingerprint();

  useEffect(() => {
    setFieldsValue({ hostname: cnn });
  }, [cnn, setFieldsValue]);
  useEffect(() => {
    const isConnection = MQTTConnection && connectionStatus === MQTT_STATUS.CONNECTED;

    if (isConnection) {
      MQTTService.sendMessage({
        topic: `node/${cwid}/in`,
        message: {
          msgType: MESSAGE_TYPE.GET_HOST_NAME,
        },
      });

      store.dispatch(LOADING_ACTIONS.SET_LOADING(LOADING_DATA_TYPE.getHostname));
    }
  }, [cwid, connectionStatus, MQTTConnection]);

  const handleFinish = useCallback(
    ({ hostname }) => {
      CloudService.editNodeHostname(
        { cnn: hostname, fingerprint, cwid },
        {
          errorNotification: errorNotification(formatMessage),
          successNotification: successNotification(formatMessage),
        }
      );
    },
    [fingerprint, formatMessage, cwid]
  );

  if (!cnn) {
    return (
      <Spin tip={<FormattedMessage id="EditNodeName.loadingCnn" defaultMessage="Loading node name" />}>
        <Skeleton active />
      </Spin>
    );
  }

  return (
    <Card title={<FormattedMessage id="EditNodeName.editNodeName" defaultMessage="Edit Node name" />}>
      <Form onFinish={handleFinish} form={form} initialValues={{ hostname: cnn }}>
        <Row gutter={24}>
          <Col span={24}>
            <Item
              name="hostname"
              label={<FormattedMessage id="EditNodeName.nodeName" defaultMessage="Node name" />}
              rules={[required, az09DotSpace, max32characters]}
            >
              <Input />
            </Item>
          </Col>
        </Row>
        <Button type="primary" htmlType="submit">
          <FormattedMessage id="global.save" defaultMessage="Save" />
        </Button>
      </Form>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  cnn: NODE_SELECTORS.getCnn(state),
  connectionStatus: MQTT_SELECTORS.getStatus(state),
  cwid: NODE_SELECTORS.getNodeCwid(state),
  fingerprint: NODE_SELECTORS.getFingerprint(state),
  MQTTConnection: MQTT_SELECTORS.getMqttConnection(state),
});

EditNodeName.propTypes = {
  cnn: PropTypes.string,
  connectionStatus: PropTypes.string,
  cwid: PropTypes.string,
  MQTTConnection: PropTypes.bool,
};

EditNodeName.defaultProps = {
  cnn: null,
  connectionStatus: null,
  cwid: null,
  MQTTConnection: null,
};

export default connect(mapStateToProps, null)(EditNodeName);
