import React from "react";
import { Tag, Badge } from "antd";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import { selectors as NODE_PLAYOUT_PLAYLIST_SELECTORS } from "../../ducks/nodePlayoutPlaylist";

import { ENGINE_PLAYOUT_SWITCHER_PATH, NODE_CHANNEL_STATE } from "../../lib/utils/constants";
import themeColors from "../../lib/style/theme";

export default function EnginePlayoutSourceSwitch({ channelId, requestedStatusText }) {
  const elemPath0 = useSelector((state) =>
    NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistElemPath0(state, +channelId)
  );

  const isEmergency = elemPath0 === ENGINE_PLAYOUT_SWITCHER_PATH.EMERGENCY;
  const isNotAvailable = elemPath0 === "N/A";

  const isStarted = requestedStatusText === NODE_CHANNEL_STATE.STARTED;

  if (!isStarted || isNotAvailable) {
    return <span>N/A</span>;
  }

  const parsedElemPath0 = elemPath0.split("(")[0];

  return (
    <Tag color={isEmergency ? themeColors.orange : themeColors.maritime}>
      <Badge
        status="processing"
        color={isEmergency ? themeColors.lightRed : themeColors.green}
        text={
          isEmergency ? (
            <FormattedMessage id="EnginePlayoutSourceSwitch.emergency" defaultMessage="EMERGENCY" />
          ) : (
            parsedElemPath0
          )
        }
      />
    </Tag>
  );
}
