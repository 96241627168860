import React from "react";
import { Col, Button, Form } from "antd";
import PropTypes from "prop-types";
import { PlusOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";

import { DEFAULT_PLAYOUT_AUDIO_STREAM } from "../../../../../../lib/utils/defaultValues/defaultTranscodedTypes";

import AudioStreamsElement from "./AudioStreamsElement";

const { List } = Form;

export default function PlayoutAudioStreams({ disabled, prefix }) {
  return (
    <List name={[...prefix, "audioStreams"]}>
      {(fields, { add, remove }) => (
        <>
          {fields.map((field, index) => {
            return (
              <AudioStreamsElement
                key={field.name}
                prefix={[...prefix, "audioStreams", index]}
                disabled={disabled}
                index={index}
                remove={() => remove(field?.name)}
              />
            );
          })}
          <Col span={24}>
            <Button
              type="dashed"
              style={{ width: "100%" }}
              onClick={() => {
                add(DEFAULT_PLAYOUT_AUDIO_STREAM);
              }}
            >
              <PlusOutlined />{" "}
              <FormattedMessage id="PlayoutAudioStreams.addAudioStream" defaultMessage="Add audio stream" />
            </Button>
          </Col>
        </>
      )}
    </List>
  );
}

PlayoutAudioStreams.propTypes = {
  disabled: PropTypes.bool.isRequired,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
};
