/* eslint-disable no-case-declarations */
import moment from "moment";

import store, { history } from "../../store";

import { actions as NAVIGATION_ACTIONS } from "../../ducks/navigation";
import { actions as MQTT_ACTIONS } from "../../ducks/mqtt";
import { actions as LOADING_ACTIONS } from "../../ducks/loadingData";
import { actions as NODE_ACTIONS } from "../../ducks/node";
import {
  SET_HIST_STATS_IN_MIN,
  SET_HIST_OUT_PERMISSION_IDS,
  SET_HIST_STATS_OUT_MIN,
  CLEAR_HIST_STATISTIC,
  SET_HIST_STATS_IN,
  SET_HIST_STATS_OUT,
} from "../../ducks/nodeStatistics";
import { SET_OWN_CHANNELS, SET_SHARED_CHANNELS } from "../../ducks/cloudChannels";
import { SET_DEVICES_LIST, SET_LOADING_DEVICES_LIST } from "../../ducks/nodeDevices";
import { SET_LOADING_FORMATS, SET_FORMAT } from "../../ducks/nodeFormats";
import { SET_PROBE, SET_PROBE_LOADING } from "../../ducks/probe";
import { actions as ACCOUNT_ACTIONS } from "../../ducks/account";
import { SET_SYSTEM_STATS } from "../../ducks/nodeSystemStatistics";
import { actions as NODE_CHANNEL_ACTIONS } from "../../ducks/nodeChannels";
import { actions as NODE_ALERTS_ACTIONS } from "../../ducks/nodeAlerts";
import { actions as NODE_PLAYOUT_PLAYLIST_ACTIONS } from "../../ducks/nodePlayoutPlaylist";
import { actions as NODE_ENGINE_LOGGING_ACTIONS } from "../../ducks/nodeEngineLogging";

// eslint-disable-next-line import/no-cycle
import NodeService from "../../services/node";

import { MESSAGE_TYPE, MQTT_STATUS } from "./constants";

import nodeNotificationHandler from "./nodeNotificationHandler";
import cloudNotificationHandler from "./cloudNotificationHandler";
import nodeNotificationErrorHandler from "./nodeNotificationErrorHandler";
import roundNumber from "./roundNumber";

const MQTTMessageHandler = ({ message, client }) => {
  const msgType = message?.msgType;

  const connectedNodeCwid = store.getState().getIn(["node", "cwid"]);
  const connectedNodeCnn = store.getState().getIn(["node", "cnn"]);

  switch (msgType) {
    case MESSAGE_TYPE.GET_NODES:
      if (message.nodes) {
        const nodeDoesNotExist =
          connectedNodeCwid && !message.nodes.map((node) => node.cwid).includes(connectedNodeCwid);
        if (nodeDoesNotExist) {
          store.dispatch(NAVIGATION_ACTIONS.CLEAR_ACTIVE_SUBMENU());
          history.push("/");
        }

        const oldNodes = store.getState().getIn(["mqtt", "nodes"]);

        const nodesToSubscribe = message.nodes.filter(
          ({ cwid: cwid1 }) => !oldNodes.some(({ cwid: cwid2 }) => cwid2 === cwid1)
        );

        const cwidsToSubscribe = nodesToSubscribe.map((node) => node.cwid);

        const nodesToUnscubscribe = oldNodes.filter(
          ({ cwid: cwid1 }) => !message.nodes.some(({ cwid: cwid2 }) => cwid2 === cwid1)
        );
        const cwidsToUnsubscribe = nodesToUnscubscribe.map((node) => node.cwid);

        cwidsToSubscribe.forEach((cwid) => {
          client.subscribe(`node/${cwid}/out/#`);

          // TO DO - uncomment when node will need welcome info
          // client.publish(
          //   `node/${cwid}/in`,
          //   JSON.stringify({ online: true, from: clientId, msgType: MESSAGE_TYPE.CLIENT_UPDATE_STATUS })
          // );
        });

        if (cwidsToUnsubscribe && cwidsToUnsubscribe.length > 0) {
          const topicsToUnsubscribe = cwidsToUnsubscribe.map((cwid) => `node/${cwid}/out/#`);

          client.unsubscribe(topicsToUnsubscribe);
        }

        const parsedNodes = message.nodes.map((node) => {
          const oldNodeData = oldNodes.find((oldNode) => oldNode.cwid === node.cwid);

          if (oldNodeData) {
            return {
              ...node,
              online: oldNodeData.online,
              nodeVersion: oldNodeData.nodeVersion,
              cloudStatus: oldNodeData?.cloudStatus,
            };
          }

          return node;
        });

        store.dispatch(MQTT_ACTIONS.SET_NODES(parsedNodes));
      }

      store.dispatch(LOADING_ACTIONS.CLEAR_LOADING());
      break;
    case MESSAGE_TYPE.NODE_UPDATE_STATUS:
      if (message.online === MQTT_STATUS.FALSE && connectedNodeCwid === message.from.split("/")[1]) {
        store.dispatch(NAVIGATION_ACTIONS.CLEAR_ACTIVE_SUBMENU());
        store.dispatch(NODE_ACTIONS.CLEAR_NODE_CONNECTION());
        nodeNotificationHandler({ msgType: MESSAGE_TYPE.ERROR, message: "nodeDisconnected", from: message.from });
        history.push("/qs-hub/hub-channels");
      }

      store.dispatch(
        MQTT_ACTIONS.NODE_UPDATE_STATUS({
          cwid: message.from && message.from.split("/")[1],
          online: message.online ? MQTT_STATUS.CONNECTED : MQTT_STATUS.DISCONNECTED,
          nodeVersion: message?.nodeVersion,
          nodeOS: message?.nodeOS,
        })
      );
      break;

    case MESSAGE_TYPE.UPDATE_PORTS:
      store.dispatch(NODE_ACTIONS.SET_NODE_PORTS(message?.portsUsed));
      break;

    case MESSAGE_TYPE.PLAYOUT_DATA:
      if (connectedNodeCwid === message.from.split("/")[1]) {
        store.dispatch(NODE_PLAYOUT_PLAYLIST_ACTIONS.SET_PLAYOUT_PLAYLIST(message));
      }

      break;
    case MESSAGE_TYPE.CLOUD_NODE_STATUS:
      store.dispatch(
        MQTT_ACTIONS.CLOUD_NODE_UPDATE_STATUS({
          cwid: message.cwid,
          cloudStatus: message.cloudStatus,
        })
      );
      break;
    case MESSAGE_TYPE.CLOUD_NODE_LICENSES:
      store.dispatch(MQTT_ACTIONS.CLOUD_NODE_LICENSES(message?.cloudNodeLicenses));
      break;
    case MESSAGE_TYPE.LIST_NDI_CHANNELS:
      store.dispatch(NODE_ACTIONS.SET_NODE_NDIS(message.channelsNdi));
      store.dispatch(LOADING_ACTIONS.SET_LOADING_NODE_NDIS(false));

      break;
    case MESSAGE_TYPE.ERROR_PLATFORM:
      cloudNotificationHandler({ msgType: MESSAGE_TYPE.ERROR, errorCode: message.errorCode });
      break;
    case MESSAGE_TYPE.WARNING_PLATFORM:
      cloudNotificationHandler({ msgType: MESSAGE_TYPE.WARNING, errorCode: message.errorCode });
      break;

    // --------- Nodes messages   ----------
    case MESSAGE_TYPE.LOG_LEVEL:
      NodeService.updateLogLevel(message);
      store.dispatch(LOADING_ACTIONS.SET_LOADING_LOG_LVL(false));
      break;
    case MESSAGE_TYPE.GET_LOGS:
      NodeService.loadMoreLogData(message);
      break;
    case MESSAGE_TYPE.GET_LOG_PARAM_NAMES:
      NodeService.setLogParams(message);
      break;
    case MESSAGE_TYPE.NEW_NODE_CHANNEL_LOG:
      NodeService.newLogData(message);
      break;
    case MESSAGE_TYPE.UPDATE_LOGS:
      NodeService.deleteLogs();
      break;
    case MESSAGE_TYPE.CHANNEL_STATUS:
      if (connectedNodeCwid === message.from.split("/")[1]) {
        NodeService.updateChannel({ status: message, cwid: connectedNodeCwid });
      }
      break;
    case MESSAGE_TYPE.GET_NODES_NETWORK_USAGE:
      store.dispatch(MQTT_ACTIONS.NODE_UPDATE_TOTAL_NETWORK_OUTPUT(message?.nodesTotalNetworkOutput));
      store.dispatch(MQTT_ACTIONS.NODE_UPDATE_NETWORK_OUTPUT(message?.data));

      break;
    case MESSAGE_TYPE.USER_PASSWORD_SET_UP:
      nodeNotificationHandler({ msgType: "success", message: "userPasswordSetUp", from: message.from });

      break;
    case MESSAGE_TYPE.USER_PASSWORD_SET_UP_FAILURE:
      nodeNotificationHandler({ msgType: "error", message: "userPasswordSetUpFailure", from: message.from });

      break;
    case MESSAGE_TYPE.GET_HOST_NAME:
      store.dispatch(MQTT_ACTIONS.NODE_UPDATE_CNN(message));
      store.dispatch(LOADING_ACTIONS.CLEAR_LOADING());
      break;
    case MESSAGE_TYPE.SET_HOSTNAME:
      store.dispatch(MQTT_ACTIONS.NODE_UPDATE_CNN(message));

      if (connectedNodeCwid === message.from.split("/")[1]) {
        store.dispatch(NODE_ACTIONS.SET_NODE_CNN(message?.hostname));
        history.push(`/${message?.from}/${message?.hostname}/system`);
      }
      break;
    case MESSAGE_TYPE.UPDATE_CHANNELS:
      NodeService.updateChannelsList(message);
      store.dispatch(NODE_CHANNEL_ACTIONS.SET_LOADING_NODE_CHANNELS(false));
      break;
    case MESSAGE_TYPE.GET_CLOUD_CONFIG:
      store.dispatch(NODE_ACTIONS.SET_NODE_CONFIG(message?.config));
      break;
    case MESSAGE_TYPE.SET_CAPABILITIES:
      NodeService.updateNodeCapabilities(message?.capabilities);
      store.dispatch(LOADING_ACTIONS.CLEAR_LOADING());
      break;
    case MESSAGE_TYPE.NETWORK_INTERFACES:
      NodeService.setNetworkInterfaces({ interfaces: message?.interfaces, from: message.from.split("/")[1] });
      break;
    case MESSAGE_TYPE.NODE_LICENSE:
      NodeService.setNodeLicenseList(message);
      store.dispatch(LOADING_ACTIONS.SET_LOADING_LICENSES(false));
      break;
    case MESSAGE_TYPE.STUND_ADDRESS:
      NodeService.setStundAddress(message?.addr);
      break;
    case MESSAGE_TYPE.GET_CHANNEL_LOGGING_OPTIONS:
      store.dispatch(
        NODE_ENGINE_LOGGING_ACTIONS.SET_ENGINE_LOGGING({
          channelId: message?.channelId,
          logging: {
            ffmpegLogLevel: message?.ffmpegLogLevel,
            logBitrateAll: message?.logBitrateAll,
            logBitrateReports: message?.logBitrateReports,
            logCGEvents: message?.logCGEvents,
            logLevel: message?.logLevel,
            logPlaylistEvents: message?.logPlaylistEvents,
            logSCTEEvents: message?.logSCTEEvents,
            logSCTENullEvents: message?.logSCTENullEvents,
            logSubtitles: message?.logSubtitles,
            logTcInOut: message?.logTcInOut,
            logTimestamps: message?.logTimestamps,
            logWholeSubtitles: message?.logWholeSubtitles,
            timeoutTimestamp: message?.timeoutTimestamp,
            extendedLoggingEnabled: message?.extendedLoggingEnabled,
          },
          cwid: message?.from.split("/")[1],
        })
      );
      break;
    case MESSAGE_TYPE.FINGERPRINT:
      NodeService.setFingerprint(message?.fingerprint);
      break;
    case MESSAGE_TYPE.SHORT_FINGERPRINT:
      NodeService.setShortFingerprint(message?.shortFingerprint);
      break;
    case MESSAGE_TYPE.ERROR:
      nodeNotificationErrorHandler({ error: message });
      store.dispatch(LOADING_ACTIONS.CLEAR_LOADING());
      store.dispatch(NODE_CHANNEL_ACTIONS.SET_LOADING_NODE_CHANNELS(false));
      break;
    case MESSAGE_TYPE.WARNING:
      nodeNotificationErrorHandler({ error: message });
      store.dispatch(LOADING_ACTIONS.CLEAR_LOADING());
      break;
    case MESSAGE_TYPE.NODE_CHANNEL_STATISTIC:
      // eslint-disable-next-line no-case-declarations
      const nodeCwid = message.from.split("/")[1];

      if (message.statsArray) {
        message.statsArray.forEach((statistic) => NodeService.updateChannelStatistic({ ...statistic, from: nodeCwid }));
      } else {
        NodeService.updateChannelStatistic({ ...message, from: nodeCwid });
      }
      break;
    case MESSAGE_TYPE.NODE_CHANNEL_STATISTIC_V5:
      if (message.statsArray) {
        message.statsArray.forEach((statistic) =>
          NodeService.updateChannelStatisticV5({ ...statistic, from: message.from.split("/")[1] })
        );
      }
      break;
    case MESSAGE_TYPE.SET_HIST_STATS_IN_MIN:
      store.dispatch(SET_HIST_STATS_IN_MIN(message?.minTimestamp));
      store.dispatch(LOADING_ACTIONS.CLEAR_LOADING());
      break;
    case MESSAGE_TYPE.SET_HIST_STATS_OUT_INIT:
      store.dispatch(SET_HIST_OUT_PERMISSION_IDS(message?.permissionIdList));
      store.dispatch(LOADING_ACTIONS.CLEAR_LOADING());
      break;
    case MESSAGE_TYPE.SET_HIST_STATS_OUT_MIN:
      store.dispatch(SET_HIST_STATS_OUT_MIN(message?.minTimestamp));
      break;
    case MESSAGE_TYPE.UPDATE_STRIPE_CUSTOMER:
      store.dispatch(ACCOUNT_ACTIONS.UPDATE_STRIPE_CUSTOMER(message?.stripeCustomer));
      break;
    case MESSAGE_TYPE.ADD_NODE_CHANNEL:
      if (message.channelId) {
        history.push(`/node/${connectedNodeCwid}/${connectedNodeCnn}/channel/edit/${message?.channelId}?page=last`);
      }
      break;
    case MESSAGE_TYPE.GET_DEVICES:
      if (message?.errorCode) {
        store.dispatch(SET_LOADING_DEVICES_LIST(false));
        nodeNotificationHandler({ msgType: "error", message: message?.errorCode, from: message.from });

        return;
      }
      store.dispatch(SET_DEVICES_LIST(message));
      store.dispatch(SET_LOADING_DEVICES_LIST(false));
      break;
    case MESSAGE_TYPE.GET_FORMATS:
      if (message?.errorCode) {
        store.dispatch(SET_LOADING_FORMATS(false));
        nodeNotificationHandler({ msgType: "error", message: message?.errorCode, from: message.from });

        return;
      }
      store.dispatch(SET_FORMAT(message));
      store.dispatch(SET_LOADING_FORMATS(false));
      break;
    case MESSAGE_TYPE.PROBE_STREAM:
      if (message?.errorCode) {
        store.dispatch(SET_PROBE_LOADING(false));
        nodeNotificationHandler({ msgType: "error", message: message?.errorCode, from: message.from });

        return;
      }

      store.dispatch(SET_PROBE(message));
      break;
    case MESSAGE_TYPE.NODE_STATS:
      store.dispatch(
        SET_SYSTEM_STATS({
          ...message,
          totalIncoming: roundNumber(message.totalIncoming),
          totalOutgoing: roundNumber(message.totalOutgoing),
          time: moment.unix(message.time).format("YYYY-MM-DD HH:mm:ss"),
        })
      );
      break;
    case MESSAGE_TYPE.LIST_EMERGENCY_IMAGES_FILES:
      store.dispatch(NODE_ACTIONS.SET_NODE_EMERGENCY_FILE_LIST(message?.list));
      break;
    case MESSAGE_TYPE.NODE_ALERTS_UPDATE:
      store.dispatch(NODE_ALERTS_ACTIONS.SET_NODE_ALERTS(message));
      break;
    case MESSAGE_TYPE.LIST_EMERGENCY_VIDEO_FILES:
      store.dispatch(NODE_ACTIONS.SET_NODE_EMERGENCY_FILE_LIST(message?.list));
      break;

    case MESSAGE_TYPE.GET_STAT_PARAM_NAMES:
      NodeService.updateHistStatParamsV5({ ...message, cwid: message.from.split("/")[1] });
      break;

    case MESSAGE_TYPE.GET_STAT_VALUES:
      NodeService.updateStatsDataV5({
        values: message?.values || [],
        timestampStart: message?.timestampStart,
        resultsPeriodLen: message?.resultsPeriodLen,
        cwid: message.from.split("/")[1],
      });
      break;
    case MESSAGE_TYPE.SET_HIST_STATS_IN:
      if (message?.error) {
        nodeNotificationHandler({ msgType: "error", message: message?.error, from: message.from });
        store.dispatch(CLEAR_HIST_STATISTIC());

        return;
      }

      store.dispatch(SET_HIST_STATS_IN(message?.data));
      store.dispatch(LOADING_ACTIONS.SET_LOADING_HIST_STATS(false));
      break;
    case MESSAGE_TYPE.SET_HIST_STATS_OUT:
      if (message?.error) {
        nodeNotificationHandler({ msgType: "error", message: message?.error, from: message.from });
        store.dispatch(CLEAR_HIST_STATISTIC());

        return;
      }

      store.dispatch(SET_HIST_STATS_OUT(message?.data));
      store.dispatch(LOADING_ACTIONS.SET_LOADING_HIST_STATS(false));
      break;
    case MESSAGE_TYPE.QS_METHOD_UNDO:
      store.dispatch(SET_OWN_CHANNELS(message.channels.own));
      store.dispatch(SET_SHARED_CHANNELS(message.channels.shared));
      break;
    default:
      // eslint-disable-next-line no-console
      // console.log("default-MQTTMessageHandler", message);
      break;
  }
};

export default MQTTMessageHandler;
