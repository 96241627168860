import React from "react";
import { Button, Popconfirm } from "antd";
import { DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

const RemoveButton = ({ handleRemove, fieldName, confirmRemoveText, disabled }) => {
  return (
    <Popconfirm
      title={confirmRemoveText}
      icon={<QuestionCircleOutlined style={{ color: "red" }} />}
      onConfirm={() => handleRemove(fieldName)}
      okText={<FormattedMessage id="general.yes" defaultMessage="Yes" />}
      cancelText={<FormattedMessage id="general.no" defaultMessage="No" />}
      disabled={disabled}
    >
      <Button icon={<DeleteOutlined />} type="primary" danger disabled={disabled}>
        <span>
          <FormattedMessage id="general.remove" defaultMessage="Remove" />
        </span>
      </Button>
    </Popconfirm>
  );
};

RemoveButton.propTypes = {
  disabled: PropTypes.bool,
  fieldName: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  handleRemove: PropTypes.func,
  confirmRemoveText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

RemoveButton.defaultProps = {
  disabled: false,
  handleRemove: null,
};

export default RemoveButton;
