import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Badge, List, Tooltip, Empty, Button } from "antd";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import dayjs from "dayjs";
import { CheckCircleOutlined, CodeOutlined, QuestionCircleFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ifProp } from "styled-tools";

import { getNodeAlertsSelector } from "../../ducks/nodeAlerts";

import useMainTheme from "../../hooks/useMainTheme";

import themeColor from "../../lib/style/theme";
import NodeTranslations from "../../lib/translations/nodeTranslations";

import NodeAlertsHeader from "./NodeAlertsHeader";

export default function NodeAlerts({ cwid, cnn }) {
  const { isDarkTheme } = useMainTheme();
  const navigate = useNavigate();
  const nodeAlerts = useSelector((state) => getNodeAlertsSelector(state, { cwid }));
  const noAlerts = nodeAlerts && nodeAlerts.length === 0;

  const handleGoToLogs = ({ source, time, channelId }) => {
    const sourceUri = encodeURIComponent(JSON.stringify({ ...source }));

    navigate(`/node/${cwid}/${cnn}/logs?source=${sourceUri}&jumpTo=${time}&c=${channelId}`);
  };

  return (
    <StyledList
      dark={isDarkTheme.toString()}
      style={{ ...(noAlerts ? { alignContent: "center" } : {}) }}
      size="small"
      grid={{ gutter: 24, column: 1 }}
      dataSource={nodeAlerts}
      locale={{
        emptyText: (
          <Empty
            image={<CheckCircleOutlined />}
            imageStyle={{ color: themeColor.green, fontSize: "60px", alignContent: "end" }}
            description={
              <StyledNoAlerts>
                <FormattedMessage id="NodeAlerts.noActiveAlerts" defaultMessage="No active alerts" />
              </StyledNoAlerts>
            }
          />
        ),
      }}
      renderItem={(item) => (
        <List.Item>
          <List
            header={
              <NodeAlertsHeader channelName={item?.channelName} cwid={cwid} cnn={cnn} channelId={item?.channelId} />
            }
            size="small"
            grid={{ gutter: 24, column: 1 }}
            dataSource={item.sources}
            renderItem={(source) => {
              if (source?.alerts) {
                const alertsData = source.alerts.map((alert) => {
                  const parsedAlertCode = `nodeAlert-${alert?.code}`;

                  return (
                    <List.Item key={parsedAlertCode}>
                      <List.Item.Meta
                        style={{ alignItems: "center", margin: "8px 0px" }}
                        avatar={
                          <Tooltip
                            placement="top"
                            title={<FormattedMessage id="NodeAlerts.counter" defaultMessage="Counter" />}
                          >
                            <StyledBadge count={alert?.count} showZero color={themeColor.orange} />
                          </Tooltip>
                        }
                        title={
                          <>
                            <Tooltip
                              placement="top"
                              title={<FormattedMessage id="NodeAlerts.source" defaultMessage="Source" />}
                            >
                              {source?.source?.e}
                            </Tooltip>
                            <Tooltip
                              placement="top"
                              title={<FormattedMessage id="NodeAlerts.lastTime" defaultMessage="Last time" />}
                            >
                              <StyledLastTime>
                                {" - "} {dayjs.unix(alert?.time).format("D MMM, HH:mm:ss")}
                              </StyledLastTime>
                            </Tooltip>{" "}
                            <Tooltip title={<FormattedMessage id="NodeAlerts.showLogs" defaultMessage="Show logs" />}>
                              <Button
                                size="small"
                                shape="circle"
                                onClick={() =>
                                  handleGoToLogs({
                                    source: source?.source,
                                    time: alert?.time,
                                    channelId: item?.channelId,
                                  })
                                }
                              >
                                <StyledCodeOutlined />
                              </Button>
                            </Tooltip>
                          </>
                        }
                        description={
                          (parsedAlertCode in NodeTranslations && (
                            <>
                              {NodeTranslations[parsedAlertCode].defaultMessage}.
                              <Tooltip
                                placement="top"
                                title={<FormattedMessage id="NodeAlerts.findOutMore" defaultMessage="Find out more" />}
                              >
                                <Button
                                  style={{ padding: "0 5px" }}
                                  type="link"
                                  href={`https://docs.cloud.quickstream.tech/qs-node-v5/alerts/${alert?.code}.html`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <QuestionCircleFilled />
                                </Button>
                              </Tooltip>
                            </>
                          )) ||
                          parsedAlertCode
                        }
                      />
                    </List.Item>
                  );
                });

                return alertsData;
              }

              return (
                <List.Item>
                  <FormattedMessage id="NodeAlerts.nodeAlertsData" defaultMessage="No alerts data" />
                </List.Item>
              );
            }}
          />
        </List.Item>
      )}
    />
  );
}

NodeAlerts.propTypes = {
  cwid: PropTypes.string.isRequired,
  cnn: PropTypes.string.isRequired,
};

const StyledNoAlerts = styled.span`
  color: ${themeColor.green};
`;

const StyledLastTime = styled.span`
  font-weight: normal;
`;

const StyledBadge = styled(Badge)`
  .ant-badge-count {
    color: #fff;
  }
`;

const StyledCodeOutlined = styled(CodeOutlined)`
  font-size: 11px;
`;

const StyledList = styled(List)`
  height: 200px;
  overflow-x: hidden;

  &::-webkit-scrollbar-track {
    padding: 2px 0;
    background-color: ${ifProp({ dark: "true" }, "#404040", "#bfbfbf")};
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: ${ifProp({ dark: "true" }, "inset 0 0 6px rgba(0, 0, 0, 0.3)", "inset 0 0 0 rgba(0, 0, 0, 0.8)")};
    background-color: ${ifProp({ dark: "true" }, "#737272", "#f2f0f0")};
  }

  .ant-list-header {
    color: ${themeColor.orange};
  }
`;
