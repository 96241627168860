import React from "react";
import { FormattedMessage } from "react-intl";
import { Tooltip } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";

const NodeEngineLoggingFFmpegLogLevelLabel = () => {
  return (
    <Tooltip
      placement="top"
      title={
        <FormattedMessage
          id="NodeEngineLoggingFFmpegLogLevelLabel.logLevelDescription"
          defaultMessage="Maximum FFmpeg Log Level cannot be greater than log level"
        />
      }
    >
      <span>
        <FormattedMessage id="NodeEngineLoggingFFmpegLogLevelLabel.logLevel" defaultMessage="FFmpeg Log Level" />{" "}
        <QuestionCircleFilled />
      </span>
    </Tooltip>
  );
};

export default NodeEngineLoggingFFmpegLogLevelLabel;
