/* eslint-disable max-len */
import { defineMessages } from "react-intl";

export default defineMessages({
  // Node local password change
  userPasswordSetUp: {
    id: "userPasswordSetUp",
    defaultMessage: "Node local password has been changed",
  },
  userPasswordSetUpFailure: {
    id: "userPasswordSetUpFailure",
    defaultMessage: "Node local password edit error - please check old password",
  },

  // warning definitions
  "warning-0": {
    id: "nodeWarning.0",
    defaultMessage: "Unknown warning",
  },
  "warning-1": {
    id: "nodeWarning.1",
    defaultMessage: "License is in 7 days expiration period",
  },
  "warning-2": {
    id: "nodeWarning.2",
    defaultMessage: "Buffer overrun occurred",
  },
  "warning-3": {
    id: "nodeWarning.3",
    defaultMessage: "Selected network interface is invalid and has been ignored",
  },
  "warning-4": {
    id: "nodeWarning.4",
    defaultMessage: "Automatic port is in use by other app, retrying on other port",
  },
  "warning-5": {
    id: "nodeWarning.5",
    defaultMessage: "Encoder is not able to encode stream in real time!",
  },
  "warning-6": {
    id: "nodeWarning.6",
    defaultMessage: "Output FPS is not stable",
  },
  "warning-7": {
    id: "nodeWarning.7",
    defaultMessage: "Emergency video is playing",
  },
  "warning-8": {
    id: "nodeWarning.8",
    defaultMessage: "There is an update ready for Client app",
  },

  "warning-100": {
    id: "nodeWarning.100",
    defaultMessage:
      "Input UDP socket's buffer low, recommended to set net.core.rmem_default and rmem_max to at least 4MB",
  },
  "warning-101": {
    id: "nodeWarning.101",
    defaultMessage:
      "Output UDP socket's buffer low, recommended to set net.core.wmem_default  and wmem_max to at least 4MB",
  },

  // 9000- 9999 reserved for qs-client warnings
  "warning-9000": {
    id: "warning-9000",
    defaultMessage:
      "Quickstream Client version is not compatible with Node version. Please download and use newest version of both components.",
  },

  // Node alerts
  "nodeAlert-0": {
    id: "nodeAlert.0",
    defaultMessage: "No Alert",
  },
  "nodeAlert-1": {
    id: "nodeAlert.1",
    defaultMessage: "Critical error",
  },
  "nodeAlert-2": {
    id: "nodeAlert.2",
    defaultMessage: "Licensing error",
  },
  "nodeAlert-3": {
    id: "nodeAlert.3",
    defaultMessage: "Source video error",
  },
  "nodeAlert-4": {
    id: "nodeAlert.4",
    defaultMessage: "Network error",
  },
  "nodeAlert-5": {
    id: "nodeAlert.5",
    defaultMessage: "Channel initialization error",
  },
  "nodeAlert-6": {
    id: "nodeAlert.6",
    defaultMessage: "Output Error",
  },
  "nodeAlert-7": {
    id: "nodeAlert.7",
    defaultMessage: "Cloud connection error",
  },
  "nodeAlert-8": {
    id: "nodeAlert.8",
    defaultMessage: "Performance error",
  },
  "nodeAlert-9": {
    id: "nodeAlert.9",
    defaultMessage: "Emergency video is playing",
  },
  "nodeAlert-10": {
    id: "nodeAlert.10",
    defaultMessage: "There is an update ready for Client app",
  },

  // Playout engine alerts
  "nodeAlert-100": {
    id: "nodeAlert.100",
    defaultMessage: "Api has sent empty playlist",
  },
  "nodeAlert-101": {
    id: "nodeAlert.101",
    defaultMessage: "Api has sent invalid playlist",
  },
  "nodeAlert-102": {
    id: "nodeAlert.102",
    defaultMessage: "Playlist is nearing end, action needed",
  },
  "nodeAlert-103": {
    id: "nodeAlert.103",
    defaultMessage: "Unexpected API disconnect",
  },
  "nodeAlert-104": {
    id: "nodeAlert.104",
    defaultMessage: "Subtitles missing",
  },
  "nodeAlert-105": {
    id: "nodeAlert.105",
    defaultMessage: "File is missing",
  },
  "nodeAlert-106": {
    id: "nodeAlert.106",
    defaultMessage: "Playing from backup",
  },
  "nodeAlert-107": {
    id: "nodeAlert.107",
    defaultMessage: "External audio file missing",
  },
  "nodeAlert-108": {
    id: "nodeAlert.108",
    defaultMessage: "CG is missing",
  },
  "nodeAlert-109": {
    id: "nodeAlert.109",
    defaultMessage: "Failed to load emergency file",
  },
  "nodeAlert-110": {
    id: "nodeAlert.110",
    defaultMessage: "Failed to load adbreak file",
  },

  "nodeAlert-120": {
    id: "nodeAlert.120",
    defaultMessage: "Live has disconnected",
  },
  "nodeAlert-121": {
    id: "nodeAlert.121",
    defaultMessage: "External audio file is too short",
  },
  "nodeAlert-122": {
    id: "nodeAlert.122",
    defaultMessage: "File is too short",
  },
  "nodeAlert-123": {
    id: "nodeAlert.123",
    defaultMessage: "Still frame detected",
  },
  "nodeAlert-124": {
    id: "nodeAlert.124",
    defaultMessage: "Audio silence detected",
  },
  "nodeAlert-125": {
    id: "nodeAlert.125",
    defaultMessage: "File is broken",
  },
  "nodeAlert-126": {
    id: "nodeAlert.126",
    defaultMessage: "External audio file is broken",
  },

  "nodeAlert-140": {
    id: "nodeAlert.140",
    defaultMessage: "Caspar control connection is offline",
  },
  "nodeAlert-141": {
    id: "nodeAlert.141",
    defaultMessage: "Caspar failed to load CG",
  },
  "nodeAlert-142": {
    id: "nodeAlert.142",
    defaultMessage: "Caspar data connection is offline",
  },

  // Hardware errors
  "nodeAlert-200": {
    id: "nodeAlert.200",
    defaultMessage: "Hardware error",
  },

  // 1 -  999 system errors (ex. disc, processor)
  0: {
    id: "nodeError.0",
    defaultMessage: "Status OK",
  },
  1: {
    id: "nodeError.1",
    defaultMessage: "Cannot create folder for configuration files",
  },
  2: {
    id: "nodeError.2",
    defaultMessage: "Cannot create folder for log files",
  },
  3: {
    id: "nodeError.3",
    defaultMessage: "Configuration file is not writable",
  },
  4: {
    id: "nodeError.4",
    defaultMessage: "Cannot open websocket",
  },
  // 5: {
  //   id: "nodeError.5",
  //   defaultMessage: "Cannot create REST API server",
  // },
  6: {
    id: "nodeError.6",
    defaultMessage: "Cannot generate crypto keys",
  },
  7: {
    id: "nodeError.7",
    defaultMessage: "Cannot create folder for PID file",
  },
  8: {
    id: "nodeError.8",
    defaultMessage: "Cannot open/create PID file",
  },
  9: {
    id: "nodeError.9",
    defaultMessage: "Cannot start qs-node service, service is already running",
  },
  10: {
    id: "nodeError.10",
    defaultMessage: "Invalid environment",
  },
  11: {
    id: "nodeError.11",
    defaultMessage: "Cannot open system file needed to generate stats",
  },
  12: {
    id: "nodeError.12",
    defaultMessage: "Wrong values in system file needed to generate stats",
  },
  13: {
    id: "nodeError.13",
    defaultMessage: "Cannot create actions' dump file",
  },

  // 900 - 999 windows errors
  900: {
    id: "nodeError.900",
    defaultMessage: "WMI error",
  },

  // 1000 - 1999 application errors (ex. database errors)
  1000: {
    id: "nodeError.1000",
    defaultMessage: "Unknown database exception",
  },
  1001: {
    id: "nodeError.1001",
    defaultMessage: "Database error while inserting ",
  },
  1002: {
    id: "nodeError.1002",
    defaultMessage: "Database error while updating",
  },
  1003: {
    id: "nodeError.1003",
    defaultMessage: "Database error while querying ",
  },
  1004: {
    id: "nodeError.1004",
    defaultMessage: "Database error while deleting",
  },
  1005: {
    id: "nodeError.1005",
    defaultMessage: "Inserting duplicate value failed (Unique constraint failed)",
  },
  1006: {
    id: "nodeError.1006",
    defaultMessage: "Cannot start transaction in database",
  },
  1007: {
    id: "nodeError.1007",
    defaultMessage: "Cannot commit transaction in database",
  },
  1008: {
    id: "nodeError.1008",
    defaultMessage: "Cannot rollback transaction in database",
  },
  1009: {
    id: "nodeError.1009",
    defaultMessage: "Cannot create table in database",
  },
  1010: {
    id: "nodeError.1010",
    defaultMessage: "Cannot create database",
  },
  1011: {
    id: "nodeError.1011",
    defaultMessage: "Database was created for other environment than currently running node",
  },
  1012: {
    id: "nodeError.1012",
    defaultMessage: "Unforeseen error",
  },
  1013: {
    id: "nodeError.1013",
    defaultMessage: "Emergency engine shutdown - memory saturation",
  },

  // WEB CLIENT UPDATE ERRORS
  1900: {
    id: "nodeError.1900",
    defaultMessage: "Web client update - cannot create folder",
  },
  1901: {
    id: "nodeError.1901",
    defaultMessage: "Web client update - cannot remove folder",
  },
  1902: {
    id: "nodeError.1902",
    defaultMessage: "Web client update - cannot rename folder",
  },
  1903: {
    id: "nodeError.1903",
    defaultMessage: "Web client update - cannot extract files",
  },
  1904: {
    id: "nodeError.1904",
    defaultMessage: "Web client update - incorrect checksum",
  },

  // 2000 - 2999 licenses errors
  2000: {
    id: "nodeError.2000",
    defaultMessage: "License Id is empty",
  },
  2001: {
    id: "nodeError.2001",
    defaultMessage: "License Id not found",
  },
  2002: {
    id: "nodeError.2002",
    defaultMessage: "License has expired",
  },
  2003: {
    id: "nodeError.2003",
    defaultMessage: "Unable to add channel, Maximum number of channels for this license is reached",
  },
  2004: {
    id: "nodeError.2004",
    defaultMessage: "Cannot remove license (license probably in use)",
  },
  2005: {
    id: "nodeError.2005",
    defaultMessage: "Cannot decode license",
  },
  2006: {
    id: "nodeError.2006",
    defaultMessage: "License token cannot be empty",
  },
  2007: {
    id: "nodeError.2007",
    defaultMessage: "License has invalid signature, probably wrong Hardware ID",
  },
  2008: {
    id: "nodeError.2008",
    defaultMessage: "Invalid license",
  },
  2009: {
    id: "nodeError.2009",
    defaultMessage: "License server connection error",
  },
  2010: {
    id: "nodeError.2010",
    defaultMessage: "License is invalid",
  },
  2011: {
    id: "nodeError.2011",
    defaultMessage: "License has not been decoded properly",
  },
  2012: {
    id: "nodeError.2012",
    defaultMessage: "Incomplete reply from license server",
  },

  // 3000 - 3999 channels configuration errors
  3000: {
    id: "nodeError.3000",
    defaultMessage: "Channel name cannot be empty",
  },
  3001: {
    id: "nodeError.3001",
    defaultMessage: "Invalid version of Quickstream Client: Empty version string",
  },
  3002: {
    id: "nodeError.3002",
    defaultMessage: "Channel license ID cannot be empty",
  },
  3003: {
    id: "nodeError.3003",
    defaultMessage: "Incorrect channel ID",
  },
  3004: {
    id: "nodeError.3004",
    defaultMessage: "Invalid version of Quickstream Client: Client is newer than Node",
  },
  3005: {
    id: "nodeError.3005",
    defaultMessage: "Invalid version of Quickstream Client: Invalid version string",
  },
  3006: {
    id: "nodeError.3006",
    defaultMessage: "Quickstream Client sent too old configuration, rejecting",
  },
  3007: {
    id: "nodeError.3007",
    defaultMessage: "Invalid value in configuration",
  },
  3008: {
    id: "nodeError.3008",
    defaultMessage: "Channel type cannot be empty",
  },
  3009: {
    id: "nodeError.3009",
    defaultMessage: "Channel type invalid",
  },

  // 3101: {
  //   id: "nodeError.3101",
  //   defaultMessage: "Missing input configuration",
  // },
  // 3102: {
  //   id: "nodeError.3102",
  //   defaultMessage: "Missing output configuration",
  // },
  // 3103: {
  //   id: "nodeError.3103",
  //   defaultMessage: "Missing required option for input",
  // },
  // 3104: {
  //   id: "nodeError.3104",
  //   defaultMessage: "Missing required option for output",
  // },
  // 3105: {
  //   id: "nodeError.3105",
  //   defaultMessage: "Unknown protocol for input",
  // },
  // 3106: {
  //   id: "nodeError.3106",
  //   defaultMessage: "Unknown protocol for output",
  // },
  // 3107: {
  //   id: "nodeError.3107",
  //   defaultMessage: "Invalid configuration for input",
  // },
  // 3108: {
  //   id: "nodeError.3108",
  //   defaultMessage: "Invalid configuration for output",
  // },
  // 3109: {
  //   id: "nodeError.3109",
  //   defaultMessage: "Incorrect IP for input",
  // },
  // 3110: {
  //   id: "nodeError.3110",
  //   defaultMessage: "Incorrect IP for output",
  // },
  // 3111: {
  //   id: "nodeError.3111",
  //   defaultMessage: "Missing required SRT option in input",
  // },
  // 3112: {
  //   id: "nodeError.3112",
  //   defaultMessage: "Missing required SRT option in output",
  // },
  // 3113: {
  //   id: "nodeError.3113",
  //   defaultMessage: "Invalid port for SRT input",
  // },
  // 3114: {
  //   id: "nodeError.3114",
  //   defaultMessage: "Invalid port for SRT output",
  // },
  // 3115: {
  //   id: "nodeError.3115",
  //   defaultMessage: "Missing interface IP for rendezvous SRT input",
  // },
  // 3116: {
  //   id: "nodeError.3116",
  //   defaultMessage: "Missing interface IP for rendezvous SRT output",
  // },
  // 3117: {
  //   id: "nodeError.3117",
  //   defaultMessage: "Invalid IP class for UDP input",
  // },
  // 3118: {
  //   id: "nodeError.3118",
  //   defaultMessage: "Invalid IP class for UDP output",
  // },
  // 3119: {
  //   id: "nodeError.3119",
  //   defaultMessage: "Invalid port for UDP input",
  // },
  // 3120: {
  //   id: "nodeError.3120",
  //   defaultMessage: "Invalid port for UDP output",
  // },

  // // QS method errors
  // 3201: {
  //   id: "nodeError.3201",
  //   defaultMessage: "Invalid PermissionId for QS input",
  // },
  // 3202: {
  //   id: "nodeError.3202",
  //   defaultMessage: "Invalid CloudId for QS input",
  // },
  // 3203: {
  //   id: "nodeError.3203",
  //   defaultMessage: "Invalid CloudId for QS output",
  // },
  // 3204: {
  //   id: "nodeError.3204",
  //   defaultMessage: "Channel reached connection limit!",
  // },
  // 3205: {
  //   id: "nodeError.3205",
  //   defaultMessage: "Quickstream channel config missing stoken value",
  // },
  // 3206: {
  //   id: "nodeError.3206",
  //   defaultMessage: "Config version mismatch: QS Stream in 1.0 config",
  // },
  // // 3207: {
  // //   id: "nodeError.3207",
  // //   defaultMessage: "Channel not ready, server not connected yet",
  // // },
  // 3208: {
  //   id: "nodeError.3208",
  //   defaultMessage: "Channel not ready, not completely configured yet - no port range",
  // },
  // 3209: {
  //   id: "nodeError.3209",
  //   defaultMessage: "Channel not ready, not completely configured yet - missing external IP",
  // },
  // 3210: {
  //   id: "nodeError.3210",
  //   defaultMessage: "No free ports available to connect!",
  // },
  // // 3211: {
  // //   id: "nodeError.3211",
  // //   defaultMessage: "Cannot connect to server",
  // // },

  // 3400 - 3499 New configuration errors
  3400: {
    id: "nodeError.3400",
    defaultMessage: "Feature not implemented yet",
  },
  // 3401: {
  //   id: "nodeError.3401",
  //   defaultMessage: "No inputs in configuration",
  // },
  // 3402: {
  //   id: "nodeError.3402",
  //   defaultMessage: "Missing input type",
  // },
  3403: {
    id: "nodeError.3403",
    defaultMessage: "Invalid input type",
  },
  // 3404: {
  //   id: "nodeError.3404",
  //   defaultMessage: "No outputs in configuration",
  // },
  // 3405: {
  //   id: "nodeError.3405",
  //   defaultMessage: "Missing output type",
  // },
  3406: {
    id: "nodeError.3406",
    defaultMessage: "Invalid output type",
  },
  3407: {
    id: "nodeError.3407",
    defaultMessage: "Missing stream type",
  },
  3408: {
    id: "nodeError.3408",
    defaultMessage: "Unknown stream type",
  },
  3409: {
    id: "nodeError.3409",
    defaultMessage: "Missing required param in config",
  },
  3410: {
    id: "nodeError.3410",
    defaultMessage: "Incorrect value in config",
  },
  3411: {
    id: "nodeError.3411",
    defaultMessage: "Missing object type",
  },
  3412: {
    id: "nodeError.3412",
    defaultMessage: "Invalid video codec type",
  },
  // 3413: {
  //   id: "nodeError.3413",
  //   defaultMessage: "Missing input name",
  // },
  3414: {
    id: "nodeError.3414",
    defaultMessage: "Invalid audio codec type",
  },
  3415: {
    id: "nodeError.3415",
    defaultMessage: "Invalid stream type",
  },
  3417: {
    id: "nodeError.3417",
    defaultMessage: "Invalid muxer type",
  },
  3419: {
    id: "nodeError.3419",
    defaultMessage: "Invalid filter type",
  },
  3420: {
    id: "nodeError.3420",
    defaultMessage: "Invalid stream name, no such stream defined",
  },
  3421: {
    id: "nodeError.3421",
    defaultMessage: "Invalid Json in config",
  },
  3423: {
    id: "nodeError.3423",
    defaultMessage: "Invalid filter type",
  },
  3424: {
    id: "nodeError.3424",
    defaultMessage: "Wrong config type",
  },
  3425: {
    id: "nodeError.3425",
    defaultMessage: "MpegTS PID is outside of a valid range <32,8191>",
  },
  3426: {
    id: "nodeError.3426",
    defaultMessage: "Quickstream method cannot be a backup",
  },
  3427: {
    id: "nodeError.3427",
    defaultMessage: "Quickstream method cannot have a backup (yet?)",
  },
  3428: {
    id: "nodeError.3428",
    defaultMessage: "Program list in mpegts muxer is empty",
  },
  3429: {
    id: "nodeError.3429",
    defaultMessage: "Restrictions error",
  },
  3430: {
    id: "nodeError.3430",
    defaultMessage: "Invalid url specified in config",
  },
  3431: {
    id: "nodeError.3431",
    defaultMessage: "Invalid IP address on config",
  },
  3432: {
    id: "nodeError.3432",
    defaultMessage: "Invalid port on config",
  },
  3433: {
    id: "nodeError.3433",
    defaultMessage: "Missing input configuration",
  },
  3434: {
    id: "nodeError.3434",
    defaultMessage: "NDI is not available - CPU is missing SSSE3 extension",
  },
  3435: {
    id: "nodeError.3435",
    defaultMessage: "Invalid pixel format for output",
  },
  3436: {
    id: "nodeError.3436",
    defaultMessage: "Duplicate local port found, configuration wouldn't work properly",
  },
  3437: {
    id: "nodeError.3437",
    defaultMessage: "UDP Multicast needs to have an interface selected on Windows",
  },
  3438: {
    id: "nodeError.3438",
    defaultMessage: "Path must be absolute",
  },

  // 3600 - 3699 new thread mechanics errors
  3600: {
    id: "nodeError.3600",
    defaultMessage: "Couldn't initialize decoder",
  },
  3601: {
    id: "nodeError.3601",
    defaultMessage: "Couldn't initialize decoder thread",
  },
  3602: {
    id: "nodeError.3602",
    defaultMessage: "Couldn't initialize preview thread",
  },
  3603: {
    id: "nodeError.3603",
    defaultMessage: "Couldn't find specified stream in input",
  },
  3604: {
    id: "nodeError.3604",
    defaultMessage: "Couldn't find specified stream in encoders",
  },
  3605: {
    id: "nodeError.3605",
    defaultMessage: "Couldn't start thread, encoder not initialized yet",
  },
  3606: {
    id: "nodeError.3606",
    defaultMessage: "Couldn't initialize encoder",
  },
  3607: {
    id: "nodeError.3607",
    defaultMessage: "Couldn't initialize video filters",
  },
  3608: {
    id: "nodeError.3608",
    defaultMessage: "Couldn't initialize audio filters",
  },
  // 3609: {
  //   id: "nodeError.3609",
  //   defaultMessage: "Couldn't filter video frame",
  // },
  3610: {
    id: "nodeError.3610",
    defaultMessage: "Cannot initialize, out of memory",
  },
  3611: {
    id: "nodeError.3611",
    defaultMessage: "Cannot create temporary objects, out of memory",
  },
  3612: {
    id: "nodeError.3612",
    defaultMessage: "Incorrect type of stream",
  },
  3613: {
    id: "nodeError.3613",
    defaultMessage: "Incorrect codec used for output",
  },
  3614: {
    id: "nodeError.3614",
    defaultMessage: "Output thread cannot write header",
  },
  3615: {
    id: "nodeError.3615",
    defaultMessage: "Can not initialize output thread",
  },
  3616: {
    id: "nodeError.3616",
    defaultMessage: "Engine not ready, missing external IP",
  },
  3617: {
    id: "nodeError.3617",
    defaultMessage: "Output thread got incorrect data",
  },
  3618: {
    id: "nodeError.3618",
    defaultMessage: "Engine needs to stop due to problem with config",
  },

  // New SRT and UDP errors
  3800: {
    id: "nodeError.3800",
    defaultMessage: "Cannot create socket",
  },
  3801: {
    id: "nodeError.3801",
    defaultMessage: "Cannot setup socket",
  },
  3802: {
    id: "nodeError.3802",
    defaultMessage: "Cannot resolve address",
  },
  3803: {
    id: "nodeError.3803",
    defaultMessage: "Cannot bind socket",
  },
  3804: {
    id: "nodeError.3804",
    defaultMessage: "Cannot connect socket",
  },
  3805: {
    id: "nodeError.3805",
    defaultMessage: "Socket read error",
  },
  3806: {
    id: "nodeError.3806",
    defaultMessage: "Socket write error",
  },
  3807: {
    id: "nodeError.3807",
    defaultMessage: "SRT has opened too many file descriptors, engine restart necessary",
  },

  // Others errors
  3900: {
    id: "nodeError.3900",
    defaultMessage: "Error when muxing streams",
  },
  3901: {
    id: "nodeError.3901",
    defaultMessage: "Out of memory",
  },
  3902: {
    id: "nodeError.3902",
    defaultMessage: "Unknown protocol for output",
  },
  3903: {
    id: "nodeError.3903",
    defaultMessage: "Invalid timestamps in NDI input",
  },
  3904: {
    id: "nodeError.3904",
    defaultMessage: "Missing decklink driver",
  },
  3905: {
    id: "nodeError.3905",
    defaultMessage: "No such decklink device",
  },

  // // 4000 - 4999 Video errors
  // 4001: {
  //   id: "nodeError.4001",
  //   defaultMessage: "Input video error: Cannot open stream",
  // },
  // 4002: {
  //   id: "nodeError.4002",
  //   defaultMessage: "Output video error: Cannot open stream",
  // },
  // 4003: {
  //   id: "nodeError.4003",
  //   defaultMessage: "Input video error: Cannot read stream",
  // },
  // 4004: {
  //   id: "nodeError.4004",
  //   defaultMessage: "Output video error: Cannot write stream",
  // },
  // 4005: {
  //   id: "nodeError.4005",
  //   defaultMessage: "Input video error: Out of memory",
  // },
  // 4006: {
  //   id: "nodeError.4006",
  //   defaultMessage: "Output video error: Out of memory",
  // },
  // 4007: {
  //   id: "nodeError.4007",
  //   defaultMessage: "Input video error: Not implemented",
  // },
  // 4008: {
  //   id: "nodeError.4008",
  //   defaultMessage: "Output video error: Not implemented",
  // },
  // 4009: {
  //   id: "nodeError.4009",
  //   defaultMessage: "Input video error",
  // },
  // 4010: {
  //   id: "nodeError.4010",
  //   defaultMessage: "Output video error",
  // },
  // 4011: {
  //   id: "nodeError.4011",
  //   defaultMessage: "Input video error: incorrect configuration",
  // },
  // 4012: {
  //   id: "nodeError.4012",
  //   defaultMessage: "Output video error: incorrect configuration",
  // },
  // 4013: {
  //   id: "nodeError.4013",
  //   defaultMessage: "Input video error: missing configuration option",
  // },
  // 4014: {
  //   id: "nodeError.4014",
  //   defaultMessage: "Output video error: missing configuration option",
  // },
  // 4015: {
  //   id: "nodeError.4015",
  //   defaultMessage: "Input video error: protocol not found",
  // },
  // 4016: {
  //   id: "nodeError.4016",
  //   defaultMessage: "Output video error: protocol not found",
  // },
  // 4017: {
  //   id: "nodeError.4017",
  //   defaultMessage: "Input video error: out of memory",
  // },
  // 4018: {
  //   id: "nodeError.4018",
  //   defaultMessage: "Output video error: out of memory",
  // },
  // 4019: {
  //   id: "nodeError.4019",
  //   defaultMessage: "Input video error: timeout",
  // },
  // 4020: {
  //   id: "nodeError.4020",
  //   defaultMessage: "Output video error: timeout",
  // },
  // 4021: {
  //   id: "nodeError.4021",
  //   defaultMessage: "Error when resampling audio",
  // },
  // 4022: {
  //   id: "nodeError.4022",
  //   defaultMessage: "Error when muxing streams",
  // },

  // // SRT errors
  // 4200: {
  //   id: "nodeError.4200",
  //   defaultMessage: "Input SRT connection error",
  // },
  // 4201: {
  //   id: "nodeError.4201",
  //   defaultMessage: "Output SRT connection error",
  // },
  // 4202: {
  //   id: "nodeError.4202",
  //   defaultMessage: "Input SRT connection error: Cannot initialize resources",
  // },
  // 4203: {
  //   id: "nodeError.4203",
  //   defaultMessage: "Output SRT connection error: Cannot initialize resources",
  // },
  // 4204: {
  //   id: "nodeError.4204",
  //   defaultMessage: "Input SRT connection error: Cannot configure socket",
  // },
  // 4205: {
  //   id: "nodeError.4205",
  //   defaultMessage: "Output SRT connection error: Cannot configure socket",
  // },
  // 4206: {
  //   id: "nodeError.4206",
  //   defaultMessage: "Input SRT connection error: Security error",
  // },
  // 4207: {
  //   id: "nodeError.4207",
  //   defaultMessage: "Output SRT connection error: Security error",
  // },
  // 4208: {
  //   id: "nodeError.4208",
  //   defaultMessage: "Input SRT connection error: Connection failure",
  // },
  // 4209: {
  //   id: "nodeError.4209",
  //   defaultMessage: "Output SRT connection error: Connection failure",
  // },
  // 4210: {
  //   id: "nodeError.4210",
  //   defaultMessage: "Input SRT connection error: Resource allocation error",
  // },
  // 4211: {
  //   id: "nodeError.4211",
  //   defaultMessage: "Output SRT connection error: Resource allocation error",
  // },
  // 4212: {
  //   id: "nodeError.4212",
  //   defaultMessage: "Input SRT connection error: Threading error",
  // },
  // 4213: {
  //   id: "nodeError.4213",
  //   defaultMessage: "Output SRT connection error: Threading error",
  // },
  // 4214: {
  //   id: "nodeError.4214",
  //   defaultMessage: "Input SRT connection error: Not enough memory error",
  // },
  // 4215: {
  //   id: "nodeError.4215",
  //   defaultMessage: "Output SRT connection error: Not enough memory error",
  // },
  // 4216: {
  //   id: "nodeError.4216",
  //   defaultMessage: "Input SRT connection error: File operation error",
  // },
  // 4217: {
  //   id: "nodeError.4217",
  //   defaultMessage: "Output SRT connection error: File operation error",
  // },
  // 4218: {
  //   id: "nodeError.4218",
  //   defaultMessage: "Input SRT connection error: Error reading file - invalid offset",
  // },
  // 4219: {
  //   id: "nodeError.4219",
  //   defaultMessage: "Output SRT connection error: Error reading file - invalid offset",
  // },
  // 4220: {
  //   id: "nodeError.4220",
  //   defaultMessage: "Input SRT connection error: Error reading file (permissions?)",
  // },
  // 4221: {
  //   id: "nodeError.4221",
  //   defaultMessage: "Output SRT connection error: Error reading file (permissions?)",
  // },
  // 4222: {
  //   id: "nodeError.4222",
  //   defaultMessage: "Input SRT connection error: Error writing file - invalid offset",
  // },
  // 4223: {
  //   id: "nodeError.4223",
  //   defaultMessage: "Output SRT connection error: Error writing file - invalid offset",
  // },
  // 4224: {
  //   id: "nodeError.4224",
  //   defaultMessage: "Input SRT connection error: Error opening file for writing (permissions?)",
  // },
  // 4225: {
  //   id: "nodeError.4225",
  //   defaultMessage: "Output SRT connection error: Error opening file for writing (permissions?)",
  // },
  // 4226: {
  //   id: "nodeError.4226",
  //   defaultMessage: "Input SRT connection error: Internal error - invalid operation",
  // },
  // 4227: {
  //   id: "nodeError.4227",
  //   defaultMessage: "Output SRT connection error: Internal error - invalid operation",
  // },
  // 4228: {
  //   id: "nodeError.4228",
  //   defaultMessage: "Input SRT connection error: Socket is already bound",
  // },
  // 4229: {
  //   id: "nodeError.4229",
  //   defaultMessage: "Output SRT connection error: Socket is already bound",
  // },
  // 4230: {
  //   id: "nodeError.4230",
  //   defaultMessage: "Input SRT connection error: Socket is already connected",
  // },
  // 4231: {
  //   id: "nodeError.4231",
  //   defaultMessage: "Output SRT connection error: Socket is already connected",
  // },
  // 4232: {
  //   id: "nodeError.4232",
  //   defaultMessage: "Input SRT connection error: Invalid param",
  // },
  // 4233: {
  //   id: "nodeError.4233",
  //   defaultMessage: "Output SRT connection error: Invalid param",
  // },
  // 4234: {
  //   id: "nodeError.4234",
  //   defaultMessage: "Input SRT connection error: Invalid socket used",
  // },
  // 4235: {
  //   id: "nodeError.4235",
  //   defaultMessage: "Output SRT connection error: Invalid socket used",
  // },
  // 4236: {
  //   id: "nodeError.4236",
  //   defaultMessage: "Input SRT connection error: Socket not a listener",
  // },
  // 4237: {
  //   id: "nodeError.4237",
  //   defaultMessage: "Output SRT connection error: Socket not a listener",
  // },
  // 4238: {
  //   id: "nodeError.4238",
  //   defaultMessage: "Input SRT connection error: Socket cannot be set as rendezvous",
  // },
  // 4239: {
  //   id: "nodeError.4239",
  //   defaultMessage: "Output SRT connection error: Socket cannot be set as rendezvous",
  // },
  // 4240: {
  //   id: "nodeError.4240",
  //   defaultMessage: "Input SRT connection error: Socket set to rendezvous before binding",
  // },
  // 4241: {
  //   id: "nodeError.4241",
  //   defaultMessage: "Output SRT connection error: Socket set to rendezvous before binding",
  // },
  // 4242: {
  //   id: "nodeError.4242",
  //   defaultMessage: "Input SRT connection error: Incorrect API usage in message mode",
  // },
  // 4243: {
  //   id: "nodeError.4243",
  //   defaultMessage: "Output SRT connection error: Incorrect API usage in message mode",
  // },
  // 4244: {
  //   id: "nodeError.4244",
  //   defaultMessage: "Input SRT connection error: Incorrect API usage in stream mode",
  // },
  // 4245: {
  //   id: "nodeError.4245",
  //   defaultMessage: "Output SRT connection error: Incorrect API usage in stream mode",
  // },
  // 4246: {
  //   id: "nodeError.4246",
  //   defaultMessage: "Input SRT connection error: Socket already used as listener",
  // },
  // 4247: {
  //   id: "nodeError.4247",
  //   defaultMessage: "Output SRT connection error: Socket already used as listener",
  // },
  // 4248: {
  //   id: "nodeError.4248",
  //   defaultMessage: "Input SRT connection error: Message too large to fit in sending buffer",
  // },
  // 4249: {
  //   id: "nodeError.4249",
  //   defaultMessage: "Output SRT connection error: Message too large to fit in sending buffer",
  // },
  // 4250: {
  //   id: "nodeError.4250",
  //   defaultMessage: "Input SRT connection error: Invalid epoll id",
  // },
  // 4251: {
  //   id: "nodeError.4251",
  //   defaultMessage: "Output SRT connection error: Invalid epoll id",
  // },
  // 4252: {
  //   id: "nodeError.4252",
  //   defaultMessage: "Input SRT connection error: SRT has opened too many file descriptors, engine restart necessary",
  // },
  // 4253: {
  //   id: "nodeError.4253",
  //   defaultMessage: "Output SRT connection error: SRT has opened too many file descriptors, engine restart necessary",
  // },
  // 4254: {
  //   id: "nodeError.4254",
  //   defaultMessage: "Input SRT connection error: connection timeout",
  // },
  // 4255: {
  //   id: "nodeError.4255",
  //   defaultMessage: "Output SRT connection error: connection timeout",
  // },
  // 4256: {
  //   id: "nodeError.4256",
  //   defaultMessage: "Input SRT connection error: connection lost",
  // },
  // 4257: {
  //   id: "nodeError.4257",
  //   defaultMessage: "Output SRT connection error: connection lost",
  // },
  // 4258: {
  //   id: "nodeError.4258",
  //   defaultMessage: "Input SRT connection error: socket not connected",
  // },
  // 4259: {
  //   id: "nodeError.4259",
  //   defaultMessage: "Output SRT connection error: socket not connected",
  // },
  // 4260: {
  //   id: "nodeError.4260",
  //   defaultMessage: "Input SRT connection error: socket not bound",
  // },
  // 4261: {
  //   id: "nodeError.4261",
  //   defaultMessage: "Output SRT connection error: socket not bound",
  // },
  // 4262: {
  //   id: "nodeError.4262",
  //   defaultMessage: "Input SRT connection error: sync fail",
  // },
  // 4263: {
  //   id: "nodeError.4263",
  //   defaultMessage: "Output SRT connection error: sync fail",
  // },
  // 4264: {
  //   id: "nodeError.4264",
  //   defaultMessage: "Input SRT connection error: send buffer full",
  // },
  // 4265: {
  //   id: "nodeError.4265",
  //   defaultMessage: "Output SRT connection error: send buffer full",
  // },
  // 4266: {
  //   id: "nodeError.4266",
  //   defaultMessage: "Input SRT connection error: no data received yet",
  // },
  // 4267: {
  //   id: "nodeError.4267",
  //   defaultMessage: "Output SRT connection error: no data received yet",
  // },
  // 4268: {
  //   id: "nodeError.4268",
  //   defaultMessage: "Input SRT connection error: timeout",
  // },
  // 4269: {
  //   id: "nodeError.4269",
  //   defaultMessage: "Output SRT connection error: timeout",
  // },
  // 4270: {
  //   id: "nodeError.4270",
  //   defaultMessage: "Input SRT connection error: congestion error",
  // },
  // 4271: {
  //   id: "nodeError.4271",
  //   defaultMessage: "Output SRT connection error: congestion error",
  // },
  // 4272: {
  //   id: "nodeError.4272",
  //   defaultMessage: "Input SRT connection error: peer signals error",
  // },
  // 4273: {
  //   id: "nodeError.4273",
  //   defaultMessage: "Output SRT connection error: peer signals error",
  // },
  // 4274: {
  //   id: "nodeError.4274",
  //   defaultMessage: "Input SRT connection error: socket closed during operation",
  // },
  // 4275: {
  //   id: "nodeError.4275",
  //   defaultMessage: "Output SRT connection error: socket closed during operation",
  // },
  // 4276: {
  //   id: "nodeError.4276",
  //   defaultMessage: "Input SRT connection error: system couldn't allocate resources",
  // },
  // 4277: {
  //   id: "nodeError.4277",
  //   defaultMessage: "Output SRT connection error: system couldn't allocate resources",
  // },
  // 4278: {
  //   id: "nodeError.4278",
  //   defaultMessage: "Input SRT connection error: epoll empty (without sockets",
  // },
  // 4279: {
  //   id: "nodeError.4279",
  //   defaultMessage: "Output SRT connection error: epoll empty (without sockets)",
  // },

  // 4800: {
  //   id: "nodeError.4800",
  //   defaultMessage: "Input SRT connection rejected",
  // },
  // 4801: {
  //   id: "nodeError.4801",
  //   defaultMessage: "Output SRT connection rejected",
  // },
  // 4802: {
  //   id: "nodeError.4802",
  //   defaultMessage: "Input SRT connection rejected: Broken due to system function error",
  // },
  // 4803: {
  //   id: "nodeError.4803",
  //   defaultMessage: "Output SRT connection rejected: Broken due to system function error",
  // },
  // 4804: {
  //   id: "nodeError.4804",
  //   defaultMessage: "Input SRT connection rejected: Connection was rejected by peer",
  // },
  // 4805: {
  //   id: "nodeError.4805",
  //   defaultMessage: "Output SRT connection rejected: Connection was rejected by peer",
  // },
  // 4806: {
  //   id: "nodeError.4806",
  //   defaultMessage: "Input SRT connection rejected: Internal problem with resource allocation",
  // },
  // 4807: {
  //   id: "nodeError.4807",
  //   defaultMessage: "Output SRT connection rejected: Internal problem with resource allocation",
  // },
  // 4808: {
  //   id: "nodeError.4808",
  //   defaultMessage: "Input SRT connection rejected: Incorrect data in handshake messages",
  // },
  // 4809: {
  //   id: "nodeError.4809",
  //   defaultMessage: "Output SRT connection rejected: Incorrect data in handshake messages",
  // },
  // 4810: {
  //   id: "nodeError.4810",
  //   defaultMessage: "Input SRT connection rejected: Listener's backlog exceeded",
  // },
  // 4811: {
  //   id: "nodeError.4811",
  //   defaultMessage: "Output SRT connection rejected: Listener's backlog exceeded",
  // },
  // 4812: {
  //   id: "nodeError.4812",
  //   defaultMessage: "Input SRT connection rejected: Internal program error",
  // },
  // 4813: {
  //   id: "nodeError.4813",
  //   defaultMessage: "Output SRT connection rejected: Internal program error",
  // },
  // 4814: {
  //   id: "nodeError.4814",
  //   defaultMessage: "Input SRT connection rejected: Socket is closing",
  // },
  // 4815: {
  //   id: "nodeError.4815",
  //   defaultMessage: "Output SRT connection rejected: Socket is closing",
  // },
  // 4816: {
  //   id: "nodeError.4816",
  //   defaultMessage: "Input SRT connection rejected: Peer is older version than agent's minimum set",
  // },
  // 4817: {
  //   id: "nodeError.4817",
  //   defaultMessage: "Output SRT connection rejected: Peer is older version than agent's minimum set",
  // },
  // 4818: {
  //   id: "nodeError.4818",
  //   defaultMessage: "Input SRT connection rejected: Rendezvous cookie collision",
  // },
  // 4819: {
  //   id: "nodeError.4819",
  //   defaultMessage: "Output SRT connection rejected: Rendezvous cookie collision",
  // },
  // 4820: {
  //   id: "nodeError.4820",
  //   defaultMessage: "Input SRT connection rejected: Wrong password",
  // },
  // 4821: {
  //   id: "nodeError.4821",
  //   defaultMessage: "Output SRT connection rejected: Wrong password",
  // },
  // 4822: {
  //   id: "nodeError.4822",
  //   defaultMessage: "Input SRT connection rejected: Password required or unexpected",
  // },
  // 4823: {
  //   id: "nodeError.4823",
  //   defaultMessage: "Output SRT connection rejected: Password required or unexpected",
  // },
  // 4824: {
  //   id: "nodeError.4824",
  //   defaultMessage: "Input SRT connection rejected: Streamapi/messageapi collision",
  // },
  // 4825: {
  //   id: "nodeError.4825",
  //   defaultMessage: "Output SRT connection rejected: Streamapi/messageapi collision",
  // },
  // 4826: {
  //   id: "nodeError.4826",
  //   defaultMessage: "Input SRT connection rejected: Incompatible congestion-controller type",
  // },
  // 4827: {
  //   id: "nodeError.4827",
  //   defaultMessage: "Output SRT connection rejected: Incompatible congestion-controller type",
  // },
  // 4828: {
  //   id: "nodeError.4828",
  //   defaultMessage: "Input SRT connection rejected: Incompatible packet filter",
  // },
  // 4829: {
  //   id: "nodeError.4829",
  //   defaultMessage: "Output SRT connection rejected: Incompatible packet filter",
  // },
  // 4830: {
  //   id: "nodeError.4830",
  //   defaultMessage: "Input SRT connection rejected: Incompatible group",
  // },
  // 4831: {
  //   id: "nodeError.4831",
  //   defaultMessage: "Output SRT connection rejected: Incompatible group",
  // },
  // 4832: {
  //   id: "nodeError.4832",
  //   defaultMessage: "Input SRT connection rejected: Timeout",
  // },
  // 4833: {
  //   id: "nodeError.4833",
  //   defaultMessage: "Output SRT connection rejected: Timeout",
  // },

  // 4900: {
  //   id: "nodeError.4900",
  //   defaultMessage: "Unknown error in stream",
  // },
  // 4901: {
  //   id: "nodeError.4901",
  //   defaultMessage: "Unknown error in output stream",
  // },
  // 4902: {
  //   id: "nodeError.4902",
  //   defaultMessage: "Unknown error in engine",
  // },

  // 5000 - 5999 Critical errors
  5000: {
    id: "nodeError.5000",
    defaultMessage: "Cannot encrypt fingerprint",
  },
  5001: {
    id: "nodeError.5001",
    defaultMessage: "Quickstream Node won't run in a container",
  },
  5002: {
    id: "nodeError.5002",
    defaultMessage: "Detected unknown virtualization or container",
  },
  5003: {
    id: "nodeError.5003",
    defaultMessage: "Generated hardware id is empty",
  },
  // 5004: {
  //   id: "nodeError.5004",
  //   defaultMessage: "Couldn't get hardware info",
  // },

  // 6000 - 6999 node-client, node-engine errors
  6000: {
    id: "nodeError.6000",
    defaultMessage: "Engine rejected last command",
  },
  // 6001: {
  //   id: "nodeError.6001",
  //   defaultMessage: "No value for debug level",
  // },
  6002: {
    id: "nodeError.6002",
    defaultMessage: "Invalid value for debug level",
  },
  6003: {
    id: "nodeError.6003",
    defaultMessage: "Client sent an incomplete command",
  },
  6004: {
    id: "nodeError.6004",
    defaultMessage: "Engine sent an incomplete command",
  },
  6005: {
    id: "nodeError.6005",
    defaultMessage: "Client sent an unknown command",
  },
  6006: {
    id: "nodeError.6006",
    defaultMessage: "Engine sent an unknown command",
  },
  // 6007: {
  //   id: "nodeError.6007",
  //   defaultMessage: "Client called not-existing endpoint (404)",
  // },
  // 6008: {
  //   id: "nodeError.6008",
  //   defaultMessage: "Client called not allowed method on endpoint (405)",
  // },
  6009: {
    id: "nodeError.6009",
    defaultMessage: "Unauthorized: invalid authentication data",
  },
  6010: {
    id: "nodeError.6010",
    defaultMessage: "Invalid new password when changing user/password",
  },
  6011: {
    id: "nodeError.6011",
    defaultMessage: "Cannot change username or password",
  },
  6012: {
    id: "nodeError.6012",
    defaultMessage: "Quickstream Node rejected last command due to closing",
  },
  6013: {
    id: "nodeError.6013",
    defaultMessage: "Requested log file does not exist",
  },
  6014: {
    id: "nodeError.6014",
    defaultMessage: "Requested stats file does not exist",
  },
  6015: {
    id: "nodeError.6015",
    defaultMessage: "Requested file with empty filename",
  },
  6016: {
    id: "nodeError.6016",
    defaultMessage: "Requested file with incorrect filename",
  },
  6017: {
    id: "nodeError.6017",
    defaultMessage: "Could not remove some files",
  },
  6018: {
    id: "nodeError.6018",
    defaultMessage: "Version of client application is too low",
  },
  6019: {
    id: "nodeError.6019",
    defaultMessage: "Too many invalid login tries from client, disconnecting",
  },
  6020: {
    id: "nodeError.6020",
    defaultMessage: "Node sent to engine an incomplete command",
  },
  // 6021: {
  //   id: "nodeError.6021",
  //   defaultMessage: "Port ranges from engine are invalid!",
  // },
  // 6022: {
  //   id: "nodeError.6022",
  //   defaultMessage: "Port ranges overlap! Shouldn't happen, restart whole service!",
  // },
  // 6023: {
  //   id: "nodeError.6023",
  //   defaultMessage: "No free port ranges!",
  // },
  6024: {
    id: "nodeError.6024",
    defaultMessage: "Invalid hostname!",
  },
  6025: {
    id: "nodeError.6025",
    defaultMessage: "Cloud sent an incomplete command",
  },
  6026: {
    id: "nodeError.6026",
    defaultMessage: "Cloud sent an unknown command",
  },
  6027: {
    id: "nodeError.6027",
    defaultMessage: "Cloud sent command for another node",
  },
  6028: {
    id: "nodeError.6028",
    defaultMessage: "Cloud sent command with invalid sender name",
  },
  6029: {
    id: "nodeError.6029",
    defaultMessage: "Cloud sent command from unauthorized user",
  },
  6030: {
    id: "nodeError.6030",
    defaultMessage: "Setup sent an incomplete command",
  },
  6031: {
    id: "nodeError.6031",
    defaultMessage: "Setup sent an unknown command",
  },
  6032: {
    id: "nodeError.6032",
    defaultMessage: "Cloud sent command restricted for setups",
  },
  6033: {
    id: "nodeError.6033",
    defaultMessage: "Client sent command restricted for setups",
  },
  6034: {
    id: "nodeError.6034",
    defaultMessage: "Setup sent command restricted for clients",
  },
  6035: {
    id: "nodeError.6035",
    defaultMessage: "Version of client application is too high",
  },
  6036: {
    id: "nodeError.6036",
    defaultMessage: "Cannot read devices list or params from system",
  },
  6037: {
    id: "nodeError.6037",
    defaultMessage: "Cloud has sent a command with invalid data",
  },
  6038: {
    id: "nodeError.6038",
    defaultMessage: "Cloud has sent invalid synchronization data",
  },

  // Stats errors
  6100: {
    id: "nodeError.6100",
    defaultMessage: "Client asked for incorrect column in stats!",
  },
  6101: {
    id: "nodeError.6101",
    defaultMessage: "Client asked for incorrect scale for stats!",
  },
  6102: {
    id: "nodeError.6102",
    defaultMessage: "Client asked for future stats!",
  },
  6103: {
    id: "nodeError.6103",
    defaultMessage: "Client asked for incorrect channel!",
  },
  6104: {
    id: "nodeError.6104",
    defaultMessage: "Client asked for stats outside range!",
  },

  // 6200 - 6299 MQTT errors
  6200: {
    id: "nodeError.6200",
    defaultMessage: "Cannot initialize MQTT library",
  },
  6201: {
    id: "nodeError.6201",
    defaultMessage: "Cannot connect to MQTT broker",
  },
  6202: {
    id: "nodeError.6202",
    defaultMessage: "Invalid MQTT message received",
  },
  6203: {
    id: "nodeError.6203",
    defaultMessage: "Cannot send MQTT message",
  },

  // 7000 - 7999 unknown errors
  7000: {
    id: "nodeError.7000",
    defaultMessage: "Unknown error",
  },
  7001: {
    id: "nodeError.7001",
    defaultMessage: "Caught unhandled std::exception!",
  },
  7002: {
    id: "nodeError.7002",
    defaultMessage: "Caught unknown exception!",
  },
  7003: {
    id: "nodeError.7003",
    defaultMessage: "Unknown exception in helper",
  },
  7004: {
    id: "nodeError.7004",
    defaultMessage: "Incorrect helper command line: wrong param count",
  },
  7005: {
    id: "nodeError.7005",
    defaultMessage: "Wrong command for qs-helper",
  },
  7006: {
    id: "nodeError.7006",
    defaultMessage: "Wrong param for command",
  },
  7007: {
    id: "nodeError.7007",
    defaultMessage: "Helper timeout",
  },
  7008: {
    id: "nodeError.7008",
    defaultMessage: "Node helper has returned incorrect json",
  },
  7009: {
    id: "nodeError.7009",
    defaultMessage: "Node helper has returned errors",
  },
  7010: {
    id: "nodeError.7010",
    defaultMessage: "Node helper rejected: queue is too long, wait a minute and try again",
  },
  7011: {
    id: "nodeError.7011",
    defaultMessage: "Node helper rejected: input is of QS type",
  },
  7012: {
    id: "nodeError.7012",
    defaultMessage: "Node helper cannot open decklink driver, is it installed",
  },
  7013: {
    id: "nodeError.7013",
    defaultMessage: "Decklink error",
  },
  7014: {
    id: "nodeError.7014",
    defaultMessage: "Decklink device not found",
  },
  7015: {
    id: "nodeError.7015",
    defaultMessage: "Probe cannot open source",
  },
  7016: {
    id: "nodeError.7016",
    defaultMessage: "Probe cannot initialize NDI",
  },

  // 8000 - 8999 license errors
  8000: {
    id: "nodeError.8000",
    defaultMessage: "Free Quickstream license must have a Quickstream input or output",
  },
  8001: {
    id: "nodeError.8001",
    defaultMessage: "Free Quickstream license allows only one output",
  },
  8002: {
    id: "nodeError.8002",
    defaultMessage: "Standard license allows only one output",
  },
  8003: {
    id: "nodeError.8003",
    defaultMessage: "H264 codec used with non-cloud license",
  },
  8004: {
    id: "nodeError.8004",
    defaultMessage: "H265 codec used with non-cloud license",
  },
  8005: {
    id: "nodeError.8005",
    defaultMessage: "AAC codec used with non-cloud license",
  },
  8006: {
    id: "nodeError.8006",
    defaultMessage: "QS stream used with non-cloud license",
  },
  8010: {
    id: "nodeError.8010",
    defaultMessage: "Free-Quickstream license is deprecated",
  },
  8011: {
    id: "nodeError.8011",
    defaultMessage: "Unknown license type",
  },
  8012: {
    id: "nodeError.8012",
    defaultMessage: "Cloud denied permission to start channel",
  },

  // 9000 - 9999 qs-client/qs-client-web errors
  9001: {
    id: "nodeError.9001",
    defaultMessage: "Unable to establish connection to node - timeout",
  },
  9000: {
    id: "nodeError.9000",
    defaultMessage: "Websocket disconnected",
  },

  // 11100 - 11199  Source errors
  11101: {
    id: "nodeError.11101",
    defaultMessage: "Cannot set source",
  },
  11102: {
    id: "nodeError.11102",
    defaultMessage: "Cannot start source object",
  },
  11103: {
    id: "nodeError.11103",
    defaultMessage: "Cannot stop source object",
  },
  11104: {
    id: "nodeError.11104",
    defaultMessage: "Cannot create source object",
  },
  11105: {
    id: "nodeError.11105",
    defaultMessage: "Source is missing required streams",
  },
  11106: {
    id: "nodeError.11106",
    defaultMessage: "Source too short to set tc_in",
  },
  11107: {
    id: "nodeError.11107",
    defaultMessage: "Error reading source",
  },
  11108: {
    id: "nodeError.11108",
    defaultMessage: "Error reading image file",
  },
  11109: {
    id: "nodeError.11109",
    defaultMessage: "Cannot setup input device",
  },
  11110: {
    id: "nodeError.11110",
    defaultMessage: "Emergency file loading problem",
  },
  11111: {
    id: "nodeError.11111",
    defaultMessage: "Adbreak file loading problem",
  },

  // Playout engine errors
  20000: {
    id: "nodeError.20000",
    defaultMessage: "Invalid video format specified",
  },
  20001: {
    id: "nodeError.20001",
    defaultMessage: "Config file is not a valid JSON file!",
  },
  20002: {
    id: "nodeError.20002",
    defaultMessage: "Invalid value found in config",
  },
  20003: {
    id: "nodeError.20003",
    defaultMessage: "Key cannot be empty in config",
  },
  20004: {
    id: "nodeError.20004",
    defaultMessage: "Missing ad break video",
  },
  20005: {
    id: "nodeError.20005",
    defaultMessage: "Missing emergency video",
  },
  20006: {
    id: "nodeError.20006",
    defaultMessage: "Multicast on Windows needs interface defined",
  },
  20007: {
    id: "nodeError.20007",
    defaultMessage: "Duplicate output name found",
  },
  20008: {
    id: "nodeError.20008",
    defaultMessage: "Live source is not enabled, yet output for it is defined",
  },
  20009: {
    id: "nodeError.20009",
    defaultMessage: "Unsupported playlist source type",
  },

  // Playout initialization errors
  21002: {
    id: "nodeError.21002",
    defaultMessage: "Cannot create folder for log files",
  },
  21003: {
    id: "nodeError.21003",
    defaultMessage: "Cannot create folder for AsRun files",
  },
  21004: {
    id: "nodeError.21004",
    defaultMessage: "Cannot create folder for stats files",
  },
  21005: {
    id: "nodeError.21005",
    defaultMessage: "Cannot create folder for playlist files",
  },
  21006: {
    id: "nodeError.21006",
    defaultMessage: "Cannot create folder for debug playlist files",
  },
  21007: {
    id: "nodeError.21007",
    defaultMessage: "Cannot open websocket",
  },
  21008: {
    id: "nodeError.21008",
    defaultMessage: "Source already registered",
  },
  21009: {
    id: "nodeError.21009",
    defaultMessage: "Invalid request for pointer",
  },
  21012: {
    id: "nodeError.21012",
    defaultMessage: "Encryption error",
  },
  21014: {
    id: "nodeError.21014",
    defaultMessage: "Invalid environment",
  },
  21015: {
    id: "nodeError.21015",
    defaultMessage: "WMI error",
  },
  21017: {
    id: "nodeError.21017",
    defaultMessage: "Cannot initialize REST api for monitoring",
  },
  21018: {
    id: "nodeError.21018",
    defaultMessage: "Cannot initialize Stream Switcher",
  },

  // Playlist errors
  22000: {
    id: "nodeError.22000",
    defaultMessage: "Received playlist has no valid items",
  },
  22001: {
    id: "nodeError.22001",
    defaultMessage: "Received playlist has missing required key",
  },
  22002: {
    id: "nodeError.22002",
    defaultMessage: "Received playlist has an item with invalid value type",
  },
  22003: {
    id: "nodeError.22003",
    defaultMessage: "Received playlist has an item with empty required value",
  },
  22004: {
    id: "nodeError.22004",
    defaultMessage: "Received playlist has an item with invalid time value",
  },
  22005: {
    id: "nodeError.22005",
    defaultMessage: "Received playlist is not sorted by date!",
  },

  // translations
  websocketAuth: {
    id: "nodeMsg.websocketAuth",
    defaultMessage: "Connection to Quickstream Node established",
  },
  changenodePasswordSuccess: {
    id: "nodeMsg.changenodePasswordSuccess",
    defaultMessage: "Password was successfully updated",
  },
  byteAvailRcvBuf: {
    id: "StatisticsView.byteAvailRcvBuf",
    defaultMessage: "Space available in receiver buffer",
  },
  byteAvailSndBuf: {
    id: "StatisticsView.byteAvailSndBuf",
    defaultMessage: "Space available in sender buffer",
  },
  mbpsBandwidth: {
    id: "StatisticsView.mbpsBandwidth",
    defaultMessage: "Link bandwidth [Mbps]",
  },
  mbpsRecvRate: {
    id: "StatisticsView.mbpsRecvRate",
    defaultMessage: "Receive rate [Mbps]",
  },
  mbpsSendRate: {
    id: "StatisticsView.mbpsSendRate",
    defaultMessage: "Send rate [Mbps]",
  },
  msRtt: {
    id: "StatisticsView.msRtt",
    defaultMessage: "RTT [Ping]",
  },
  pktCongestionWindow: {
    id: "StatisticsView.pktCongestionWindow",
    defaultMessage: "Congestation window size",
  },
  pktFlightSize: {
    id: "StatisticsView.pktFlightSize",
    defaultMessage: "Flight size",
  },
  pktFlowWindow: {
    id: "StatisticsView.pktFlowWindow",
    defaultMessage: "Flow window",
  },
  pktRcvBelated: {
    id: "StatisticsView.pktRcvBelated",
    defaultMessage: "Packets received too late",
  },
  pktRcvDrop: {
    id: "StatisticsView.pktRcvDrop",
    defaultMessage: "Dropped received packets",
  },
  pktRcvLoss: {
    id: "StatisticsView.pktRcvLoss",
    defaultMessage: "Received packets loss",
  },
  pktRcvRetrans: {
    id: "StatisticsView.pktRcvRetrans",
    defaultMessage: "Retransmitted received packets",
  },
  pktRecv: {
    id: "StatisticsView.pktRecv",
    defaultMessage: "Packets received",
  },
  pktRetrans: {
    id: "StatisticsView.pktRetrans",
    defaultMessage: "Packets retransmitted",
  },
  pktSent: {
    id: "StatisticsView.pktSent",
    defaultMessage: "Packets sent",
  },
  pktSndDrop: {
    id: "StatisticsView.pktSndDrop",
    defaultMessage: "Dropped sent packets",
  },
  pktSndLoss: {
    id: "StatisticsView.pktSndLoss",
    defaultMessage: "Sent packets loss",
  },
  time: {
    id: "StatisticsView.time",
    defaultMessage: "Time",
  },
  switcherFpsVariation: {
    id: "StatisticsView.switcherFpsVariation",
    defaultMessage: "Switcher Fps Variation",
  },
  mbpsMaxbw: {
    id: "StatisticsView.mbpsMaxbw",
    defaultMessage: "Max bandwidth [Mbps]",
  },
  msTimestamp: {
    id: "StatisticsView.msTimestamp",
    defaultMessage: "SRT Connection Alive Time",
  },
  ccErrors: {
    id: "StatisticsView.ccErrors",
    defaultMessage: "CC Errors",
  },
  ccErrorsPerHour: {
    id: "StatisticsView.ccErrorsPerHour",
    defaultMessage: "CC Errors in last hour",
  },
  ccErrorsTotal: {
    id: "StatisticsView.ccErrorsTotal",
    defaultMessage: "CC Errors since startup",
  },
  msRTT: {
    id: "StatisticsView.msRTT",
    defaultMessage: "Round Trip Time [ms]",
  },
  outputVideoSentFps: {
    id: "StatisticsView.outputVideoSentFps",
    defaultMessage: "Send Video [Fps]",
  },
  outputAudioSentFps: {
    id: "StatisticsView.outputAudioSentFps",
    defaultMessage: "Send Audio [Fps]",
  },
  inputPacketsVideoReadFps: {
    id: "StatisticsView.inputPacketsVideoReadFps",
    defaultMessage: "Read Packets Video [Fps]",
  },
  inputVideoDecodedFps: {
    id: "StatisticsView.inputVideoDecodedFps",
    defaultMessage: "Video Decoded [Fps]",
  },
  inputVideoBrokenFps: {
    id: "StatisticsView.inputVideoBrokenFps",
    defaultMessage: "Video Broken [Fps]",
  },
  inputVideoDroppedFps: {
    id: "StatisticsView.inputVideoDroppedFps",
    defaultMessage: "Video Dropped [Fps]",
  },
  inputVideoDuplicatedFps: {
    id: "StatisticsView.inputVideoDuplicatedFps",
    defaultMessage: "Video Duplicated [Fps]",
  },
  inputVideoProcessedFps: {
    id: "StatisticsView.inputVideoProcessedFps",
    defaultMessage: "Video Processed [Fps]",
  },
  inputPacketsAudioReadFps: {
    id: "StatisticsView.inputPacketsAudioReadFps",
    defaultMessage: "Read Packets Audio [Fps]",
  },
  inputAudioDecodedFps: {
    id: "StatisticsView.inputAudioDecodedFps",
    defaultMessage: "Audio Decoded [Fps]",
  },
  inputAudioBrokenFps: {
    id: "StatisticsView.inputAudioBrokenFps",
    defaultMessage: "Audio Broken [Fps]",
  },
  inputAudioDroppedFps: {
    id: "StatisticsView.inputAudioDroppedFps",
    defaultMessage: "Audio Dropped [Fps]",
  },
  inputAudioDuplicatedFps: {
    id: "StatisticsView.inputAudioDuplicatedFps",
    defaultMessage: "Audio Duplicated [Fps]",
  },
  inputAudioProcessedFps: {
    id: "StatisticsView.inputAudioProcessedFps",
    defaultMessage: "Audio Processed [Fps]",
  },
  switcherVideoProcessedFps: {
    id: "StatisticsView.switcherVideoProcessedFps",
    defaultMessage: "Video Processed [Fps]",
  },
  switcherVideoDuplicatedFps: {
    id: "StatisticsView.switcherVideoDuplicatedFps",
    defaultMessage: "Video Duplicated [Fps]",
  },
  switcherAudioProcessedFps: {
    id: "StatisticsView.switcherAudioProcessedFps",
    defaultMessage: "Audio Processed [Fps]",
  },
  switcherAudioDuplicatedFps: {
    id: "StatisticsView.switcherAudioDuplicatedFps",
    defaultMessage: "Audio Duplicated [Fps]",
  },
  encodeVideoReceivedFps: {
    id: "StatisticsView.encodeVideoReceivedFps",
    defaultMessage: "Video Received [Fps]",
  },
  encodeVideoProcessedFps: {
    id: "StatisticsView.encodeVideoProcessedFps",
    defaultMessage: "Video Processed [Fps]",
  },
  encodeAudioReceivedFps: {
    id: "StatisticsView.encodeAudioReceivedFps",
    defaultMessage: "Audio Received [Fps]",
  },
  encodeAudioProcessedFps: {
    id: "StatisticsView.encodeAudioProcessedFps",
    defaultMessage: "Audio Processed [Fps]",
  },
  inputPacketsReceivedFps: {
    id: "StatisticsView.inputPacketsReceivedFps",
    defaultMessage: "Packets Received [Fps]",
  },
  inputPacketsDroppedFps: {
    id: "StatisticsView.inputPacketsDroppedFps",
    defaultMessage: "Packets Dropped [Fps]",
  },

  framesEncoded: {
    id: "StatisticsView.framesEncoded",
    defaultMessage: "Encoded frames count",
  },
  framesDropped: {
    id: "StatisticsView.framesDropped",
    defaultMessage: "Frames dropped by encoder",
  },
  processingFps: {
    id: "StatisticsView.processingFps",
    defaultMessage: "Encoder speed [FPS]",
  },
  processing: {
    id: "StatisticsView.processing",
    defaultMessage: "Playing Source [%]",
  },
  switcherSourceMainPercent: {
    id: "StatisticsView.switcherSourceMainPercent",
    defaultMessage: "Main Source [%]",
  },
  switcherSourceBackupPercent: {
    id: "StatisticsView.switcherSourceBackupPercent",
    defaultMessage: "Backup Source [%]",
  },
  switcherSourceEmergencyPercent: {
    id: "StatisticsView.switcherSourceEmergencyPercent",
    defaultMessage: "Emergency Source [%]",
  },
  avgFrameTimeMs: {
    id: "StatisticsView.avgFrameTimeMs",
    defaultMessage: "Average frame encode time",
  },
  queueFill: {
    id: "StatisticsView.queueFill",
    defaultMessage: "Queue fill [%]",
  },
  memUsed: {
    id: "StatisticsView.memUsed",
    defaultMessage: "Memory used",
  },
  memUsedMB: {
    id: "StatisticsView.memUsedMB",
    defaultMessage: "Memory used [MB]",
  },
  memUsedPercent: {
    id: "StatisticsView.memUsedPercent",
    defaultMessage: "Memory used [%]",
  },
  incomplete0: {
    id: "IncompleteTranslations.incomplete0",
    defaultMessage: "Invalid configuration - check detail inside",
  },
  incomplete1: {
    id: "IncompleteTranslations.incomplete1",
    defaultMessage: "Missing input configuration",
  },
  incomplete2: {
    id: "IncompleteTranslations.incomplete2",
    defaultMessage: "Missing any output configuration",
  },
  incomplete3: {
    id: "IncompleteTranslations.incomplete3",
    defaultMessage: "Not supported input type",
  },
  incomplete4: {
    id: "IncompleteTranslations.incomplete4",
    defaultMessage: "Not supported output type",
  },
  incomplete5: {
    id: "IncompleteTranslations.incomplete5",
    defaultMessage: "Invalid quickstream channel on input",
  },
  incomplete6: {
    id: "IncompleteTranslations.incomplete6",
    defaultMessage: "Invalid quickstream channel on output",
  },
  incomplete7: {
    id: "IncompleteTranslations.incomplete7",
    defaultMessage: "No services defined",
  },
  incomplete8: {
    id: "IncompleteTranslations.incomplete8",
    defaultMessage: "No outputs defined",
  },
  incomplete9: {
    id: "IncompleteTranslations.incomplete9",
    defaultMessage: "No streams defined in service",
  },
  incomplete11: {
    id: "IncompleteTranslations.incomplete11",
    defaultMessage: "Global video settings are missing",
  },
  incomplete12: {
    id: "IncompleteTranslations.incomplete12",
    defaultMessage: "Global audio settings are missing",
  },
  incomplete13: {
    id: "IncompleteTranslations.incomplete13",
    defaultMessage: "Video stream mapping is missing (streamSelector)",
  },
  incomplete14: {
    id: "IncompleteTranslations.incomplete14",
    defaultMessage: "Audio stream mapping is missing",
  },
  incomplete15: {
    id: "IncompleteTranslations.incomplete15",
    defaultMessage: "Emergency settings are missing",
  },
  incomplete16: {
    id: "IncompleteTranslations.incomplete16",
    defaultMessage: "OutputUrl contains the program without the required video stream",
  },
  incomplete17: {
    id: "IncompleteTranslations.incomplete17",
    defaultMessage: "OutputUrl contains the program without the required audio stream",
  },
  incomplete18: {
    id: "IncompleteTranslations.incomplete18",
    defaultMessage:
      "Sample format (set in processing audio format) is incompatible with codec selected in output, please add a conversion",
  },
  incomplete19: {
    id: "IncompleteTranslations.incomplete19",
    defaultMessage:
      "Pixel format (set in processing video format) is incompatible with codec selected in output, please add a conversion",
  },
  incomplete20: {
    id: "IncompleteTranslations.incomplete20",
    defaultMessage: "Audio codec requires conversion of the number of channels",
  },
  incomplete21: {
    id: "IncompleteTranslations.incomplete21",
    defaultMessage:
      "Video codec selected in output and processing video format has incompatible pixelformat, please add a conversion",
  },
  incomplete22: {
    id: "IncompleteTranslations.incomplete22",
    defaultMessage: "RTMP output MUST use H264 or H265 codec",
  },
  incomplete23: {
    id: "IncompleteTranslations.incomplete23",
    defaultMessage: "RTMP output needs YUV420P pixel format",
  },
  incomplete24: {
    id: "IncompleteTranslations.incomplete24",
    defaultMessage: "RTMP output cannot be interlaced",
  },
  incomplete25: {
    id: "IncompleteTranslations.incomplete25",
    defaultMessage: "RTMP output MUST use AAC codec",
  },
  incomplete26: {
    id: "IncompleteTranslations.incomplete26",
    defaultMessage: "Missing required value",
  },
  incomplete27: {
    id: "IncompleteTranslations.incomplete27",
    defaultMessage:
      "The number of channels is greater than the maximum number of channels allowed for the global processing audio formats",
  },
});
