import React from "react";
import { Form, Row, Col, Steps } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { DEFAULT_VIRTUAL_CHANNEL } from "../../../lib/utils/constants";

import VirtualChannelStep0 from "./Steps/VirtualChannelStep0";
import VirtualChannelStep1 from "./Steps/VirtualChannelStep1";
// import VirtualChannelStep2 from "./Steps/VirtualChannelStep2";
// import VirtualChannelStep3 from "./Steps/VirtualChannelStep3";

import { selectors as CLOUD_CHANNELS_SELECTORS } from "../../../ducks/cloudChannels";

const { Step } = Steps;

const NewChannelFormModal = ({
  handleShowModal,
  setCurrentStep,
  currentStep,
  form,
  currentCloudId,
  setCurrentCloudId,
  allChannels,
}) => {
  const { getFieldValue, resetFields } = form;

  const initialValues =
    useSelector((state) =>
      CLOUD_CHANNELS_SELECTORS.getChannelData(state, currentCloudId, allChannels ? "allChannels" : "ownChannels")
    ) || DEFAULT_VIRTUAL_CHANNEL;
  const channelNameValue = Form.useWatch("channelName", form);
  const noChannelName = !(channelNameValue && channelNameValue.length > 0);

  const handleChangeStep = (value) => {
    setCurrentStep(value);
    resetFields();
  };

  const handleFinish = () => {
    setCurrentStep(0);
    resetFields();
    handleShowModal();
  };

  const steps = [
    {
      title: <FormattedMessage id="NewChannelFormModal.metaData" defaultMessage="Meta data" />,
      content: (
        <VirtualChannelStep0
          currentStep={currentStep}
          handleChangeStep={handleChangeStep}
          initialValues={initialValues}
          cloudId={currentCloudId}
          setCurrentCloudId={setCurrentCloudId}
          form={form}
        />
      ),
    },
    {
      title: <FormattedMessage id="NewChannelFormModal.upload" defaultMessage="Upload" />,
      content: (
        <VirtualChannelStep1
          getFieldValue={getFieldValue}
          currentStep={currentStep}
          handleChangeStep={handleChangeStep}
          cloudId={currentCloudId}
          logoUrl={initialValues.logoUrl}
          bgUrl={initialValues.bgUrl}
          screenShotUrls={initialValues.screenShotUrls || []}
          onFinish={handleFinish}
        />
      ),
    },
    // {
    //   title: <FormattedMessage id="NewChannelFormModal.public" defaultMessage="Public" />,
    //   content: (
    //     <VirtualChannelStep2
    //       initialValues={initialValues}
    //       currentStep={currentStep}
    //       handleChangeStep={handleChangeStep}
    //       cloudId={currentCloudId}
    //       onFinish={handleFinish}
    //     />
    //   ),
    // },
    // {
    //   title: <FormattedMessage id="NewChannelFormModal.preview" defaultMessage="Preview" />,
    //   content: (
    //     <VirtualChannelStep3
    //       currentStep={currentStep}
    //       handleChangeStep={handleChangeStep}
    //       handleFinish={handleFinish}
    //     />
    //   ),
    // },
  ];

  return (
    <>
      <Row gutter={[12, 24]}>
        <Col span={24}>
          <Steps current={currentStep} onChange={handleChangeStep}>
            {steps.map((item, index) => (
              <Step
                key={item.title}
                title={item.title}
                status={currentStep === index ? "process" : "wait"}
                disabled={currentStep === 0 && noChannelName}
              />
            ))}
          </Steps>
        </Col>
        <Col span={24}>{steps[currentStep].content}</Col>
      </Row>
    </>
  );
};

NewChannelFormModal.propTypes = {
  currentStep: PropTypes.number,
  setCurrentStep: PropTypes.func,
  allChannels: PropTypes.bool,
  handleShowModal: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  currentCloudId: PropTypes.number,
  setCurrentCloudId: PropTypes.func,
};

NewChannelFormModal.defaultProps = {
  currentCloudId: null,
  currentStep: 0,
  setCurrentStep: null,
  setCurrentCloudId: null,
  allChannels: false,
};

export default NewChannelFormModal;
