import themeColors from "../style/theme";
import { NODE_PLAYOUT_PLAYLIST_TYPE } from "./constants";

export default function playoutPlaylistStatusColor(type, status) {
  if (type === NODE_PLAYOUT_PLAYLIST_TYPE.LIVE && status === "PLAYING") {
    return themeColors.green;
  }

  if (type === NODE_PLAYOUT_PLAYLIST_TYPE.LIVE && status === "CONNECTED") {
    return themeColors.green;
  }

  if (type === NODE_PLAYOUT_PLAYLIST_TYPE.LIVE) {
    return themeColors.warningStatus;
  }

  return themeColors.green;
}
