import React, { useState } from "react";
import { Card, Table, Tag, Modal, Button } from "antd";
import { useIntl, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";
// import PropTypes from "prop-types";

import themeColors from "lib/style/theme";
// import OrganizationsServices from "services/organizations";

import { selectors as ADMIN_SELECTORS } from "ducks/admin";
import { selectors as ORGANIZATIONS_SELECTORS } from "ducks/organizations";
import { selectors as ACCOUNT_SELECTORS } from "ducks/account";

// import { errorNotification } from "lib/utils/notification";
// import { SEO_STATUS } from "lib/utils/constants";
import globalTranslation from "lib/translations";
import OrganizationColors from "lib/utils/organizationColors";
import useRole from "hooks/useRole";

import OrganizationForm from "components/OrganizationForm";
import OrganizationValidation from "components/OrganizationValidation";
import SearchFilter from "components/Filters/SearchFilter";

import OrganizationsListActions from "./OrganizationsListActions";

const OrganizationsList = () => {
  const { formatMessage } = useIntl();
  // const [confirmVisible, setConfirmVisible] = useState(null);
  const [isVisibleEditOrgModal, setIsVisibleEditOrgModal] = useState(false);
  const [selectedOrganizationData, setSelectedOrganizationData] = useState({});
  const [loadingDeleteOrg, setLoadingDeleteOrg] = useState(false);
  const [isVisibleNewOrgModal, setIsVisibleNewOrgModal] = useState(false);
  const { isAdminOrSupport } = useRole();

  const organizationsList = useSelector(ORGANIZATIONS_SELECTORS.getOrganizationsList);

  const userId = useSelector(ACCOUNT_SELECTORS.getUserId);
  const userOrganizationList = organizationsList.filter((organization) => organization.creator === userId);

  const accountList = useSelector(ADMIN_SELECTORS.getAccountList);

  // const handleChangeStatus = useCallback(() => {
  //   OrganizationsServices.changeOrganizationStatus(confirmVisible, {
  //     errorNotification: errorNotification(formatMessage),
  //   });
  //   setConfirmVisible(null);
  // }, [confirmVisible]);

  // const showConfirm = useCallback(
  //   (data) => {
  //     setConfirmVisible(data);
  //   },
  //   [setConfirmVisible]
  // );

  // const hideConfirm = useCallback(() => {
  //   setConfirmVisible(null);
  // }, [setConfirmVisible]);

  const adminAndSupportColumns = [
    {
      title: <FormattedMessage id="OrganizationsList.name" defaultMessage="Name" />,
      dataIndex: "name",
      ...SearchFilter({ dataIndex: "organizationName", placeholder: "name" }),
    },
    // {
    //   key: "preview",
    //   dataIndex: "preview",
    //   title: formatMessage(globalTranslation.preview),
    //   render: (_text, record) => {
    //     return (
    //       <Button
    //         icon={<SearchOutlined />}
    //         type="dashed"
    //         shape="circle"
    //         href={`${process.env.REACT_APP_HUB_URL}/preview/organizations/${record?.name}`}
    //         target="_blank"
    //         rel="noopener noreferrer"
    //       />
    //     );
    //   },
    // },
    {
      title: <FormattedMessage id="OrganizationsList.country" defaultMessage="Country" />,
      dataIndex: "country",
      ...SearchFilter({ dataIndex: "country", placeholder: "country" }),
    },
    // {
    //   title: <FormattedMessage id="OrganizationsList.logoUrl" defaultMessage="Logo" />,
    //   dataIndex: "logoUrl",
    //   render: (logoUrl) => {
    //     if (!logoUrl) {
    //       return null;
    //     }

    //     return <Image width={70} src={`${logoUrl}?${imageHash}`} />;
    //   },
    // },
    {
      title: <FormattedMessage id="OrganizationsList.owner" defaultMessage="Owner" />,
      dataIndex: "creator",
      ...SearchFilter({ dataIndex: "creator", placeholder: "creator", accountList }),
    },
    // {
    //   title: <FormattedMessage id="OrganizationsList.public" defaultMessage="Public" />,
    //   dataIndex: "public",
    //   render: (isPublic) => {
    //     return isPublic ? (
    //       <Tag color={themeColors.green}>
    //         <FormattedMessage id="OrganizationsList.public" defaultMessage="Public" />
    //       </Tag>
    //     ) : (
    //       <Tag color={themeColors.lightRed}>
    //         <FormattedMessage id="OrganizationsList.private" defaultMessage="Private" />
    //       </Tag>
    //     );
    //   },
    // },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   width: 240,
    //   render: (status, organizationData) => {
    //     return (
    //       <Tag
    // eslint-disable-next-line max-len
    //         color={OrganizationColors({ isOrganizationPublic: organizationData?.public, organizationStatus: status })}
    //       >
    //         <OrganizationValidation organizationData={organizationData} organizationId={organizationData?.id} />
    //       </Tag>
    //     );
    //   },
    // },
    // ...(verification
    //   ? [
    //       {
    //         title: "Status",
    //         dataIndex: "status",
    //         width: 240,
    //         filters: [
    //           {
    //             text: SEO_STATUS.VERIFICATION.toUpperCase(),
    //             value: SEO_STATUS.VERIFICATION,
    //           },
    //           {
    //             text: SEO_STATUS.ACCEPTED.toUpperCase(),
    //             value: SEO_STATUS.ACCEPTED,
    //           },
    //           {
    //             text: SEO_STATUS.REJECTED.toUpperCase(),
    //             value: SEO_STATUS.REJECTED,
    //           },
    //         ],
    //         defaultFilteredValue: [SEO_STATUS.VERIFICATION],
    //         onFilter: (value, record) => value && record?.status?.indexOf(value) === 0,
    //         render: (status, { id }) => {
    //           return (
    //             <Popconfirm
    //               key={id}
    //               open={confirmVisible?.id === id}
    //               placement="top"
    //               title={
    //                 <>
    //                   <FormattedMessage
    //                     id="OrganizationsRolesList.change"
    //                     defaultMessage="Are you sure you want to change role to"
    //                   />{" "}
    //                   <b>{confirmVisible?.status.toUpperCase()}</b>
    //                 </>
    //               }
    //               onConfirm={() => handleChangeStatus()}
    //               onCancel={hideConfirm}
    //               okText={formatMessage(globalTranslation.yes)}
    //               cancelText={formatMessage(globalTranslation.no)}
    //             >
    //               <Radio.Group
    //                 value={status}
    //                 onChange={(event) => showConfirm({ status: event.target.value, id })}
    //                 disabled={loadingDeleteOrg}
    //                 loading={loadingDeleteOrg}
    //               >
    //                 <Radio.Button value={SEO_STATUS.ACCEPTED}>{SEO_STATUS.ACCEPTED.toUpperCase()}</Radio.Button>
    //                 <Radio.Button value={SEO_STATUS.REJECTED}>{SEO_STATUS.REJECTED.toUpperCase()}</Radio.Button>
    //               </Radio.Group>
    //             </Popconfirm>
    //           );
    //         },
    //       },
    //     ]
    //   : []),
    {
      title: <FormattedMessage id="OrganizationsList.actions" defaultMessage="Actions" />,
      dataIndex: "actions",
      key: "actions",
      render: (_data, record) => (
        <OrganizationsListActions
          setSelectedOrganizationData={setSelectedOrganizationData}
          setIsVisibleEditOrgModal={setIsVisibleEditOrgModal}
          record={record}
          loadingDeleteOrg={loadingDeleteOrg}
          setLoadingDeleteOrg={setLoadingDeleteOrg}
        />
      ),
    },
  ];

  const userViewColumns = [
    {
      title: <FormattedMessage id="OrganizationsList.name" defaultMessage="Name" />,
      dataIndex: "name",
      ...SearchFilter({ dataIndex: "organizationName", placeholder: "name" }),
    },
    {
      key: "preview",
      dataIndex: "preview",
      title: formatMessage(globalTranslation.preview),
      render: (_text, record) => {
        return (
          <Button
            icon={<SearchOutlined />}
            type="dashed"
            shape="circle"
            href={`${process.env.REACT_APP_HUB_URL}/preview/organizations/${record?.name}`}
            target="_blank"
            rel="noopener noreferrer"
          />
        );
      },
    },
    {
      title: <FormattedMessage id="OrganizationsList.country" defaultMessage="Country" />,
      dataIndex: "country",
    },
    {
      title: <FormattedMessage id="OrganizationsList.owner" defaultMessage="Owner" />,
      dataIndex: "creator",
      render: (creatorId) => {
        const creatorData = accountList.find((account) => account.id === creatorId);

        return creatorData?.username;
      },
    },
    {
      title: <FormattedMessage id="OrganizationsList.public" defaultMessage="Public" />,
      dataIndex: "public",
      render: (isPublic) => {
        return isPublic ? (
          <Tag color={themeColors.green}>
            <FormattedMessage id="OrganizationsList.public" defaultMessage="Public" />
          </Tag>
        ) : (
          <Tag color={themeColors.lightRed}>
            <FormattedMessage id="OrganizationsList.private" defaultMessage="Private" />
          </Tag>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 240,
      render: (status, organizationData) => {
        return (
          <Tag
            color={OrganizationColors({ isOrganizationPublic: organizationData?.public, organizationStatus: status })}
          >
            <OrganizationValidation organizationData={organizationData} organizationId={organizationData?.id} />
          </Tag>
        );
      },
    },
    {
      title: <FormattedMessage id="OrganizationsList.actions" defaultMessage="Actions" />,
      dataIndex: "actions",
      key: "actions",
      render: (_data, record) => (
        <OrganizationsListActions
          setSelectedOrganizationData={setSelectedOrganizationData}
          setIsVisibleEditOrgModal={setIsVisibleEditOrgModal}
          record={record}
          loadingDeleteOrg={loadingDeleteOrg}
          setLoadingDeleteOrg={setLoadingDeleteOrg}
        />
      ),
    },
  ];

  return (
    <Card
      title={<FormattedMessage id="OrganizationsList.title" defaultMessage="Organizations list" />}
      extra={
        <Button type="primary" icon={<PlusCircleOutlined />} onClick={() => setIsVisibleNewOrgModal(true)}>
          <span>
            <FormattedMessage id="OrganizationsList.addNew" defaultMessage="Add new" />
          </span>
        </Button>
      }
    >
      <Table
        rowKey={(record) => record.id}
        columns={isAdminOrSupport ? adminAndSupportColumns : userViewColumns}
        dataSource={isAdminOrSupport ? organizationsList : userOrganizationList}
        scroll={{ x: "calc(100vh - 200px)" }}
      />
      {isVisibleEditOrgModal && (
        <Modal
          closable={false}
          maskClosable={false}
          title={
            <FormattedMessage id="OrganizationStep1.editOrganizationModalTitle" defaultMessage="Edit organization" />
          }
          open={isVisibleEditOrgModal}
          footer={null}
          width={1000}
        >
          <OrganizationForm
            setModalVisible={setIsVisibleEditOrgModal}
            selectedOrganizationData={selectedOrganizationData}
          />
        </Modal>
      )}
      <Modal
        title={<FormattedMessage id="OrganizationStep1.newOrganizationModalTitle" defaultMessage="New organization" />}
        open={isVisibleNewOrgModal}
        onOk={() => setIsVisibleNewOrgModal(true)}
        onCancel={() => setIsVisibleNewOrgModal(false)}
        footer={null}
        width={1000}
      >
        <OrganizationForm setModalVisible={setIsVisibleNewOrgModal} setOrganization={() => null} />
      </Modal>
    </Card>
  );
};

// OrganizationsList.propTypes = {
//   verification: PropTypes.bool,
// };

// OrganizationsList.defaultProps = {
//   verification: false,
// };

export default OrganizationsList;
