import React from "react";
import { FormattedMessage } from "react-intl";
import { Tooltip } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import PropTypes from "prop-types";

import themeColor from "../../lib/style/theme";

const NodeEngineLoggingBeCerfuleLabel = ({ label }) => {
  return (
    <Tooltip
      placement="top"
      title={
        <FormattedMessage
          id="NodeEngineLoggingBeCerfuleLabel.logLevelDescription"
          defaultMessage="Please be careful - activating this option, it can generate >1.5MB logs per minute"
        />
      }
    >
      <span>
        {label} <ExclamationCircleFilled style={{ color: themeColor.orange }} />
      </span>
    </Tooltip>
  );
};

NodeEngineLoggingBeCerfuleLabel.propTypes = {
  label: PropTypes.node.isRequired,
};

export default NodeEngineLoggingBeCerfuleLabel;
