import React from "react";
import { Button, Popconfirm } from "antd";
import { defineMessages, useIntl, FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";

import globalTranslation from "../../lib/translations";
import { MESSAGE_TYPE } from "../../lib/utils/constants";
import themeColors from "../../lib/style/theme";

import MQTTService from "../../services/mqtt";
import { selectors as NODE_PLAYOUT_PLAYLIST_SELECTORS } from "../../ducks/nodePlayoutPlaylist";

const translations = defineMessages({
  resetAlertsQuestionTitle: {
    id: "ResetAlertsButton.resetAlertsQuestionTitle",
    defaultMessage: "Are you sure you want to reset alerts?",
  },
});

export default function ResetAlertsButton() {
  const { formatMessage } = useIntl();
  const { cwid, id } = useParams();

  const lastReset = useSelector((state) => NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistLastReset(state, +id));

  const resetAlerts = () => {
    MQTTService.sendMessageV5({
      topic: `node/${cwid}/in`,
      message: {
        msgType: MESSAGE_TYPE.CLEAR_PLAYOUT_ALERTS,
        channelId: id,
      },
    });
  };

  return (
    <Popconfirm
      placement="left"
      title={
        <>
          <div>
            <b>{formatMessage(translations.resetAlertsQuestionTitle)}</b>
          </div>
        </>
      }
      onConfirm={resetAlerts}
      okText={formatMessage(globalTranslation.yes)}
      cancelText={formatMessage(globalTranslation.no)}
    >
      <Button type="primary">
        <FormattedMessage id="ResetAlertsButton.reset" defaultMessage="Reset" />
      </Button>
      <StyledSpan>
        (<FormattedMessage id="ResetAlertsButton.lastReset" defaultMessage="Last reset" />:{" "}
        <StyledLastReset>{lastReset}</StyledLastReset>)
      </StyledSpan>
    </Popconfirm>
  );
}

const StyledSpan = styled.span`
  font-size: 11px;
  margin: 0 5px;
`;

const StyledLastReset = styled.span`
  color: ${themeColors.orange};
`;
