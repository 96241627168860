import { createAction, handleActions } from "redux-actions";
import { Map } from "immutable";

export const actions = {
  SET_ENGINE_LOGGING: createAction("SET_ENGINE_LOGGING"),
};

const initialState = new Map({});

const reducer = handleActions(
  {
    [actions.SET_ENGINE_LOGGING]: (state, { payload: { channelId, logging, cwid } }) =>
      state.setIn([cwid, channelId], logging),
  },
  initialState
);

export const selectors = {
  getEngineLogging: (state, cwid, channelId) => state.getIn(["nodeEngineLogging", cwid, +channelId]),
};

export default reducer;
