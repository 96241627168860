import React, { useEffect, useState } from "react";
import { Card, Table, Button, Modal } from "antd";
import { useIntl, defineMessages } from "react-intl";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
// import { SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import CloudChannelsServices from "../../services/cloudChannels";
import { selectors as CLOUD_CHANNELS_SELECTORS } from "../../ducks/cloudChannels";
import { selectors as MQTT_SELECTORS } from "../../ducks/mqtt";

import { errorNotification } from "../../lib/utils/notification";
import parseCloudId from "../../lib/utils/parseCloudId";

import SearchFilter from "../../components/Filters/SearchFilter";
import HubChannelDetailView from "../../components/HubChannelDetailView";
import HubShareUseOnNode from "./HubShareUseOnNode";

import { NODE_CHANEL_TABS } from "../../lib/utils/constants";

const translations = defineMessages({
  id: {
    id: "SharedChannelList.id",
    defaultMessage: "ID",
  },
  owner: {
    id: "SharedChannelList.owner",
    defaultMessage: "Owner",
  },
  name: {
    id: "SharedChannelList.name",
    defaultMessage: "Name",
  },
  channelName: {
    id: "SharedChannelList.channelName",
    defaultMessage: "Channel name",
  },
  shareName: {
    id: "SharedChannelList.shareName",
    defaultMessage: "Share name",
  },
  preview: {
    id: "OwnChannelList.preview",
    defaultMessage: "Preview",
  },
  actions: {
    id: "general.actions",
    defaultMessage: "Actions",
  },
  node: {
    id: "OwnChannelList.node",
    defaultMessage: "Node",
  },
});

const SharedChannelList = ({ sharedChannelsList }) => {
  const { formatMessage } = useIntl();
  const [openModal, setOpenModal] = useState(false);
  const [hubChannelData, setHubChannelData] = useState({});
  const nodes = useSelector(MQTT_SELECTORS.getNodes);

  async function fetchChannels() {
    CloudChannelsServices.getSharedChannels({ errorNotification: errorNotification(formatMessage) });
  }

  useEffect(() => {
    fetchChannels();
  }, []);

  const handleOpenModal = (selectedChannelData) => {
    setOpenModal(true);
    setHubChannelData(selectedChannelData);
  };

  const columns = [
    {
      key: "description",
      dataIndex: "description",
      width: 250,
      title: formatMessage(translations.shareName),
    },
    // {
    //   key: "preview",
    //   dataIndex: "preview",
    //   width: 90,
    //   align: "center",
    //   title: formatMessage(translations.preview),
    //   render: (_text, record) => {
    //     return (
    //       <Button
    //         icon={<SearchOutlined />}
    //         type="dashed"
    //         shape="circle"
    //         href={`${process.env.REACT_APP_HUB_URL}/preview/channels/${record.cloudId}-${record.channelName}`}
    //         target="_blank"
    //         rel="noopener noreferrer"
    //       />
    //     );
    //   },
    // },
    {
      key: "channelName",
      dataIndex: "channelName",
      width: 250,
      title: formatMessage(translations.channelName),
      ...SearchFilter({ dataIndex: "virtualChannelName", placeholder: "name" }),
      sorter: (currentValue, nextValue) => currentValue.channelName.localeCompare(nextValue.channelName),
      render: (channelName, record) => {
        return (
          <Button type="link" onClick={() => handleOpenModal(record)}>
            {channelName}
          </Button>
        );
      },
    },
    {
      key: "cloudId",
      dataIndex: "cloudId",
      width: 130,
      title: formatMessage(translations.id),
      sorter: (currentValue, nextValue) => currentValue.cloudId - nextValue.cloudId,
      render: (cloudId) => {
        return parseCloudId(cloudId);
      },
    },
    {
      key: "id",
      dataIndex: "userId",
      title: formatMessage(translations.owner),
      sorter: (currentValue, nextValue) => currentValue.userId.localeCompare(nextValue.userId),
      render: (username) => {
        return <Link to={`/users/${username}`}>{username}</Link>;
      },
    },
    {
      key: "cwid",
      dataIndex: "cwid",
      title: formatMessage(translations.node),
      render: (nodeCwid, record) => {
        const nodeData = nodes.find((node) => node.cwid === nodeCwid);
        if (nodeData && nodeData.cnn) {
          return (
            <Link
              // eslint-disable-next-line max-len
              to={`/node/${nodeData.cwid}/${nodeData.cnn}/channels?search=${record?.channelName}&tab=${NODE_CHANEL_TABS.channelList}`}
            >
              {nodeData.cnn}
            </Link>
          );
        }

        const parsedNodeData = record?.cnn ? record.cnn : nodeCwid;

        return <span>{parsedNodeData}</span>;
      },
    },
    {
      key: "actions",
      title: formatMessage(translations.actions),
      render: (_, { cloudId, cwid, description, permissionId, channelName }) => (
        <HubShareUseOnNode
          shareName={description}
          permissionId={permissionId}
          cloudId={cloudId}
          cwid={cwid}
          channelName={channelName}
        />
      ),
    },
  ];

  return (
    <Card>
      <Table columns={columns} dataSource={sharedChannelsList} rowKey={(record) => record.permissionId} />
      <Modal
        destroyOnClose
        width="60%"
        title={hubChannelData?.channelName}
        open={openModal}
        footer={false}
        onCancel={() => setOpenModal(false)}
      >
        <HubChannelDetailView hubChannelData={hubChannelData} />
      </Modal>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  sharedChannelsList: CLOUD_CHANNELS_SELECTORS.getSharedChannels(state),
});

SharedChannelList.propTypes = {
  sharedChannelsList: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        cloudId: PropTypes.number.isRequired,
        channelName: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        isEncrypted: PropTypes.bool.isRequired,
        public: PropTypes.bool.isRequired,
        userId: PropTypes.string.isRequired,
      })
    ),
    PropTypes.array,
  ]).isRequired,
};

export default connect(mapStateToProps, null)(SharedChannelList);
