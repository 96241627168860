import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Tooltip } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { CodeOutlined, LineChartOutlined } from "@ant-design/icons";

import { selectors as CLOUD_CHANNELS_SELECTORS } from "../../../ducks/cloudChannels";

import { CHANNEL_SOURCE } from "../../../lib/utils/constants";

function DetailSectionPermissionLabel({
  channelId,
  permissions,
  permissionId,
  outputId,
  outputsConfigs,
  info,
  sourceId,
  outputName,
}) {
  const navigate = useNavigate();
  const { cwid, cnn } = useParams();
  const sourceUrlParams = encodeURIComponent(JSON.stringify({ ...sourceId }));

  const handleGoToLogs = () => {
    navigate(`/node/${cwid}/${cnn}/channel/${channelId}?source=${sourceUrlParams}&tab=logs`);
  };

  const handleGoToStats = () => {
    navigate(
      // eslint-disable-next-line max-len
      `/node/${cwid}/${cnn}/channel/${channelId}?tab=statistics&subTab=outputsV5&outputIdTab=${outputId}&sourceTab=${sourceId.s}-${sourceId.p1}-${sourceId.p2}-${sourceId.p3}`
    );
  };

  if (permissionId <= +CHANNEL_SOURCE.nodeChannel) {
    const output = outputsConfigs.find((config) => config.outputId === outputId);

    return (
      <>
        {outputName || output?.outputName} {info && <>({info})</>}{" "}
        <Tooltip
          title={
            <FormattedMessage
              id="DetailSectionPermissionLabel.goToOutputLogsview"
              defaultMessage="Go to output logs view"
            />
          }
        >
          <Button type="link" size="small" onClick={() => handleGoToLogs()} icon={<CodeOutlined />} shape="circle" />
        </Tooltip>
        <Tooltip
          title={
            <FormattedMessage
              id="DetailSectionPermissionLabel.goToOutputStats"
              defaultMessage="Go to output statistic view"
            />
          }
        >
          <Button
            type="link"
            size="small"
            onClick={() => handleGoToStats()}
            icon={<LineChartOutlined />}
            shape="circle"
          />
        </Tooltip>
      </>
    );
  }

  const permissionDataExist = permissions && !!permissions[channelId];
  if (permissionDataExist) {
    const permissionDescriptionArray = permissions[channelId];
    const descriptionData = permissionDescriptionArray.find((permission) => +permission.permissionId === +permissionId);

    return `${descriptionData?.description} (${descriptionData?.username} - ${info})`;
  }

  return `${permissionId} - ${info}`;
}

DetailSectionPermissionLabel.propTypes = {
  permissionId: PropTypes.number,
  permissions: PropTypes.object,
  outputId: PropTypes.number.isRequired,
  info: PropTypes.string.isRequired,
  outputName: PropTypes.string,
};

DetailSectionPermissionLabel.defaultProps = {
  permissionId: null,
  permissions: null,
  outputName: null,
};

export const mapStateToProps = (state) => ({
  permissions: CLOUD_CHANNELS_SELECTORS.getChannelPermissions(state),
});

export default connect(mapStateToProps)(DetailSectionPermissionLabel);
