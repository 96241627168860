import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import { OrderedListOutlined } from "@ant-design/icons";
import { FormattedMessage, useIntl } from "react-intl";
import OrganizationsServices from "../../../services/organizations";
import AdminService from "../../../services/admin";
import useRole from "../../../hooks/useRole";
import OrganizationsList from "../../../components/OrganizationsList";
import OrganizationLoading from "../../../components/OrganizationLoading";
// import OrganizationsRolesList from "../../../components/OrganizationsRolesList";
import { errorNotification } from "../../../lib/utils/notification";

const Organizations = () => {
  const { formatMessage } = useIntl();
  const [loadingData, setLoadingData] = useState(true);
  const { isAdmin, isAdminOrSupport } = useRole();

  useEffect(() => {
    setLoadingData(true);
    OrganizationsServices.getOrganizations({ errorNotification: errorNotification(formatMessage), setLoadingData });
    if (isAdminOrSupport) {
      AdminService.getAccountList({ errorNotification: errorNotification(formatMessage) });
    }
  }, [isAdmin]);

  if (loadingData) {
    return <OrganizationLoading />;
  }

  const items = [
    {
      icon: <OrderedListOutlined />,
      label: (
        <span>
          <FormattedMessage id="Organizations.organizations" defaultMessage="Organizations" />
        </span>
      ),
      key: "organizationsList",
      children: <OrganizationsList />,
    },
    // {
    //   icon: <UserSwitchOutlined />,
    //   label: (
    //     <span>
    //       <FormattedMessage id="Organizations.rolesVerification" defaultMessage="Roles verification" />
    //     </span>
    //   ),
    //   key: "organizationsRole",
    //   children: <OrganizationsRolesList />,
    // },
    ...(isAdminOrSupport
      ? [
          {
            icon: <OrderedListOutlined />,
            label: (
              <span>
                <FormattedMessage
                  id="Organizations.organizationsVerification"
                  defaultMessage="Organizations verification"
                />
              </span>
            ),
            key: "organizationsVerification",
            children: <OrganizationsList verification />,
          },
        ]
      : []),
  ];

  return <Tabs defaultActiveKey="organizationsList" items={items} />;
};

export default Organizations;
