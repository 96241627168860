import React, { useState } from "react";
import { Tooltip, Button, Modal } from "antd";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import store from "../../store";

import { actions as NAVIGATION_ACTIONS } from "../../ducks/navigation";
import { actions as NODE_CHANNELS_ACTIONS } from "../../ducks/nodeChannels";
import { actions as NODE_ACTIONS } from "../../ducks/node";
import { DELETE_LOGS } from "../../ducks/logs";
import { CLEAR_HIST_STATISTIC } from "../../ducks/nodeStatistics";
import { CLEAR_DEVICES_LIST } from "../../ducks/nodeDevices";
import { CLEAR_FORMATS } from "../../ducks/nodeFormats";

import { MQTT_STATUS, NODE_TYPES } from "../../lib/utils/constants";
import checkClientVersion from "../../lib/utils/checkClientVersion";
import redirectClientVersion from "../../lib/utils/redirectClientVersion";

const NodesListTitle = ({ online, cwid, cnn, nodeVersion, type }) => {
  const navigate = useNavigate();
  const [showNodeVersionModal, setShowNodeVersionModal] = useState(false);

  const handleOkRedirect = () => {
    if (cwid && cnn && nodeVersion) {
      redirectClientVersion({
        nodeVersion,
        url: `/node/${cwid}/${cnn}/channels`,
      });
    }
  };

  const handleCancelRedirect = () => {
    setShowNodeVersionModal(false);
  };

  const handleChangeNode = () => {
    const changeClientVersion = checkClientVersion({ nodeVersion });

    if (changeClientVersion) {
      setShowNodeVersionModal(true);

      return;
    }

    store.dispatch(NAVIGATION_ACTIONS.SET_CHANNELS_ACTIVE_TAB({ channelsActiveTab: "channelList" }));
    store.dispatch(NODE_ACTIONS.CLEAR_NODE_CONNECTION());
    store.dispatch(NODE_CHANNELS_ACTIONS.CLEAR_NODES_CHANNELS());
    store.dispatch(DELETE_LOGS());
    store.dispatch(CLEAR_HIST_STATISTIC());
    store.dispatch(CLEAR_DEVICES_LIST());
    store.dispatch(CLEAR_FORMATS());

    navigate(`/node/${cwid}/${cnn}/channels`);
  };

  return (
    <>
      <Tooltip
        placement="right"
        title={
          <>
            {online === MQTT_STATUS.CONNECTED && <FormattedMessage id="general.online" defaultMessage="Online" />}
            {online === MQTT_STATUS.DISCONNECTED && <FormattedMessage id="general.offline" defaultMessage="Offline" />}
            {!online && type === NODE_TYPES.SHARED && (
              <FormattedMessage id="general.offline" defaultMessage="Offline" />
            )}
            {!online && type === NODE_TYPES.USER && (
              <FormattedMessage
                id="NodesListTitle.cloudVersionInfo"
                defaultMessage="Using this node in cloud requires different cloud interface version"
              />
            )}
            {!online && type === NODE_TYPES.CLOUD && (
              <FormattedMessage
                id="NodesListTitle.cloudNodeWaiting"
                defaultMessage="Waiting for cloud node connection"
              />
            )}
          </>
        }
      >
        {!online && type === NODE_TYPES.CLOUD && (
          <StyledLoading>
            <pre>{cnn}</pre>
          </StyledLoading>
        )}
        {!online && type === NODE_TYPES.USER && (
          <StyledButton type="text" onClick={handleChangeNode}>
            <pre>{cnn}</pre>
          </StyledButton>
        )}
        {!online && type === NODE_TYPES.SHARED && <pre>{cnn}</pre>}
        {online === MQTT_STATUS.DISCONNECTED && <pre>{cnn}</pre>}
        {online === MQTT_STATUS.CONNECTED && <pre>{cnn}</pre>}
      </Tooltip>
      <Modal
        title={<FormattedMessage id="NodesListTitle.clientVersion" defaultMessage="Cloud version validation" />}
        open={showNodeVersionModal}
        onOk={handleOkRedirect}
        onCancel={handleCancelRedirect}
        okText={<FormattedMessage id="NodesListTitle.redirect" defaultMessage="Redirect" />}
      >
        <div>
          <FormattedMessage
            id="NodesListTitle.cloudVersionInfo"
            defaultMessage="Using this node in cloud requires different cloud interface version"
          />
          .
        </div>
      </Modal>
    </>
  );
};

const StyledButton = styled(Button)`
  color: #fff !important;
  padding: 0;
  span {
    margin-right: 2px;
  }
`;

const StyledLoading = styled.div`
  margin-left: 12px;
`;

NodesListTitle.propTypes = {
  cwid: PropTypes.string.isRequired,
  cnn: PropTypes.string.isRequired,
  nodeVersion: PropTypes.string.isRequired,
  online: PropTypes.string,
  type: PropTypes.string,
};

NodesListTitle.defaultProps = {
  online: null,
  type: NODE_TYPES.USER,
};

export default NodesListTitle;
