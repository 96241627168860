import { createAction, handleActions } from "redux-actions";
import { Map } from "immutable";
import { createSelector } from "reselect";

export const actions = {
  CLEAR_NODE_ALERTS: createAction("NODE_ALERTS/CLEAR_NODE_ALERTS"),
  SET_NODE_ALERTS: createAction("NODE_ALERTS/SET_NODE_ALERTS"),
};

const defaultState = new Map({
  muted: new Map({}),
  data: new Map({}),
});

const reducer = handleActions(
  {
    [actions.SET_NODE_ALERTS]: (state, { payload: { from, alerts, muted } }) => {
      const parsedFrom = from.split("/")[1];

      return state.setIn(["data", parsedFrom], alerts).setIn(["muted", parsedFrom], muted);
    },
    [actions.CLEAR_NODE_ALERTS]: () => defaultState,
  },
  defaultState
);

export const selectors = {
  getNodeAlerts: (state) => {
    return state.getIn(["nodeAlerts", "data"]);
  },
  getNodeMutedChannels: (state) => {
    return state.getIn(["nodeAlerts", "muted"]);
  },
  getParams: (state, params) => params,
};

export const getNodeMutedSelector = createSelector(
  [selectors.getNodeMutedChannels, selectors.getParams],
  (muted, params) => {
    const { cwid } = params || {};

    return muted.get(cwid);
  }
);

export const getNodeAlertsSelector = createSelector(
  [selectors.getNodeAlerts, selectors.getParams],
  (alerts, params) => {
    const { cwid } = params || {};

    return alerts.get(cwid);
  }
);

export const getNodeAlertsCountSelector = createSelector(
  [selectors.getNodeAlerts, selectors.getParams],
  (alerts, params) => {
    const { cwid } = params || {};
    let nodeAlertsCount = 0;

    const nodeAlerts = alerts.get(cwid) || [];
    nodeAlerts.forEach((channelAlert) => {
      channelAlert?.sources.forEach((channelSourceAlert) => {
        channelSourceAlert?.alerts.forEach(() => {
          nodeAlertsCount += 1;
        });
      });
    });

    return nodeAlertsCount;
  }
);

export default reducer;
