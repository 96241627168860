import React, { useState } from "react";
import { Card, Table, Tooltip, Tag, Switch, Spin } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import PropTypes from "prop-types";

import {
  selectors as NODE_PLAYOUT_PLAYLIST_SELECTORS,
  MEMOIZED_PLAYOUT_PLAYLIST_SELECTOR,
  MEMOIZED_PLAYOUT_PLAYLIST_FIRST_THREE_SELECTOR,
} from "../../ducks/nodePlayoutPlaylist";

import { selectors as NODE_CHANNELS_SELECTOR } from "../../ducks/nodeChannels";

import themeColors from "../../lib/style/theme";
import { NODE_PLAYOUT_PLAYLIST_TYPE, PLAYOUT_PLAYLIST_ITEM_STATUS } from "../../lib/utils/constants";
import playoutPlaylistStatusColor from "../../lib/utils/playoutPlaylistStatusColor";

import NodePlayoutPlaylistsAlerts from "./NodePlayoutPlaylistsAlerts";
import NodePlayoutPlaylistsPreview from "./NodePlayoutPlaylistsPreview";
import ResetAlertsButton from "./ResetAlertsButton";

function ExpandedRow(record) {
  return <pre>{JSON.stringify({ cgEvents: record?.cgEvents }, null, 2)}</pre>;
}

ExpandedRow.propTypes = {
  record: PropTypes.shape({
    cgEvents: PropTypes.string,
  }).isRequired,
};

const NodePlayoutPlaylists = () => {
  const { id } = useParams();
  const playoutPlaylist = useSelector((state) => MEMOIZED_PLAYOUT_PLAYLIST_SELECTOR(state, +id));
  const parsedPlayoutPlaylist = useSelector((state) => MEMOIZED_PLAYOUT_PLAYLIST_FIRST_THREE_SELECTOR(state, +id));

  const elemStatus22 = useSelector((state) =>
    NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistElemStatus22(state, +id)
  );

  const isChannelStarted = useSelector((state) => NODE_CHANNELS_SELECTOR.getIsChannelStarted({ state, channelId: id }));

  const [showMoreData, setShowMoreData] = useState(false);

  const columns = [
    {
      title: <FormattedMessage id="NodePlayoutPlaylists.date" defaultMessage="DATE" />,
      dataIndex: "time",
      key: "time",
      render: (time, record) => (
        <Tooltip placement="top" title={record?.date}>
          <span>{time}</span>
        </Tooltip>
      ),
    },
    {
      title: <FormattedMessage id="NodePlayoutPlaylists.name" defaultMessage="NAME" />,
      dataIndex: "clipName",
      key: "clipName",
      render: (clipName, record) => (
        <span>
          <StyledSpan>{record?.clipName}</StyledSpan>
          {record?.fileName ? ` (${record.fileName})` : ""}
        </span>
      ),
    },
    {
      title: <FormattedMessage id="NodePlayoutPlaylists.type" defaultMessage="TYPE" />,
      dataIndex: "type",
      key: "type",
      align: "center",
      render: (type) => {
        if (type === NODE_PLAYOUT_PLAYLIST_TYPE.FILE) {
          return (
            <Tag bordered={false} color={themeColors.defaultBlueColor}>
              {type}
            </Tag>
          );
        }

        if (type === NODE_PLAYOUT_PLAYLIST_TYPE.AD_BREAK) {
          return (
            <Tag bordered={false} color={themeColors.adBreakStatus}>
              {type}
            </Tag>
          );
        }

        if (type === NODE_PLAYOUT_PLAYLIST_TYPE.LIVE) {
          return (
            <Tag bordered={false} color={themeColors.maritime}>
              {type}
            </Tag>
          );
        }

        if (type === NODE_PLAYOUT_PLAYLIST_TYPE.PLACEHOLDER) {
          return (
            <Tag bordered={false} color={themeColors.maritime}>
              {type}
            </Tag>
          );
        }

        return <Tag bordered={false}>{type}</Tag>;
      },
    },
    {
      title: <FormattedMessage id="NodePlayoutPlaylists.statusUppercase" defaultMessage="STATUS" />,
      dataIndex: "itemStatus",
      key: "itemStatus",
      align: "center",
      render: (itemStatus, { type }) => {
        if (itemStatus === PLAYOUT_PLAYLIST_ITEM_STATUS.OK) {
          return (
            <Tag bordered={false} color={playoutPlaylistStatusColor(type, elemStatus22 && elemStatus22.toUpperCase())}>
              {type === NODE_PLAYOUT_PLAYLIST_TYPE.LIVE ? elemStatus22 && elemStatus22.toUpperCase() : itemStatus}
            </Tag>
          );
        }

        if (itemStatus === PLAYOUT_PLAYLIST_ITEM_STATUS.BACKUP) {
          return (
            <Tag bordered={false} color={themeColors.orange}>
              {itemStatus}
            </Tag>
          );
        }

        if (itemStatus === PLAYOUT_PLAYLIST_ITEM_STATUS.MISSING || itemStatus === PLAYOUT_PLAYLIST_ITEM_STATUS.BROKEN) {
          return (
            <Tag bordered={false} color={themeColors.lightRed}>
              {itemStatus}
            </Tag>
          );
        }

        if (itemStatus === PLAYOUT_PLAYLIST_ITEM_STATUS.UNKNOWN) {
          return (
            <Tag bordered={false} color={themeColors.unknownStatus}>
              {itemStatus}
            </Tag>
          );
        }

        return <Tag bordered={false}>{itemStatus}</Tag>;
      },
    },
    {
      title: <FormattedMessage id="NodePlayoutPlaylists.extAudioStatus" defaultMessage="EXT. AUDIO" />,
      dataIndex: "extAudioStatus",
      key: "extAudioStatus",
      align: "center",
      render: (extAudioStatus) => {
        if (extAudioStatus === PLAYOUT_PLAYLIST_ITEM_STATUS.OK) {
          return (
            <Tag bordered={false} color={themeColors.green}>
              {extAudioStatus}
            </Tag>
          );
        }

        if (extAudioStatus === PLAYOUT_PLAYLIST_ITEM_STATUS.BACKUP) {
          return (
            <Tag bordered={false} color={themeColors.orange}>
              {extAudioStatus}
            </Tag>
          );
        }

        if (
          extAudioStatus === PLAYOUT_PLAYLIST_ITEM_STATUS.MISSING ||
          extAudioStatus === PLAYOUT_PLAYLIST_ITEM_STATUS.BROKEN
        ) {
          return (
            <Tag bordered={false} color={themeColors.lightRed}>
              {extAudioStatus}
            </Tag>
          );
        }

        if (extAudioStatus === PLAYOUT_PLAYLIST_ITEM_STATUS.UNKNOWN) {
          return (
            <Tag bordered={false} color={themeColors.unknownStatus}>
              {extAudioStatus}
            </Tag>
          );
        }

        return <Tag bordered={false}>{extAudioStatus}</Tag>;
      },
    },
    {
      title: <FormattedMessage id="NodePlayoutPlaylists.duration" defaultMessage="DURATION" />,
      dataIndex: "formattedDuration",
      key: "formattedDuration",
      align: "center",
    },
    {
      title: <FormattedMessage id="NodePlayoutPlaylists.tcIn" defaultMessage="TC IN" />,
      dataIndex: "formattedTcIn",
      key: "formattedTcIn",
      align: "center",
    },
    {
      title: <FormattedMessage id="NodePlayoutPlaylists.tcOut" defaultMessage="TC OUT" />,
      dataIndex: "formattedTcOut",
      key: "formattedTcOut",
      align: "center",
    },
  ];

  return (
    <Spin
      tip={
        <FormattedMessage id="NodePlayoutPlaylists.pleaseStartChannel" defaultMessage="Please start channel first" />
      }
      spinning={!isChannelStarted}
      indicator={<WarningOutlined />}
      size="large"
    >
      <Card
        style={{
          filter: !isChannelStarted ? "blur(8px)" : "none",
        }}
      >
        <NodePlayoutPlaylistsPreview />
        <StyledCard
          title={<FormattedMessage id="NodePlayoutPlaylists.alerts" defaultMessage="Alerts" />}
          extra={<ResetAlertsButton />}
        >
          <NodePlayoutPlaylistsAlerts />
        </StyledCard>
        <StyledCard
          title={<FormattedMessage id="NodePlayoutPlaylists.playlist" defaultMessage="Playlist" />}
          extra={
            <Switch
              checkedChildren={<FormattedMessage id="NodePlayoutPlaylists.lessData" defaultMessage="Less data" />}
              unCheckedChildren={<FormattedMessage id="NodePlayoutPlaylists.modeData" defaultMessage="More data" />}
              checked={showMoreData}
              onChange={(checked) => {
                setShowMoreData(checked);
              }}
            />
          }
        >
          <Table
            pagination={false}
            dataSource={showMoreData ? playoutPlaylist : parsedPlayoutPlaylist}
            columns={columns}
            expandable={{
              expandedRowRender: ExpandedRow,
              rowExpandable: (record) => !!record?.cgEvents,
              expandRowByClick: true,
            }}
            rowKey={(record) => record?.guid}
          />
        </StyledCard>
      </Card>
    </Spin>
  );
};

const StyledSpan = styled.span`
  color: ${themeColors.orange};
`;

const StyledCard = styled(Card)`
  margin-top: 16px;
`;

export default NodePlayoutPlaylists;
