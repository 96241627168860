import * as React from "react";
import { Col, Row } from "antd";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import globalTranslations from "../../../lib/translations";
import { CONNECTION_METHOD_OUTPUT } from "../../../lib/utils/constants";

import { PortField } from "../../Fields/Fields";
import InputNumberField from "../../Fields/InputNumberField";
import NetworkField from "../../Fields/NetworkField";
import PassphraseField from "../../Fields/PassphraseField";

import OverheadBandwidthLabel from "../Labels/OverheadBandwidthLabel";
import LatencyLabel from "../Labels/LatencyLabel";
import HostField from "../../HostField";
import StreamIdFields from "../../StreamIdFields";
import IPTOSFields from "../../IPTOSFields";
import IPTTLFields from "../../IPTTLFields";
import LocalPortLabel from "../../LocalPortLabel";
import AdvancedSettings from "../../AdvancedSettings";

import { required } from "../../../lib/utils/formValidation";

const OutputStreamSrtSection = ({ disabled, prefix, connectionMethod, form, basicMode }) => {
  const { formatMessage } = useIntl();

  const isListenerMode = connectionMethod === CONNECTION_METHOD_OUTPUT.outSrtListener.value;
  const isCallerMode = connectionMethod === CONNECTION_METHOD_OUTPUT.outSrtCaller.value;

  return (
    <>
      {isCallerMode && (
        <>
          <Col md={24} lg={12}>
            <HostField
              disabled={disabled}
              label={formatMessage(globalTranslations.addressIp)}
              name={[...prefix, "destinationAddress"]}
            />
          </Col>
          <Col md={24} lg={12}>
            <PortField name={[...prefix, "destinationPort"]} disabled={disabled} />
          </Col>
        </>
      )}

      <Col md={24} lg={12}>
        <NetworkField name={[...prefix, "interfaceIp"]} disabled={disabled} />
      </Col>

      {isListenerMode && (
        <Col md={24} lg={12}>
          <PortField
            name={[...prefix, "localPort"]}
            disabled={disabled}
            label={<LocalPortLabel noTooltip={isListenerMode} />}
            min={1}
          />
        </Col>
      )}

      {!basicMode && (
        <>
          <Col md={24} lg={12}>
            <InputNumberField
              disabled={disabled}
              name={[...prefix, "srtLatency"]}
              label={LatencyLabel("output")}
              rules={[required]}
            />
          </Col>
          <Col span={24}>
            <AdvancedSettings>
              <Row gutter={24}>
                {isCallerMode && (
                  <Col md={24} lg={12}>
                    <PortField
                      name={[...prefix, "localPort"]}
                      disabled={disabled}
                      label={<LocalPortLabel noTooltip={isListenerMode} />}
                      min={0}
                    />
                  </Col>
                )}
                <Col md={24} lg={12}>
                  <InputNumberField
                    disabled={disabled}
                    name={[...prefix, "srtOverhead"]}
                    label={OverheadBandwidthLabel()}
                    rules={[required]}
                    min={5}
                    max={100}
                  />
                </Col>
                <StreamIdFields prefix={prefix} form={form} disabled={disabled} />
                <IPTOSFields prefix={prefix} form={form} disabled={disabled} />
                <IPTTLFields prefix={prefix} form={form} disabled={disabled} />
                <Col md={24} lg={24}>
                  <PassphraseField disabled={disabled} prefix={[...prefix]} type={prefix[0]} form={form} />
                </Col>
              </Row>
            </AdvancedSettings>
          </Col>
        </>
      )}
    </>
  );
};

OutputStreamSrtSection.propTypes = {
  disabled: PropTypes.bool,
  connectionMethod: PropTypes.string.isRequired,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  basicMode: PropTypes.bool,
};

OutputStreamSrtSection.defaultProps = {
  disabled: null,
  basicMode: false,
};

export default OutputStreamSrtSection;
