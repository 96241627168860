import React from "react";
import { FormattedMessage } from "react-intl";
import { Tooltip } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";

const NodeEngineLoggingLogLevelLabel = () => {
  return (
    <Tooltip
      placement="top"
      title={
        <FormattedMessage
          id="NodeEngineLoggingLogLevelLabel.logLevelDescription"
          defaultMessage={`Extended logging options are evailable only
      for Debug and Verbouse level.`}
        />
      }
    >
      <span>
        <FormattedMessage id="NodeEngineLoggingLogLevelLabel.logLevel" defaultMessage="Log Level" />{" "}
        <QuestionCircleFilled />
      </span>
    </Tooltip>
  );
};

export default NodeEngineLoggingLogLevelLabel;
