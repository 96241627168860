import React from "react";
import { Badge } from "antd";
import PropTypes from "prop-types";

export default function NodePlayoutPlaylistsStatus({ status }) {
  const parsedStatus = status.toUpperCase();

  if (parsedStatus === "INITIALIZED") {
    return <Badge text={parsedStatus} color="blue" />;
  }

  if (parsedStatus === "PLAYING" || parsedStatus === "ONLINE" || parsedStatus === "CONNECTED") {
    return <Badge status="processing" text={parsedStatus} color="green" />;
  }

  if (parsedStatus === "PAUSED") {
    return <Badge status="warning" text={parsedStatus} />;
  }

  if (
    parsedStatus === "STOPPED" ||
    parsedStatus === "OFFLINE" ||
    parsedStatus === "ERROR" ||
    parsedStatus === "BROKEN"
  ) {
    return <Badge status="error" text={parsedStatus} />;
  }

  if (parsedStatus === "N/A") {
    return <Badge status="default" text={parsedStatus} />;
  }

  return <Badge status="warning" text={parsedStatus} />;
}

NodePlayoutPlaylistsStatus.propTypes = {
  status: PropTypes.string.isRequired,
};
